import React from "react";
import AspectComponent from "components/aspect/AddEvualiacionComponent";
import AspectComponentPartido from 'components/aspect/InputSliderPartido';
import toast from 'react-hot-toast';
import {
    Card,
    CardBody,
    Col,
    FormFeedback,
    FormGroup, Row, Alert, CardHeader, ButtonGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input as InputTextArea, CardTitle, CardFooter
} from "reactstrap";
import styled from "styled-components";
import GlobalContext from "redux/context";
import Select from "react-select";
import { FONTS } from "variables/typography";
import { useStateCallback } from "hooks/useStateCallback";
import moment from "moment";
import Imgbck from 'components/Background_image/Index'
import background from '../assets/img/Valores.png'
import Manager from '../source/AddEvaluaciones'
import Helper from '../api/handler/index';
import { SIZES } from "variables/typography";
import Autosuggest from "components/autocomplete";
import Autocomplete from "components/autoCompleteV2"
import Loader from "react-spinners/BeatLoader";
import ModalComponent from "components/Modal";
const PreviewContainer = styled.div`
border-radius: 20px;
padding: 5px 75px;
margin-bottom: -47px;
`

const api = new Manager();


const helperManager = new Helper();

function AddEvaluacionScreen() {
    const unmounted = React.useRef(true);
    const userContext = React.useContext(GlobalContext);
    const { state, getInitialData, GetUserByCategorie } = userContext;
    const { evaluaciones, categoriaList, userList, currentEvaluacion, puntuacion, clubCategori, userInfo, userEvaluaciones, evaluacionPartido } = state;
    const [evaluacion, setEvaluacion] = React.useState('Seleccione evaluacion');
    const [contextEvaluacion, setContextEvalucion] = React.useState([]);
    const [optionCategoria, setOptionCategoria] = React.useState([]);
    const [optionPlayers, setOptionPlayers] = React.useState([]);
    const [playerSelected, setPlayerSelected] = React.useState(null);
    const [evaSelect, setEvaSelect] = React.useState(true);
    const [filterType, setFilterType] = React.useState('');
    const [alertType, setAlertType] = React.useState(null);
    const [msg, setMsg] = React.useState(null);
    const [visible, setVisible] = React.useState(false);
    const [modelPuntuacion, setModelPuntuacion] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(0);
    const [ovrAspect1, setOvrAspect1] = React.useState(0);
    const [ovrAspect2, setOvrAspect2] = React.useState(0);
    const [ovrAspect3, setOvrAspect3] = React.useState(0);
    const [$div, setDiv] = React.useState(0)
    const [modalOpen, setModalOpen] = React.useState(false);
    const [moveRow, setMoveRow] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const [evaluacionTecnicaShow, setEvaluacionTecnicaShow] = React.useState(false);
    const [evaluacionPartidoShow, setEvaluacionPartidoShow] = React.useState(false);
    //BLACK LIST PLAYERS 
    const [blackListPlayers, setBlackListPlayer] = React.useState([]);

    // MODELOS PARA INSERTAR EVALUACION TECNICA 
    const [modelBase, setModelBase] = useStateCallback({
        sessionID: '',
        clubID: '',
        promCog: 0,
        promOf: 0,
        promDef: 0,
        ovr: 0,
        observacion: '',
        date: moment(new Date()).format('YYYY-MM-DD')
    });
    const [modelBaseItem, setModelBaseItem] = useStateCallback([]);
    const [sessionID, setSessionID] = React.useState('');
    const [clubID, setClubID] = React.useState('');
    const [userWithEva, setUserWithEva] = React.useState([]);
    const [needDisabled, setNeedDisabled] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [comment, setComment] = useStateCallback('')
    const [evaPartidoList, setEvaPartidoList] = useStateCallback([]);
    const [torneoList, setTorneoList] = React.useState([]);
    const [torneoSuggestions, setTorneoSuggestions] = React.useState([]);
    const [showAdd, setShowAdd] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [torneoValue, settorneoValue] = React.useState('')
    const [torneoItemForInsert, setTorneItemForInsert] = React.useState({});
    const [adding, setAdding] = React.useState(false);
    const [categoriSelected, setCategorieSelected] = React.useState(0);
    const [timeStamp, setTimeStamp] = React.useState(99999999999);
    const [rival, setRival] = React.useState('');
    const [torneoModelForInsert, setTorneoModelForInsert] = React.useState({
        clubID: '',
        categoryID: 0,
        torneoID: 0,
        rival: "",
        observacion: "",
        fecha: '',
    })
    const [torneoModelForInsertUser, setTorneoModelForInsertUser] = React.useState({
        sessionID: '',
        evalacionPartidoID: "",
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        p7: 0,
        p8: 0,
        p9: 0,
        p10: 0,
    })
    const [searchLoading, setSearchLoading] = React.useState(false)

    const [requestTimer, setRequestTimer] = React.useState(null);

    const [disabledTorneo, setDisabledTorneo] = React.useState(true)
    const [showRival, setShowRival] = React.useState(true)
    const [torneoId, setShowInputsEvaluation] = React.useState(false)

    const [contentEvaPartido, setContentEvaPartido] = useStateCallback([])

    // LOAD INITIAL DATA
    React.useEffect(() => {
        // console.log('unMoment', unmounted.current)
        if (unmounted.current) {

            (async () => {

                let session = JSON.parse(window.localStorage.getItem('user-exists'));
                let currentCategori = window.localStorage.getItem('currentCategori')
                let categori = 0

                //console.log('CAtegorie Local ', currentCategori)
                if (currentCategori !== null || currentCategori === undefined || currentCategori === 'undefined') {
                    categori = 15
                } else {
                    currentCategori = JSON.parse(currentCategori)
                    categori = currentCategori
                }
                //  console.log('Categoer ', categori)
                let model = {
                    sessionID: session.lt,
                    categoryID: categori,
                    startDate: 0,
                    endDate: 0
                }
                //console.log('modelAdd', model)
                let initialResult = await getInitialData(model);

                setTorneoModelForInsert(prev => {
                    prev.clubID = session.lt
                    return prev
                })
                let torneoList = await api.GetTorneoListByCLubID({ clubID: session.lt })
                // console.log('TORNE LIST ', torneoList.message.length, 'CLUB ', session.lt)
                if (torneoList.message.length > 0) {
                    if (torneoList.message[0].length > 0) {
                        setTorneoList(torneoList.message[0])
                    }
                }
                setUserWithEva(userEvaluaciones)
                setTimeout(() => {
                    setLoading(false)
                }, 2000);
                // console.log('Context ', contextEvaluacion)


            })();
        }
        return () => { unmounted.current = false }
    }, [])


    // useEffect de $div
    React.useEffect(() => {
        let n = 3
        if (ovrAspect1 <= 0) {
            n = n - 1
        }

        if (ovrAspect2 <= 0) {
            n = n - 1
        }

        if (ovrAspect3 <= 0) {
            n = n - 1
        }
        setDiv(n)
    }, [ovrAspect1, ovrAspect2, ovrAspect3])

    React.useEffect(() => {
        // console.log('USER EVALUACIONES ', userEvaluaciones)
        if (userEvaluaciones.length > 0) {
            setUserWithEva(userEvaluaciones)
        }
    }, [userEvaluaciones])

    // useEffect de poscion
    React.useEffect(() => {
        console.log('initial club', clubCategori)
        if (clubCategori.length > 0) {
            let session = JSON.parse(window.localStorage.getItem('user-exists'));
            let newArray = []
            let obj = {
                value: '',
                label: ''
            }
            if (session.data.isSuper || session.data.isClub) {
                clubCategori.filter((task) => {
                    obj.value = task.categoriesID
                    obj.label = task.categoriesName
                    newArray.push(obj)
                    obj = {
                        value: '',
                        label: ''
                    }
                })

                setOptionCategoria(newArray)
            } else {
                clubCategori.filter((task) => {
                    let currentCategori = task.categoriesID
                    session.data.categoryID.filter((l) => {
                        if (currentCategori === l.categoriesID) {
                            obj.value = task.categoriesID
                            obj.label = task.categoriesName
                            newArray.push(obj)
                            obj = {
                                value: '',
                                label: ''
                            }
                        }
                    })
                })

                //console.log('New Array ', newArray)
                setOptionCategoria(newArray)

            }

        }
    }, [clubCategori])

    React.useEffect(() => {
        //console.log('Current Evaluacion ', currentEvaluacion[0])
        if (currentEvaluacion.length > 0) {
            setEvaluacion(currentEvaluacion[0].evaluationType)
            let filter = currentEvaluacion[0].filterSelected
            setFilterType(filter)
        }
    }, [currentEvaluacion])

    // PUNTUACION EFFECT
    React.useEffect(() => {

        if (puntuacion?.message) {
            setModelPuntuacion(puntuacion.message)
        }
    }, [puntuacion])

    React.useEffect(() => {
        // console.log('User Info ', userInfo)
        if (userInfo.length > 0) {
            setClubID(userInfo[0].clubID)
        }
    }, [userInfo]);

    React.useEffect(() => {
        if (evaluacionPartido.length > 0) {
            let obj = {
                name: "",
                type: ""
            }
            let newArray = {
                name: "",
                content: []

            }
            for (let index = 0; index < evaluacionPartido[0].items.length; index++) {
                let element = evaluacionPartido[0].items[index];

                obj.name = element.Name
                obj.type = element.InputType
                newArray.content.push(obj)
                obj = {
                    name: "",
                    type: ""
                }
            }
            // console.log('NEVAS EVALUACIONS DE PARTIDO ', newArray)
            setContentEvaPartido(newArray.content)
        }
    }, [evaluacionPartido])

    const SetFilterCriterial = async (item) => {
        let user = JSON.parse(window.localStorage.getItem('user-exists'))
        setContextEvalucion([])
        setOptionPlayers([])
        let model = {
            clubID: user.lt,
            categoryID: item.value
        }
        //console.log('model ', item)
        let getUserList = await GetUserByCategorie(model)

        let newArray = []
        let obj = {
            value: '',
            label: ''
        }
        getUserList.filter((task) => {
            obj.value = task.sessionID
            obj.label = task.nombre
            newArray.push(obj)
            obj = {
                value: '',
                label: ''
            }
        })


        setOptionPlayers(newArray)
        setCategorieSelected(item.value)
        setTorneoModelForInsert(prev => {
            prev.categoryID = item.value
            return prev
        })
        setEvaSelect(false)

        let isExistInitEva = await api.GetCheckEvaluacionesDiagnosticaStart({ clubSession: user.lt, categoryID: item.value })


        if (isExistInitEva.message[0][0].isExists <= 0) {
            setShowModal(true)
        }

        let modelNew = {
            sessionID: user.lt,
            categoryID: item.value,
            startDate: 0,
            endDate: 0
        }


        let initialResult = await getInitialData(modelNew);

    }


    const getEvaluacionByFilter = async (item) => {
        let user = JSON.parse(window.localStorage.getItem('user-exists'))

        //FUNCIONES
        const _loadFilterEvaPartido = (data) => {

            setTorneoModelForInsert(prev => {
                prev.rival = data.rival
                prev.torneoID = data.torneoID
                prev.fecha = moment(data.date).format('YYYY-MM-DD')
                return prev
            })
            setRival(data.rival)
            settorneoValue({
                torneoName: data.torneoName
            })

            setTimeout(() => {
                toast.dismiss()
            }, 250);

        }

        const _updateEvaPartidoStatus = async (torneoID) => {
            toast.dismiss();
            setTimeout(() => {
                toast.loading('Actualizando...');
            }, 500);

            let model = {
                clubSession: user.lt,
                torneoID: torneoID,
                categoryID: categoriSelected,
                status: 0
            }
            try {
                let result = await api.UpdateStatusPartido(model)
                //console.log('UPDATE RESULT ', result)
                setTimeout(() => {
                    toast.dismiss();
                    toast.success('Actualización exitosa');
                }, 800);

            } catch (error) {
                toast.error(error);
            }
        }

        let player = userList.filter((task) => {
            if (task.sessionID === item.value) return task
        })

        // VALIDAMOS EL TIPO DE EVALUACION A CARGAR
        if (evaluacion === "Partido") {
            // SE DEBE VALIDAR SI TIENE UNA EVALUACION DE PARTIDO SIN CERRAR
            let model = {
                clubSession: user.lt,
                categoryID: categoriSelected
            }
            let activeEva = await api.CheckStatusEvaluacionPartido(model)

            setTorneoModelForInsertUser(prev => {
                prev.sessionID = item.value
                return prev
            })

            setPlayerSelected(item)
            let objEP = {
                name: item.label,
                content: contentEvaPartido
            }
            setEvaPartidoList([], () => setEvaPartidoList(objEP))

            setEvaluacionPartidoShow(prev => !prev);
            setEvaluacionTecnicaShow(false)

            if (activeEva.message[0].length > 0) {
                // console.log('DATA START THIS SHIT')
                let data = activeEva.message[0][0]
                if (data !== undefined) {
                    if (data.hasOwnProperty('active')) {
                        if (data.active > 0) {
                            toast.custom(<div className="card customToast">
                                <div>
                                    <h2 className="customToastWarning">Warning...</h2>
                                    <p className="customToastText">Nuestro sistema a detectado que tiene una evaluacion pendiente en uso</p>
                                    <p>Fecha de evaluacion: {moment(data.date).format('YYYY-MM-DD')}</p>
                                    <p>Torneo: {data.torneoName}</p>
                                    <p>Rival: {data.rival}</p>
                                </div>
                                <div className="d-flex">
                                    <button className="customToastOkButtom" onClick={() => _loadFilterEvaPartido(data)}>Usar</button>
                                    <button className="customToastCloseButtom" onClick={() => _updateEvaPartidoStatus(data.torneoID)}>Marcar como completado</button>
                                </div>
                            </div>, { duration: timeStamp });
                        }
                    } else {
                        console.log('MOSTRAR ERROR')
                    }
                }

            }
        } else {

            //Obtenemos todas las evaluaciones del dia 
            let userForProcess = []
            let component1 = []
            let component2 = []
            let component3 = []

            let evaInDate = userEvaluaciones.map((task) => {
                if (task.date === moment(new Date()).format('YYYY-MM-DD')) {
                    if (userForProcess.indexOf(task.sessionID) === -1 && task.sessionID === item.value) {
                        userForProcess.push(task)
                    }
                }
            })

            if (userForProcess.length > 0) {
                component1 = userForProcess.filter((task) => {
                    if (task.tecnicaTypeID === 1) return task
                })
                component2 = userForProcess.filter((task) => {
                    if (task.tecnicaTypeID === 2) return task
                })
                component3 = userForProcess.filter((task) => {
                    if (task.tecnicaTypeID === 3) return task
                })
            }
            setPlayerSelected(item)

            // BUSCAMOS QUE EXISTE UNA EVALUACION PARA EL CRITERIO EN CURSO
            let processEvaluation = []
            if (filterType === 'Posición') {
                processEvaluation = currentEvaluacion.filter((task) => {
                    if (task.filterCriterial[0].filterItem.indexOf(player[0].sport_positionID) !== -1) return task
                })
            } else {
                processEvaluation = currentEvaluacion.filter((task) => {
                    if (task.filterCriterial[0].filterItem.indexOf(player[0].categoryID) !== -1) return task
                })
            }

            // VALIDAMOS SI EXISTE ALGUNA EVALUACION PARA ESTE CRITERIO 
            if (processEvaluation.length > 0) {
                setNeedDisabled(false)
                setSessionID(item.value)
                setContextEvalucion([])
                let resultModel = handlerCreateModel(processEvaluation[0].aspect, component1, component2, component3)
                setContextEvalucion(resultModel)


                // CARGAR LA INFORMACION DE ACTUALIZAR SI ES NECESRIO
                if (component1.length > 0 || component2.length > 0 || component3.length > 0) {
                    //Cargar model base
                    // setNeedDisabled(rev => !rev)
                    let base = {
                        sessionID: sessionID,
                        clubID: clubID,
                        promCog: Math.round(component1[0].promCog),
                        promOf: Math.round(component1[0].promDef),
                        promDef: Math.round(component1[0].promOf),
                        ovr: Math.round(component1[0].ovr),
                        observacion: component1[0].observacion,
                        date: moment(new Date()).format('YYYY-MM-DD')
                    }
                    setComment(component1[0].observacion)
                    setOvrAspect1(Math.round(component1[0].promCog))
                    setOvrAspect2(Math.round(component1[0].promDef))
                    setOvrAspect3(Math.round(component1[0].promOf))
                    setModelBase(base)
                    setUpdate(true)
                } else {
                    setModelBase({
                        sessionID: sessionID,
                        clubID: '',
                        promCog: 0,
                        promOf: 0,
                        promDef: 0,
                        ovr: 0,
                        observacion: '',
                        date: moment(new Date()).format('YYYY-MM-DD')
                    })
                    setComment('')
                    setOvrAspect1(0)
                    setOvrAspect2(0)
                    setOvrAspect3(0)

                }
                setEvaluacionTecnicaShow(true)
                setEvaluacionPartidoShow(false)
            } else {
                toast.error(`No se encontro evaluacion para el criterio de filtrado por ${filterType === 'sport_positionID' ? 'Posición' : 'Categoría'}`)
                //formError({ type: 'warning', msg: `No se encontro evaluacion para el criterio de filtrado por ${filterType === 'sport_positionID' ? 'Posición' : 'Categoría'}` })
            }
        }
    }

    const updateContext = async () => {
        let session = JSON.parse(window.localStorage.getItem('user-exists'));
        let currentCategori = window.localStorage.getItem('currentCategori')
        let categori = 0

        if (currentCategori === null || currentCategori === undefined || currentCategori === 'undefined') {
            categori = 15
        }
        currentCategori = JSON.parse(currentCategori)
        categori = currentCategori[0].categoriesID

        //console.log('Categoer ', categori)
        let model = {
            sessionID: session.lt,
            categoryID: parseInt(categori),
            startDate: "00:00:00",
            endDate: '00:00:00'
        }
        console.log('tercer model initialData', model)
        let initialResult = await getInitialData(model);

    }

    const onDismiss = () => {
        setVisible(false)
    }

    const handlerCreateModel = (model, c1, c2, c3) => {

        let objContent = {
            label: "",
            item: []
        }
        let item = {
            id: 0,
            label: "",
            description: "",
            type: "",
            rating: 5,
            percent: 0,
            value: 0
        }
        let aspecModel = {
            name: "",
            rangeDetails: modelPuntuacion,
            content: []
        }

        // ITEM INSERTAR SI EXISTS EVALUACION
        let itemEva = {
            owner: "",
            name: "",
            ovr: 0,
            item: []
        }
        let loadModel = []
        let previewModel = []
        let numeric = []
        let c1F1 = []
        let c1F2 = []

        // CARGAMOS EL PRIMER ASPECTO
        aspecModel.name = model[0].component[0].value

        // CARGAMOS EL RIMER FUNDAMENO
        objContent.label = model[0].fundamento1[0].fundamento[0].value

        // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
        numeric = model[0].fundamento1[0].item


        // CARGAMOS LOS FUNDAMENTOS EVALUACIDOS

        c1F1 = c1.filter((task) => {
            if (parseInt(task.field) === 1) return task
        })
        c1F2 = c1.filter((task) => {
            if (parseInt(task.field) === 2) return task
        })

        // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
        numeric.filter((task, index) => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = 5
            item.type = 'numeric'
            let id = (index + 1)
            for (let i = 0; i < c1F1.length; i++) {
                let elem = c1F1[i];

                if (elem.option === id) {
                    //console.log('ELEME ', elem)
                    item.percent = Math.round(elem.percent)
                    item.value = isNaN(elem.percent / (100 / modelPuntuacion[0].puntuacionRange)) ? 0 : (elem.percent / (100 / modelPuntuacion[0].puntuacionRange))
                }
            }
            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5,
                percent: 0,
                value: 0
            }
        })

        if (c1.length > 0) {

            let objItem = { porcent: 0 }

            itemEva.owner = aspecModel.name
            itemEva.name = objContent.label

            objContent.item.filter((task) => {
                objItem.porcent = task.percent
                itemEva.item.push(objItem)
                objItem = { porcent: 0 }
            })


        }
        loadModel.push(itemEva)
        aspecModel.content.push(objContent)

        objContent = {
            label: "",
            item: []
        }

        // CARGAMOS EL RIMER FUNDAMENO 2
        objContent.label = model[0].fundamento2[0].fundamento[0].value

        // // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
        numeric = model[0].fundamento2[0].item


        // // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
        numeric.filter((task, index) => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = 5
            item.type = 'numeric'
            let id = (index + 1)
            for (let i = 0; i < c1F2.length; i++) {
                let elem = c1F2[i];

                if (elem.option === id) {
                    item.percent = Math.round(elem.percent)
                    item.value = isNaN(elem.percent / (100 / modelPuntuacion[0].puntuacionRange)) ? 0 : (elem.percent / (100 / modelPuntuacion[0].puntuacionRange))
                }
            }
            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5,
                percent: 0,
                value: 0
            }
        })
        loadModel = []
        if (c1.length > 0) {

            let objItem = { porcent: 0 }

            itemEva.owner = aspecModel.name
            itemEva.name = objContent.label

            objContent.item.filter((task) => {
                objItem.porcent = task.percent
                itemEva.item.push(objItem)
                objItem = { porcent: 0 }
            })

            loadModel.push(itemEva)

            console.log('Load Eva ', itemEva)
            handlerForModelInsert(1, "", "", loadModel)
        }

        aspecModel.content.push(objContent)

        objContent = {
            label: "",
            item: []
        }

        //console.log('For Load Model', loadModel)

        previewModel.push(aspecModel)

        //////////////////////////////////////////////////////////////////// 
        // LImpiamos el aspect model para agregar la siguientes
        aspecModel = {
            name: "",
            rangeDetails: modelPuntuacion,
            content: []
        }
        itemEva = {
            owner: "",
            name: "",
            ovr: 0,
            item: []
        }
        loadModel = []


        // SEGUNDO ASPECTO 
        if (model.length > 1) {
            if (model[1].component.length > 0) {
                aspecModel.name = model[1].component[0].value

                c1F1 = c2.filter((task) => {
                    if (parseInt(task.field) === 1) return task
                })
                c1F2 = c2.filter((task) => {
                    if (parseInt(task.field) === 2) return task
                })
                // CARGAMOS EL RIMER FUNDAMENO
                objContent.label = model[1].fundamento1[0].fundamento[0].value

                // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
                numeric = model[1].fundamento1[0].item


                // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO

                numeric.filter((task, index) => {
                    item.id = task.id
                    item.label = task.value
                    item.description = task.definition
                    item.rating = 5
                    item.type = 'numeric'
                    let id = (index + 1)
                    for (let i = 0; i < c1F1.length; i++) {
                        let elem = c1F1[i];

                        if (elem.option === id) {
                            item.percent = Math.round(elem.percent)
                            item.value = isNaN(elem.percent / (100 / modelPuntuacion[0].puntuacionRange)) ? 0 : (elem.percent / (100 / modelPuntuacion[0].puntuacionRange))
                        }
                    }
                    objContent.item.push(item)
                    item = {
                        id: 0,
                        label: "",
                        description: "",
                        type: "",
                        rating: 5,
                        percent: 0,
                        value: 0
                    }
                })

                if (c2.length > 0) {

                    let objItem = { porcent: 0 }

                    itemEva.owner = aspecModel.name
                    itemEva.name = objContent.label

                    objContent.item.filter((task) => {
                        objItem.porcent = task.percent
                        itemEva.item.push(objItem)
                        objItem = { porcent: 0 }
                    })

                    loadModel.push(itemEva)

                    //console.log('Load Eva ', itemEva)
                    //handlerForModelInsert(1, "", "", loadModel)
                }

                aspecModel.content.push(objContent)

                objContent = {
                    label: "",
                    item: []
                }

                // CARGAMOS EL RIMER FUNDAMENO
                objContent.label = model[1].fundamento2[0].fundamento[0].value

                // // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
                numeric = model[1].fundamento2[0].item


                // // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
                numeric.filter((task, index) => {
                    item.id = task.id
                    item.label = task.value
                    item.description = task.definition
                    item.rating = 5
                    item.type = 'numeric'
                    let id = (index + 1)
                    for (let i = 0; i < c1F2.length; i++) {
                        let elem = c1F2[i];

                        if (elem.option === id) {
                            item.percent = Math.round(elem.percent)
                            item.value = isNaN(elem.percent / (100 / modelPuntuacion[0].puntuacionRange)) ? 0 : (elem.percent / (100 / modelPuntuacion[0].puntuacionRange))
                        }
                    }
                    objContent.item.push(item)
                    item = {
                        id: 0,
                        label: "",
                        description: "",
                        type: "",
                        rating: 5,
                        percent: 0,
                        value: 0
                    }
                })
                loadModel = []
                if (c2.length > 0) {

                    let objItem = { porcent: 0 }

                    itemEva.owner = aspecModel.name
                    itemEva.name = objContent.label

                    objContent.item.filter((task) => {
                        objItem.porcent = task.percent
                        itemEva.item.push(objItem)
                        objItem = { porcent: 0 }
                    })

                    loadModel.push(itemEva)

                    //console.log('Load Eva ', itemEva)
                    handlerForModelInsert(2, "", "", loadModel)
                }
                aspecModel.content.push(objContent)

                objContent = {
                    label: "",
                    item: []
                }

                previewModel.push(aspecModel)

                /////////////////////////////////////////////////

                // LImpiamos el aspect model para agregar la siguientes
                aspecModel = {
                    name: "",
                    rangeDetails: modelPuntuacion,
                    content: []
                }


            }

            if (model.length > 2) {
                // ASPECTO 3
                if (model[2].component.length > 0) {
                    aspecModel.name = model[2].component[0].value

                    itemEva = {
                        owner: "",
                        name: "",
                        ovr: 0,
                        item: []
                    }
                    loadModel = []
                    // CARGAMOS EL RIMER FUNDAMENO
                    objContent.label = model[2].fundamento1[0].fundamento[0].value
                    c1F1 = c3.filter((task) => {
                        if (parseInt(task.field) === 1) return task
                    })
                    c1F2 = c3.filter((task) => {
                        if (parseInt(task.field) === 2) return task
                    })
                    // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
                    numeric = model[2].fundamento1[0].item


                    // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
                    numeric.filter((task, index) => {
                        item.id = task.id
                        item.label = task.value
                        item.description = task.definition
                        item.rating = 5
                        item.type = 'numeric'
                        let id = (index + 1)
                        for (let i = 0; i < c1F1.length; i++) {
                            let elem = c1F1[i];

                            if (elem.option === id) {
                                item.percent = Math.round(elem.percent)
                                item.value = isNaN(elem.percent / (100 / modelPuntuacion[0].puntuacionRange)) ? 0 : (elem.percent / (100 / modelPuntuacion[0].puntuacionRange))
                            }
                        }
                        objContent.item.push(item)
                        item = {
                            id: 0,
                            label: "",
                            description: "",
                            type: "",
                            rating: 5,
                            percent: 0,
                            value: 0
                        }
                        return task
                    })
                    if (c3.length > 0) {

                        let objItem = { porcent: 0 }

                        itemEva.owner = aspecModel.name
                        itemEva.name = objContent.label

                        objContent.item.filter((task) => {
                            objItem.porcent = task.percent
                            itemEva.item.push(objItem)
                            objItem = { porcent: 0 }
                        })

                        loadModel.push(itemEva)

                        //console.log('Load Eva ', itemEva)
                        //handlerForModelInsert(1, "", "", loadModel)
                    }
                    aspecModel.content.push(objContent)

                    objContent = {
                        label: "",
                        item: []
                    }

                    // CARGAMOS EL RIMER FUNDAMENO
                    objContent.label = model[2].fundamento2[0].fundamento[0].value

                    // // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
                    numeric = model[2].fundamento2[0].item


                    // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
                    numeric.filter((task, index) => {
                        item.id = task.id
                        item.label = task.value
                        item.description = task.definition
                        item.rating = 5
                        item.type = 'numeric'
                        let id = (index + 1)

                        for (let i = 0; i < c1F2.length; i++) {
                            let elem = c1F2[i];

                            if (elem.option === id) {
                                item.percent = Math.round(elem.percent)
                                item.value = isNaN(elem.percent / (100 / modelPuntuacion[0].puntuacionRange)) ? 0 : (elem.percent / (100 / modelPuntuacion[0].puntuacionRange))
                            }
                        }
                        objContent.item.push(item)
                        item = {
                            id: 0,
                            label: "",
                            description: "",
                            type: "",
                            rating: 5,
                            percent: 0,
                            value: 0
                        }
                        return task
                    })
                    loadModel = []
                    if (c3.length > 0) {

                        let objItem = { porcent: 0 }

                        itemEva.owner = aspecModel.name
                        itemEva.name = objContent.label

                        objContent.item.filter((task) => {
                            objItem.porcent = task.percent
                            itemEva.item.push(objItem)
                            objItem = { porcent: 0 }
                        })

                        loadModel.push(itemEva)

                        //console.log('Load Eva ', itemEva)
                        handlerForModelInsert(1, "", "", loadModel)
                    }
                    aspecModel.content.push(objContent)

                    objContent = {
                        label: "",
                        item: []
                    }

                }

            }


        }


        previewModel.push(aspecModel)


        return previewModel

    }

    const formError = (props) => {
        switch (props.type) {
            case 'warning':
                setAlertType(props.type)
                setMsg(props.msg)
                setVisible(true)
                break;
            case 'success':
                setAlertType(props.type)
                setMsg(props.msg)
                setVisible(true)
                break;
            default:
                break;
        }

        setTimeout(() => {
            setVisible(false)
        }, 1850);
    }

    const handlerForModelInsert = (owner, aspect, itemIndex, model) => {

        let fun1 = 0
        let funLen = 0
        let fun2 = 0
        let fun2Len = 0
        model.filter((task, index) => {
            if (index === 0) {
                task.item.filter((l) => {
                    fun1 = Math.round(fun1 + l.porcent)
                    if (l.porcent > 0) {
                        funLen++
                    }

                    return l
                })
            } else {
                task.item.filter((l) => {
                    fun2 = Math.round(fun2 + l.porcent)
                    if (l.porcent > 0) {
                        fun2Len++
                    }
                    return l
                })
            }

            return task
        })

        let val1 = isNaN(Math.round((fun1 / funLen))) ? 0 : Math.round((fun1 / funLen))
        let val2 = isNaN(Math.round((fun2 / fun2Len))) ? 0 : Math.round((fun2 / fun2Len))

        let ovr = val1 <= 0 ? val2 : val2 <= 0 ? val1 : isNaN(Math.round((val1 + val2) / 2)) ? 0 : Math.round((val1 + val2) / 2)

        let newModel = modelBase
        newModel.sessionID = sessionID
        newModel.clubID = clubID
        let addItem = []
        switch (owner) {
            case 1:
                setOvrAspect1(ovr)
                newModel.promCog = ovr
                newModel.ovr = Math.round((newModel.promCog + newModel.promDef + newModel.promOf) / 3)
                setModelBase(newModel)
                model.filter((task, index) => {
                    if (index === 0) {
                        task.item.filter((t, i) => {
                            AddItemInModel(1, 1, (i + 1), t.porcent)
                        })
                    } else {
                        task.item.filter((t, i) => {
                            AddItemInModel(1, 2, (i + 1), t.porcent)
                        })
                    }
                })

                break;
            case 2:
                setOvrAspect2(ovr)
                newModel.promDef = ovr
                newModel.ovr = Math.round((newModel.promCog + newModel.promDef + newModel.promOf) / 3)
                setModelBase(newModel)
                model.filter((task, index) => {
                    if (index === 0) {
                        task.item.filter((t, i) => {
                            AddItemInModel(2, 1, (i + 1), t.porcent)
                        })
                    } else {
                        task.item.filter((t, i) => {
                            AddItemInModel(2, 2, (i + 1), t.porcent)
                        })
                    }
                })
                break;
            case 3:
                setOvrAspect3(ovr)
                newModel.promOf = ovr
                newModel.ovr = Math.round((newModel.promCog + newModel.promDef + newModel.promOf) / 3)
                setModelBase(newModel)
                model.filter((task, index) => {
                    if (index === 0) {
                        task.item.filter((t, i) => {
                            AddItemInModel(3, 1, (i + 1), t.porcent)
                        })
                    } else {
                        task.item.filter((t, i) => {
                            AddItemInModel(3, 2, (i + 1), t.porcent)
                        })
                    }
                })
                break;
            default:
                break;
        }
        //console.log('Owner', owner, 'Aspect ', aspect)
    }

    const AddItemInModel = (type, field, option, percent) => {
        let objItem = {
            evaluacionTecnicaID: 0,
            type: '',
            option: '',
            field: '',
            percent: ''
        }
        let newModelBase = modelBaseItem
        //console.log('New Model Base ', newModelBase)
        if (newModelBase.length <= 0) {
            objItem.type = type
            objItem.field = field
            objItem.option = option
            objItem.percent = percent

            newModelBase.push(objItem)
        } else {
            let needInsert = true
            newModelBase.filter((task, i) => {
                if (task.type === type && task.field === field && task.option === option) {
                    task.percent = percent
                    needInsert = false
                    return false
                }
            })

            if (needInsert) {
                objItem.type = type
                objItem.field = field
                objItem.option = option
                objItem.percent = percent

                newModelBase.push(objItem)
            }
        }


        // ACTUALIZAMOS EL MODELO BASE ITEM

        setModelBaseItem(newModelBase)
    }

    const addComent = (event) => {
        setComment(event.target.value)
        setModelBase(prev => {
            prev.observacion = event.target.value
            return prev
        })
    }

    const hanlderSaveEvaluation = async () => {
        let isValid = await helperManager.ValidateTecnicaInsertModel(modelBase, true)
        //console.log(isValid)
        if (isValid <= 0) {

            let addBase = await api.InsertEvaluacionesBase(modelBase);
            /// console.log('addbase ', addBase)
            if (addBase.message[0][0].isInsert > 0) {
                let evaluationID = addBase.message[0][0].isInsert
                //console.log('evaluationID', modelBase)

                if (evaluationID !== undefined) {
                    // SE CREA UN LOOP PARA INSERTAR LOS DETALLES 
                    let insertTotal = modelBaseItem.filter(async (task, index) => {
                        task.evaluacionTecnicaID = evaluationID
                        let resultItem = await api.AddEvaluacionesTecnicaInfo(task)
                        return task
                        // console.log('ITEMS RESULT ', resultItem)
                    })

                    //console.log('Insert Total ', insertTotal.length, 'MODEL BASE ITEM ', modelBaseItem.length)
                    if (insertTotal.length === modelBaseItem.length) {
                        if (update) {
                            toast.success('La evaluacion se actualizo con exito!')
                            //formError({ type: 'success', msg: 'La evaluacion se actualizo con exito... ' })

                        } else {
                            toast.success('La evaluacion se se creo con exito!')
                            //formError({ type: 'success', msg: 'La evaluacion se creo con exito...' })
                        }
                        updateContext()
                        setTimeout(() => {

                            clearParams()
                        }, 1250);

                    }
                } else {
                    toast.error("No se puedo realizar esta operacion, consultar con el soporte tecnico")
                }

            } else {
                toast.error(`No se puedo realizar esta operacion error: Ya se realizo evaluacion para este atleta el dia de hoy`)
                //formError({ type: 'warning', msg: 'No se puedo realizar esta operacion' })
            }
        } else {
            toast.error("El formulario no puede contener campos vacio...")
            // formError({ type: 'warning', msg: 'El formulario no puede contener campos vacio...' })
        }

        //console.log('Model Base ', isValid)
    }

    const clearParams = () => {
        setComment('')
        setContextEvalucion([])
        setOvrAspect1(0)
        setOvrAspect2(0)
        setOvrAspect3(0)
        setPlayerSelected(null)
        setUpdate(false)
    }
    const toggleModal = () => {

        setMoveRow(prev => !prev)
    }


    // ADD TORNEOS

    const addTorneoID = async (item) => {
        if (typeof item === 'object') {
            setTorneoModelForInsert(prev => {
                prev.torneoID = item.torneoID
                return prev
            })


        } else {
            setAdding(true)
            let resultAddTorneo = await api.AddTorneo({ torneoName: item.toUpperCase() })
            if (resultAddTorneo.success) {
                let torneoID = resultAddTorneo.data.message.data[0][0].torneoID
                setTorneoModelForInsert(prev => {
                    prev.torneoID = torneoID
                    return prev
                })



                let session = JSON.parse(window.localStorage.getItem('user-exists'));
                let torneoList = await api.GetTorneoListByCLubID({ clubID: session.lt })
                if (torneoList.success) {
                    if (torneoList.data.message[0].length > 0) {
                        setTorneoList(torneoList.data.message[0])
                        setTimeout(() => {
                            setAdding(null)
                        }, 2000);
                    }
                }
            }


        }
    }

    const updateModelForInser = (value, type) => {

        switch (type) {
            case 1:
                setTorneoModelForInsert({ ...torneoModelForInsert, fecha: value.target.value })

                if (torneoModelForInsert.fecha !== '') {
                    setDisabledTorneo(false)
                }

                break;
            case 2:
                setRival(value.target.value)
                setTorneoModelForInsert({ ...torneoModelForInsert, rival: value.target.value })

                if (torneoModelForInsert.rival.length >= 4) {
                    setShowRival(false)
                }

            default:
                break;
        }
    }

    const addObservacionTorneo = (value) => {
        setComment(value.target.value)
        setTorneoModelForInsert(prev => {
            prev.observacion = value.target.value
            return prev
        })
        // console.log('SE ESTA AGREGANDO COMENTARIO A PARTIDO ', value.target.value)
    }

    const addFieldInModel = (value, type) => {

        switch (type) {
            case 0:
                setTorneoModelForInsertUser(prev => {
                    prev.p1 = parseInt(value)
                    return prev
                })
                break;
            case 1:
                setTorneoModelForInsertUser(prev => {
                    prev.p2 = parseInt(value)
                    return prev
                })
                break;
            case 2:
                setTorneoModelForInsertUser(prev => {
                    prev.p3 = parseInt(value)
                    return prev
                })
                break;
            case 3:
                setTorneoModelForInsertUser(prev => {
                    prev.p4 = parseInt(value)
                    return prev
                })
                break;
            case 4:
                setTorneoModelForInsertUser(prev => {
                    prev.p5 = parseInt(value)
                    return prev
                })
                break;
            case 5:
                setTorneoModelForInsertUser(prev => {
                    prev.p6 = parseInt(value)
                    return prev
                })
                break;
            case 6:
                setTorneoModelForInsertUser(prev => {
                    prev.p7 = parseInt(value)
                    return prev
                })
                break;
            case 7:
                setTorneoModelForInsertUser(prev => {
                    prev.p8 = parseInt(value)
                    return prev
                })
                break;
            case 8:
                setTorneoModelForInsertUser(prev => {
                    prev.p9 = parseInt(value)
                    return prev
                })
                break;
            case 9:
                setTorneoModelForInsertUser(prev => {
                    prev.p10 = parseInt(value)
                    return prev
                })
                break;
            default:
                break;
        }

    }

    const _addEvaluacionesDePartidos = async () => {

        let user = JSON.parse(window.localStorage.getItem('user-exists'))

        let modelForClub = {
            clubID: torneoModelForInsert.clubID,
            categoryID: torneoModelForInsert.categoryID,
            torneoID: torneoModelForInsert.torneoID,
            rival: torneoModelForInsert.rival,
            observacion: torneoModelForInsert.observacion,
            matchDate: torneoModelForInsert.fecha
        }

        let model = {
            clubSession: user.lt,
            categoryID: categoriSelected
        }


        try {
            // INSERTAR LA EVALUCION INCIAL

            // SE VALIDA QUE LOS INPUTS TENGAN DATA
            if (modelForClub.rival === '' && modelForClub.torneoID <= 0) {
                toast.error('El campo rival y torneo debe contener información.')
                return false
            }

            if (modelForClub.torneoID <= 0) {
                toast.error('El campo torneo debe contener información.')
                return false
            }

            // SE VALIDAD CAMPOS DE EVALUACION NO LLEGUEN VACIOS PARA PROCESAR LA INFO
            if (torneoModelForInsertUser.p1 <= 0 || torneoModelForInsertUser.p2 <= 0 || torneoModelForInsertUser.p3 <= 0) {
                toast.error('Estos campos deben contener información: Puntaje Ofensivo, Puntaje Defensivo, Puntaje de Actitud')
                return false
            }

            let activeEva = await api.CheckStatusEvaluacionPartido(model)

            let clubInsert = await api.AddEvaluacionPartidoInClub(modelForClub)


            // SI YA EXISTE UNA EV. PARTIDO SE ACTUALIZA EVA_PARTIDO_USER
            if (activeEva.message[0].length > 0) {

                let modelForUser = {
                    sessionID: torneoModelForInsertUser.sessionID,
                    evalacionPartidoID: activeEva.message[0][0].evaluacion_partidoID,
                    p1: torneoModelForInsertUser.p1,
                    p2: torneoModelForInsertUser.p2,
                    p3: torneoModelForInsertUser.p3,
                    p4: torneoModelForInsertUser.p4,
                    p5: torneoModelForInsertUser.p5,
                    p6: torneoModelForInsertUser.p6,
                    p7: torneoModelForInsertUser.p7,
                    p8: torneoModelForInsertUser.p8,
                    p9: torneoModelForInsertUser.p9,
                    p10: torneoModelForInsertUser.p10,
                }

                let userInsert = await api.AddEvaluacionPartidoInUser(modelForUser)

                if (userInsert.message[0][0].isInsert > 0) {
                    setPlayerSelected('')
                    setEvaluacionPartidoShow(false)
                    setRival('')
                    settorneoValue('')
                    toast.success(`La evaluación para ${playerSelected.label} se agrego correctamente`)

                }

            } else {
                // NO EXISTE EVA. PARTIDO Y SE ALMACENA LA EVALUACION
                if (clubInsert.message[0][0].isInsert > 0) {
                    let evaluacionPartidoID = clubInsert.message[0][0].evaluacionPartidoID

                    let modelForUser = {
                        sessionID: torneoModelForInsertUser.sessionID,
                        evalacionPartidoID: evaluacionPartidoID,
                        p1: torneoModelForInsertUser.p1,
                        p2: torneoModelForInsertUser.p2,
                        p3: torneoModelForInsertUser.p3,
                        p4: torneoModelForInsertUser.p4,
                        p5: torneoModelForInsertUser.p5,
                        p6: torneoModelForInsertUser.p6,
                        p7: torneoModelForInsertUser.p7,
                        p8: torneoModelForInsertUser.p8,
                        p9: torneoModelForInsertUser.p9,
                        p10: torneoModelForInsertUser.p10,
                    }

                    let userInsert = await api.AddEvaluacionPartidoInUser(modelForUser)
                    //console.log('EVALAACION RESULT DETAILS ', userInsert)
                    if (userInsert.message[0][0].isInsert > 0) {
                        setPlayerSelected('')
                        setEvaluacionPartidoShow(false)
                        setRival('')
                        settorneoValue('')
                        toast.success(`La evaluación para ${playerSelected.label} se agrego correctamente`)

                    }

                } else {
                    console.log('CLUB INSERT RESUT ', clubInsert)
                }
            }

        } catch (error) {
            toast.error(`Error en ejecucion ${error}`)
        }

    }

    const _addStartPoint = async () => {

        let user = JSON.parse(window.localStorage.getItem('user-exists'))
        let model = {
            clubSession: user.lt,
            categoryID: categoriSelected,
            date: moment(new Date()).format('YYYY-MM-DD')
        }

        let result = await api.AddEvaluacionesDiagnosticaStart(model)

        if (result.message[0][0].isInsert > 0) {

            toast.success(`Se actualizó correctamente`)
            setShowModal(false)
        }
    }


    return (
        <div className="content">

            <Card className="chart-card card">

                <CardBody>
                    <Row className="p-3">
                        <Col xs={5}>
                            {/*SE AGREGA CONDICION PAR CAMBIAR EL NOMBRE DE PARTIDO A COMPETENCIA*/}
                            <h2>{evaluacion === "Partido" ? "Competencia" : evaluacion}</h2>
                        </Col>
                        {
                            evaluacion === 'Técnica' &&
                            <>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Select
                                            onChange={(item) => SetFilterCriterial(item)}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="singleSelect"
                                            options={optionCategoria}
                                            placeholder="Seleccione una categoría"
                                        />
                                        <FormFeedback >
                                            Debe seleccionar los criterios de filtrado
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <Select
                                            isDisabled={evaSelect}
                                            onChange={(item) => getEvaluacionByFilter(item)}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={playerSelected}
                                            name="singleSelect"
                                            options={optionPlayers}
                                            placeholder="Seleccione un atleta"
                                        />
                                        <FormFeedback >
                                            Debe seleccionar los criterios de filtrado
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </>
                        }

                        {
                            evaluacion === "Partido" && <>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Select
                                            onChange={(item) => SetFilterCriterial(item)}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="singleSelect"
                                            options={optionCategoria}
                                            placeholder="Seleccione una categoría"
                                        />
                                        <FormFeedback >
                                            Debe seleccionar los criterios de filtrado
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <Select
                                            isDisabled={evaSelect}
                                            onChange={(item) => getEvaluacionByFilter(item)}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={playerSelected}
                                            name="singleSelect"
                                            options={optionPlayers}
                                            placeholder="Seleccione un atleta"
                                        />
                                        <FormFeedback >
                                            Debe seleccionar los criterios de filtrado
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </>

                        }

                    </Row>
                    {
                        evaluacionTecnicaShow && <PreviewContainer >
                            {
                                contextEvaluacion.length > 0 && <Row className='mb-5'>
                                    <Col className='colCenter colDivider'>
                                        <div className='colCenter'>
                                            <span style={{ ...FONTS.h4 }}>Aspecto 1</span>
                                            <span style={{ ...FONTS.h3 }}>{ovrAspect1}%</span>
                                        </div>
                                    </Col>
                                    <Col className='colCenter colDivider'>
                                        <div className='colCenter'>
                                            <span style={{ ...FONTS.h4 }}>Aspecto 2</span>
                                            <span style={{ ...FONTS.h3 }}>{ovrAspect2}%</span>
                                        </div>
                                    </Col>
                                    <Col className='colCenter colDivider'>
                                        <div className='colCenter'>
                                            <span style={{ ...FONTS.h4 }}>Aspecto 3</span>
                                            <span style={{ ...FONTS.h3 }}>{ovrAspect3}%</span>
                                        </div>
                                    </Col>
                                    <Col className='colCenter colDivider'>
                                        <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                                            <span style={{ ...FONTS.h4 }}>OVR</span>
                                            <Imgbck props={{ background: background, height: '30px' }}>
                                                <span className='labelImbck' style={{ ...FONTS.h3 }}>{
                                                    isNaN(Math.round((ovrAspect1 + ovrAspect2 + ovrAspect3) / $div)) ? 0 : Math.round((ovrAspect1 + ovrAspect2 + ovrAspect3) / $div)

                                                }%</span>
                                            </Imgbck>

                                        </div>
                                    </Col>
                                    <Col xs={2} className='colCenter'>
                                        <ButtonGroup>
                                            <Button disabled={needDisabled} onClick={() => hanlderSaveEvaluation()} color="success" className="animation-on-hover" size="sm">Salvar</Button>
                                            {
                                                !moveRow ?
                                                    <Button className="btn-icon" color="twitter" size='sm' onClick={() => toggleModal()}>
                                                        <i className="tim-icons icon-pencil"></i>
                                                    </Button> :
                                                    <Button className="btn-icon" color="danger" size='sm' onClick={() => toggleModal()}>
                                                        <i className="tim-icons icon-simple-remove"></i>
                                                    </Button>
                                            }

                                        </ButtonGroup>
                                    </Col>
                                </Row>

                            }

                            {
                                contextEvaluacion.length >= 3 &&
                                <>
                                    <AspectComponent data={contextEvaluacion[0]} showDetails={true} owner={1} onUpdate={(owner, aspect, itemIndex, model) => handlerForModelInsert(owner, aspect, itemIndex, model)} puntuacionRange={modelPuntuacion} />
                                    <AspectComponent data={contextEvaluacion[1]} showDetails={true} owner={2} onUpdate={(owner, aspect, itemIndex, model) => handlerForModelInsert(owner, aspect, itemIndex, model)} puntuacionRange={modelPuntuacion} />
                                    <AspectComponent data={contextEvaluacion[2]} showDetails={true} owner={3} onUpdate={(owner, aspect, itemIndex, model) => handlerForModelInsert(owner, aspect, itemIndex, model)} puntuacionRange={modelPuntuacion} />
                                </>
                            }

                            {
                                contextEvaluacion.length === 1 && <AspectComponent data={contextEvaluacion[0]} showDetails={true} owner={1} onUpdate={(owner, aspect, itemIndex, model) => handlerForModelInsert(owner, aspect, itemIndex, model)} puntuacionRange={modelPuntuacion} />
                            }

                            {
                                contextEvaluacion.length === 2 &&
                                <>
                                    <AspectComponent data={contextEvaluacion[0]} showDetails={true} owner={1} onUpdate={(owner, aspect, itemIndex, model) => handlerForModelInsert(owner, aspect, itemIndex, model)} puntuacionRange={modelPuntuacion} />
                                    <AspectComponent data={contextEvaluacion[1]} showDetails={true} owner={2} onUpdate={(owner, aspect, itemIndex, model) => handlerForModelInsert(owner, aspect, itemIndex, model)} puntuacionRange={modelPuntuacion} />
                                </>

                            }

                        </PreviewContainer>
                    }
                    {
                        evaluacionPartidoShow &&
                        <PreviewContainer>
                            <Card style={{ height: '60px' }}>
                                <Row style={{ gap: 0 }}>
                                    <Col xs={3} style={{ padding: 2 }}>
                                        <InputTextArea
                                            value={torneoModelForInsert.fecha}
                                            type="date"
                                            placeholder="Fecha de partido"
                                            style={{ width: '100%' }}
                                            onChange={(e) => updateModelForInser(e, 1)} />
                                    </Col>
                                    <Col xs={3} style={{ padding: 2 }}>
                                        <InputTextArea
                                            disabled={disabledTorneo}
                                            value={rival}
                                            placeholder="Rival"
                                            style={{ width: '100%' }}
                                            onChange={(e) => updateModelForInser(e, 2)} />
                                    </Col>

                                    <Col xs={3} style={{ padding: 2 }}>
                                        <Autocomplete
                                            disabled={showRival}
                                            value={torneoValue}
                                            items={torneoList}
                                            isLoading={adding}
                                            onSelect={(item) => addTorneoID(item)}
                                            onChange={(item) => console.log('onChange', item)}
                                            handlerButtom={(item) => addTorneoID(item)}
                                        />
                                    </Col>

                                    <Col xs={3} className='colCenter' style={{ padding: 2 }}>
                                        <ButtonGroup>
                                            <Button disabled={needDisabled} onClick={() => _addEvaluacionesDePartidos()} color="success" className="animation-on-hover" size="sm">Salvar</Button>
                                            {
                                                !moveRow ?
                                                    <Button className="btn-icon" color="twitter" size='sm' onClick={() => toggleModal()}>
                                                        <i className="tim-icons icon-pencil"></i>
                                                    </Button> :
                                                    <Button className="btn-icon" color="danger" size='sm' onClick={() => toggleModal()}>
                                                        <i className="tim-icons icon-simple-remove"></i>
                                                    </Button>
                                            }

                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Card>


                            <Card>
                                <AspectComponentPartido data={evaPartidoList} puntuacionRange={10} onUpdate={(value, index) => addFieldInModel(value, index)}
                                />
                            </Card>

                        </PreviewContainer>
                    }
                    <Row className={moveRow ? 'ons' : 'in'}>
                        <Card style={{ boxShadow: '1px 4px 10px #6b6464cc', width: '290px', zIndex: 999 }} className="card_comentText">
                            <h4 className="comentText">Agregar Comentario</h4>
                            <CardBody>
                                <InputTextArea
                                    className="textArea"
                                    type="textarea"
                                    value={comment}
                                    rows="5"
                                    onChange={evaluacionPartidoShow ? addObservacionTorneo : addComent}
                                />
                                {/* <textarea className="textArea" onChangeCapture={(e) => addComent(e)} defaultValue={comment}></textarea> */}
                            </CardBody>
                            <CardFooter>
                                <ButtonGroup style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}>
                                    <Button size="sm" color="success" onClick={() => toggleModal()}>
                                        Agregar
                                    </Button>
                                </ButtonGroup>
                            </CardFooter>
                        </Card>
                    </Row>
                </CardBody>
            </Card>

            <ModalComponent show={showModal} height={"40%"}>
                <ModalBody style={{ marginTop: "-15px" }}>
                    <Row>
                        <Col>
                            <h4>fitwave Sport Technology</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>En <strong>fitwave</strong> estamos en constante mejora es por eso que te invitamos a que nos indiques la fecha de tu evaluación inicial para tener un punto de comparación para brindarte un mejor resumen de el estado de tus jugadores</p>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button size="sm" style={{ fontSize: 12 }} onClick={() => _addStartPoint()}>
                                Fecha inicial {moment(new Date()).format('YYYY-MM-DD')}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>

            </ModalComponent>
        </div>
    )
}

export default AddEvaluacionScreen;