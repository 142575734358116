
import Manager from '../api/main/EvaluacionServices';
const api = new Manager();

class AddEvaluacionesAPI {
    constructor() {
        this.response = {
            success: true,
            error: '',
            data: []
        }
    }

    async InsertEvaluacionesBase(model) {
        try {
            let result = await api.AddEvaluacionesBase(model)
            console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddEvaluacionesTecnicaInfo(model) {
        try {
            let result = await api.AddEvaluacionesTecnicaInfo(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddTorneo(model) {
        try {
            let result = await api.AddTorneo(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddEvaluacionPartidoInClub(model) {
        try {
            let result = await api.AddEvaluacionPartidoClub(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddEvaluacionPartidoInUser(model) {
        try {
            let result = await api.AddEvaluacionPartidoUser(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async GetTorneoListByCLubID(model) {
        try {
            let result = await api.GetTorneoList(model)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
    
    async CheckStatusEvaluacionPartido(model){
        try {
            let result = await api.CheckEvaluacionPartidoStatus(model)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
    async GetCheckEvaluacionesDiagnosticaStart(model) {
        // console.log('modelCheck', model)
         try {
             
             let result = await api.CheckEvaluacionDiagnostica(model)
          //   console.log('RESULT ', result)
             return result;
         } catch (error) {
             console.log('Error ', error)
             return error
         }
     }
     async AddEvaluacionesDiagnosticaStart(model) {
        // console.log('modelCheck', model)
         try {
             
             let result = await api.AddEvaluacionDiagnostica(model)
          //   console.log('RESULT ', result)
             return result;
         } catch (error) {
             console.log('Error ', error)
             return error
         }
     }
    async UpdateStatusPartido(model){
        try {
            let result = await api.UpdateStatusPartido(model)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddReports(model){
        try {
            let result = await api.AddReport(model)
         //   console.log('result main', result)
            return result
        } catch (error) {
            console.log('Error', error)
            return error
        }
    }

    async GetReportsClub(model){
        try {
            let result = await api.GetReportsByClubID(model)
          //  console.log('result main', result)
            return result
        } catch (error) {
            console.log('Error', error)
            return error
        }
    }

    async AddReportMain(model){
        try {
            let result = await api.AddReportsMain(model)
            console.log('result main', result)
            return result
        } catch (error) {
            console.log('Error', error)
            return error
        }
    }

    async AddReportDetails(model){
        try {
            let result = await api.AddReportsDetails(model)
            console.log('result main', result)
            return result
        } catch (error) {
            console.log('Error', error)
            return error
        }
    }

    async GetReportEvluation(model){
        try {
            let result = await api.GetReportsEva(model)
          //  console.log('result main', result)
            return result
        } catch (error) {
            console.log('Error', error)
            return error
        }
    }
}



export default AddEvaluacionesAPI;