import React from "react";
import uuid from "react-uuid";
import { toast } from "react-hot-toast";
import GlobalContext from "redux/context";
import moment from "moment";
import Manager from "../source/AddEvaluaciones"
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert,
  Input,
  FormGroup,
  Button,
  Form,
  ButtonGroup
} from "reactstrap";
import { element } from "prop-types";
import Select from "react-select";
import { useStateCallback } from "hooks/useStateCallback";


const api = new Manager()
function SettingReports() {
  const userContext = React.useContext(GlobalContext);
  const { state, addCurrentReport, endLoading } = userContext;
  const { currentReport } = state;
  const [contextReport, setContextReport] = useStateCallback({})
  const [inputArray, setInputArray] = React.useState([{
    id: 1, value: ""
  }, {
    id: 2, value: ""
  }, {
    id: 3, value: ""
  }, {
    id: 4, value: ""
  }]);
  const [modelForInsert, setModelForInsert] = React.useState({
    clubID: 0,
    reportID: 0,
    inputItems: [],
    categorieID: 0,
    createOn: moment(new Date()).format('YYYY-MM-DD')
  });
  const [showData, setShowData] = React.useState(false)
  const [showDataCategories, setShowDataCategories] = useStateCallback(false)
  const [dataFilterCategories, SetDataFilterCategories] = React.useState([])
  //const [dataReportByClub, setDataReportByClub] = React.useState([])

  //////Load Initial
  React.useEffect(() => {


    let categoriesList = JSON.parse(window.localStorage.getItem('clubCategoriesList'))

    let newArray = []

    let obj = {
      value: '',
      label: ''
    }
    for (let index = 0; index < categoriesList.length; index++) {
      let element = categoriesList[index];
      //  console.log('elem', element)
      obj.value = element.categoriesID
      obj.label = element.categoriesName
      newArray.push(obj)
      obj = {
        value: '',
        label: ''
      }

    }
    SetDataFilterCategories(newArray)

    if (currentReport.hasOwnProperty('id')) {
      setContextReport({}, () => setContextReport({}))
      setShowDataCategories(false, () => setShowDataCategories(false))
    }
    endLoading();
    _clearPage()
  }, [])


  React.useEffect(() => {
    let session = JSON.parse(window.localStorage.getItem('user-exists'));
    if (currentReport.hasOwnProperty('id') && currentReport.hasOwnProperty('value')) {
      if (contextReport !== currentReport.value) {
        setShowData(false)
        setContextReport(currentReport)
      }
      setContextReport(currentReport)
      setShowDataCategories(true)
      setModelForInsert({ ...modelForInsert, reportID: currentReport.id, clubID: session.lt })

    }
  }, [currentReport])

  const _clearPage = async () => {
    setContextReport('Seleccione un reporte')
    setShowData(false)
    setShowDataCategories(false)
    setInputArray([{
      id: 1, value: ""
    }, {
      id: 2, value: ""
    }, {
      id: 3, value: ""
    }, {
      id: 4, value: ""
    }])
    if (currentReport.hasOwnProperty('id')) {
      setContextReport({}, () => setContextReport({}))
    }
    setModelForInsert({
      clubID: 0,
      reportID: 0,
      inputItems: [],
      categorieID: 0,
      createOn: moment(new Date()).format('YYYY-MM-DD')
    })
    await addCurrentReport({})
  }



  ////////FUNCIONES
  const addInputReport = () => {

    let arrayAdd = [...inputArray]

    arrayAdd.push({
      id: arrayAdd.length + 1,
      value: ''
    })
    setInputArray(arrayAdd)
    // if (arrayAdd.length <= 41) {
    //   arrayAdd.push({
    //     id: arrayAdd.length + 1,
    //     value: ''
    //   })
    //   setInputArray(arrayAdd)
    //   //  console.log('array add', arrayAdd)
    // } else {
    //   toast.error('Solo se permiten agregar 30 inputs en este reporte')
    // }
  }

  const inputsReport = (id, value) => {

    let newInpt = { ...modelForInsert }
    let inputArrayVisula = [...inputArray]
    let obj = {
      id: id,
      value: value
    }
    let needInsert = true
    for (let index = 0; index < newInpt.inputItems.length; index++) {
      let elem = newInpt.inputItems[index];
      if (elem.id === id) {
        newInpt.inputItems[index].value = value
        needInsert = false
      }
    }
    if (needInsert) {
      newInpt.inputItems.push(obj)
    }

    setModelForInsert({ ...newInpt })

    let needVisualIset = true
    for (let index = 0; index < inputArrayVisula.length; index++) {
      let element = inputArrayVisula[index];
      if (element.id === id) {
        inputArrayVisula[index].value = value
        needVisualIset = false
      }
    }

    if (needVisualIset) {
      inputArrayVisula.push(obj)
    }

    setInputArray([...inputArrayVisula])
  }

  const deleteItem = (id) => {
    let arrayAdd = [...inputArray]

    for (let index = 0; index < arrayAdd.length; index++) {
      let element = arrayAdd[index];

      if (index === id - 1) {
        arrayAdd.splice(index, 1)
      }

    }
    setInputArray(arrayAdd)
  }

  const showInputs = async (e) => {
    let session = JSON.parse(window.localStorage.getItem('user-exists'));

    let model = {
      clubID: session.lt,
      categorieID: e.value,
      reportID: modelForInsert.reportID
    }

    let resultReport = await api.GetReportsClub(model)


    if (resultReport.message.success) {
      toast.error('Lo sentimos ya existe un reporte creado para esta categoría')
    } else {
      if (dataFilterCategories.value !== e.value) {
        setInputArray([{
          id: 1, value: ""
        }, {
          id: 2, value: ""
        }, {
          id: 3, value: ""
        }, {
          id: 4, value: ""
        }])
      };

      setModelForInsert(prev => {
        prev.categorieID = e.value
        return prev
      })
      setShowData(true)
    }
  }


  const addReport = async () => {
    console.log('MODEL FOR INSERT ', modelForInsert)
    // VALIDAR MODELO
    let isValid = true
    if (modelForInsert.clubID <= 0 || modelForInsert.reportID <= 0 || modelForInsert.inputItems.length < inputArray.length) {
      isValid = false
    }
    
    if (isValid) {

      let result = await api.AddReports(modelForInsert)

      if (result.message.success) {
        toast.success('Reporte generado con exito')
        window.location.reload()
      }
    } else {
      toast.error('Para generar un reporte debe llenar todos los campos')
    }
  }

  return (
    <div className="content">
      <Card className="chart-card card">
        <CardBody>
          <Row className="p-3">
            <Col xs={5}>
              <h2>{contextReport.value === undefined ? 'Seleccione un reporte' : contextReport.value}</h2>
            </Col>
            <Col xs={4}>
              {
                showDataCategories &&
                <FormGroup>
                  <Select

                    onChange={(e) => showInputs(e)}
                    options={dataFilterCategories}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    placeholder="Seleccione una categoría"
                  />
                </FormGroup>
              }
            </Col>
          </Row>
          {
            showData &&
            <>
              <Row style={{ alignItems: 'flex-end', justifyContent: 'flex-end', marginRight: '3px', marginBottom: '23px' }}>
                <Button
                  style={{ borderRadius: "6px" }}
                  size='sm'
                  color="success"
                  onClick={() => addInputReport()}>
                  Agregar Input
                </Button>
                <Button
                  style={{ borderRadius: "6px" }}
                  color="success"
                  size='sm'
                  onClick={() => addReport()}>
                  Crear reporte
                </Button>
              </Row>

              <Row>
                <Col>
                  <Form>
                    <Row style={{ alignSelf: 'center' }}>
                      {
                        inputArray.map((task, index) => {
                          return (
                            <Col md={3} key={uuid()} className="reportImputStyle" >
                              <FormGroup className="d-flex align-item-center" >
                                <Input
                                  placeholder={task.value === "" ? `Aspecto del reporte ${index + 1}` : task.value}
                                  onBlur={(e) => inputsReport(index + 1, e.target.value)}
                                />
                                {
                                  index > 3 && <Button color="link" className="imputButtonStyle" onClick={() => deleteItem(index + 1)}>X</Button>
                                }

                              </FormGroup>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Form>
                </Col>
              </Row>
            </>

          }
        </CardBody>
      </Card>
    </div>
  )


}

export default SettingReports;