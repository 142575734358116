import Manager from '../api/main/EvaluacionServices';

const api = new Manager();

class SettingAPi {
    constructor() {
        this.response = {
            succes: true,
            error: '',
            data: []
        }
    }
    async AddComponente(model) {
        try {
            let result = await api.addComponente(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddFundamento(model) {
        try {
            let result = await api.addFundamento(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddItem(model) {
        try {
            let result = await api.addItems(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async AddPuntuacion(model) {
        try {
            let result = await api.addPuntuacion(model)
            //console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
    async AddEvaluacion(model) {
        try {
            let result = await api.addEvaluacion(model)
            //console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
    async GetEvaluacionByID(model) {
        try {
            let result = await api.GetEvaluacion(model)
            //console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
    async UpdateCompomonent(model) {
        try {
            let result = await api.UpdateCompomonent(model)
            //console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
    async UpdateFundamento(model) {
        try {
            let result = await api.UpdateFundamento(model)
            //console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
    
}


export default SettingAPi;