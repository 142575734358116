import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import Loader from "react-spinners/BeatLoader";
import {
    Button
} from "reactstrap";
const Autocomplete = ({ value, items, isLoading, disabled, onAdd = () => { }, onSelect = () => { }, handlerButtom = () => { }, onChange = () => { } }) => {
    const [display, setDisplay] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({})
    const [disabledInput, setDisabledInput] = React.useState(true)
    const [list, setList] = useState([]);

    const wrapperRef = useRef(null);

    useEffect(() => {
        setList(items)
    }, [items])

    useEffect(() => {
        if (isLoading === null) {
            setShowAdd(false)
            setLoading(false)
        } else {
            setLoading(isLoading)
        }
    }, [isLoading])

    useEffect(() => {
        if (value.hasOwnProperty("torneoName")) {
            setSearch(value.torneoName)
        }
    }, [value])

    useEffect(() => {
        console.log(disabled)
        if (!disabled) {
            setDisabledInput(false)
        }

    }, [disabled]);

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            setDisplay(false);
            // setOptions([])
            // setSearch('')
            // setShowAdd(false)
        }
    };

    const updatePokeDex = poke => {
        setDisplay(!display);
        setSearch(poke.torneoName)
        setSelected()
        onSelect(poke)
    };
    const escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    const getSuggestion = (value) => {
        console.log('START ', value)
        if (value === "") {
            setShowAdd(false)
            setOptions([])
            setDisplay(false)
            setSearch(value)
            return false
        }

        setSearch(value)

        // const escapedValue = escapeRegexCharacters(value.trim());
        // const regex = new RegExp('^' + escapedValue, 'i');
        let optionSuggestion = list.filter(user => user.torneoName.toLowerCase().indexOf(value.toLowerCase()) > -1);
        if (optionSuggestion.length > 0) {
            setOptions(optionSuggestion)
            setDisplay(!display)
        } else {
            setOptions([])
            setShowAdd(true)
            setDisplay(false)
            onChange(value.trim())
        }
    }

    return (
        <div ref={wrapperRef} className="flex-container flex-column pos-rel">
            <div style={{width: '100%', padding: 0}}>
                <input
                    disabled={disabledInput}
                    className={disabledInput ? "autocomplete-disabled" : "react-autocomplete-input"}   
                    id="auto"
                    placeholder="Torneo"
                    value={search}
                    onChange={event => getSuggestion(event.target.value)}
                />
                <div className='d-flex'>
                    {
                        showAdd ? <Button className='react-bottom' onClick={() => handlerButtom(search)} disabled={loading ? true : false}>
                            {
                                loading ?
                                    <div><Loader size={5} color={"#000"} /></div> : "Agregar"
                            }
                        </Button> : null
                    }

                </div>
            </div>

            {display && (
                <div className="autoContainer">
                    {options.map((value, i) => {
                        return (
                            <div
                                onClick={() => updatePokeDex(value)}
                                className="items"
                                key={i}
                                tabIndex="0"
                            >
                                <span>{value.torneoName}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Autocomplete;