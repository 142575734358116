import React, { useReducer, useState } from "react";
import GlobalContext from "../context";
import Reducer from '../reducer';
import asyncDispatch from "redux/middelware";
import Manager from '../../api/main/EvaluacionServices';
import Loading from "react-loading";
import moment from "moment";

const api = new Manager();

const GlobalProvider = ({ children }) => {
    const initialState = {
        isLoading: true,
        isFetching: true,
        component: [],
        filterCriterial: [],
        position: [],
        categoriaList: [],
        componentes: [],
        fundamentos: [],
        items: [],
        puntuacion: [],
        evaluaciones: [],
        currentEvaluacion: [],
        currentReport: {},
        /*  reportByClubID: [], */
        userList: [],
        currentFilterCritrial: '',
        userInfo: [],
        fisicaEvaluacionList: {
            dt1: [0, 0, 0],
            dt2: [0, 0, 0]
        },
        userEvaluaciones: [],
        prevEva: [],
        evaluacionPartido: [],
        clubCategori: [],
        weekRange: {},
        dateArray: [],
        tableFisicaList: {
            tableData: [],
            filterData: [],
            atletaList: []
        }
    }

    const [state, dispatch] = useReducer(Reducer, initialState);

    // FUNCION INIT
    const getInitialData = async (model) => {
        console.log('model redux', model)
        try {
            // CRITERIOS DE FILTRADO
            dispatch({ type: 'FETCHING_INITIAL_DATA' })


            // GET EVALUACIONES 

            let evaResult = await api.GetEvaluacionesByDate(model);
            //console.log('Eva Result ', evaResult.data.message)


            // INFORMACION DEL USUARIO
            let user = evaResult.data.message[0];

            // console.log('user', user);

            window.localStorage.setItem('ClubID', JSON.stringify({ clubID: user[0].clubID }))

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_USER_INFO',
                    payload: user
                })
            );

            ////////////////////////////////////////////////


            // CARGAR LISTADO DE FECHAS 
            let dateList = evaResult.data.message[4];
            let newArrayDate = []
            let dateObj = {
                id: 0,
                value: ''
            }

            for (let index = 0; index < dateList.length; index++) {
                let elem = dateList[index];
                dateObj.id = index
                dateObj.value = elem.date

                newArrayDate.push(dateObj)
                dateObj = {
                    id: 0,
                    value: ''
                }
            }

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_DATE_ARRAY',
                    payload: newArrayDate
                }))
            ///////////////////////////////////////////////////////////////////////////

            // PROCESAR EVLUACION TECNICAS
            let tecnicas = evaResult.data.message[1]

            let currentDayEva = ''
            let dt1 = []
            let beforeDayEva = ''
            let dt2 = []
            for (let index = 0; index < tecnicas.length; index++) {
                let task = tecnicas[index];
                if (currentDayEva === "") {
                    currentDayEva = task.date
                }

                if (task.date !== currentDayEva) {
                    if (beforeDayEva === "") {
                        beforeDayEva = task.date
                    }
                }

                if (task.date === currentDayEva) {
                    dt1.push(task)
                }

                if (task.date === beforeDayEva) {
                    dt2.push(task)
                }

            }


            // INFORMACION DE LA PRIMERA EVALUACION

            // ORDEN LOGICO DEL LAS EVALUACIONES 
            /*
                CODIGO  - BASE DE DATOS
                ASPECT1    promCog
                ASPECT2    promDef
                ASPECT3    promOf
            */


            let modelNew = {
                dt1: [0, 0, 0],
                dt2: [0, 0, 0]
            }
            // CFUERZA
            let aspect1 = 0
            let aspect1Len = 0

            // VELOCIDAD
            let aspect2 = 0
            let aspect2Len = 0

            //RESISTENCIA 
            let aspect3 = 0
            let aspect3Len = 0


            let item1 = tecnicas.filter((task) => {
                if (task.date >= currentDayEva && task.date <= currentDayEva) {
                    return task
                }
            })

            let item2 = tecnicas.filter((task) => {
                if (task.date >= beforeDayEva && task.date <= beforeDayEva) {
                    return task
                }
            })

            let userForProcess = []
            item1.map((task) => {
                if (userForProcess.indexOf(task.sessionID) === -1) {
                    aspect1 += parseInt(task.promCog)
                    aspect1Len++
                    userForProcess.push(task.sessionID)
                }
            })
            modelNew.dt1[0] = Math.round(aspect1 / aspect1Len)

            userForProcess = []
            item1.map((task) => {
                if (userForProcess.indexOf(task.sessionID) === -1) {
                    aspect2 += parseInt(task.promDef)
                    aspect2Len++
                    userForProcess.push(task.sessionID)
                }
            })
            modelNew.dt1[1] = (Math.round(aspect2 / aspect2Len))

            userForProcess = []
            item1.map((task) => {
                if (userForProcess.indexOf(task.sessionID) === -1) {
                    aspect3 += parseInt(task.promOf)
                    aspect3Len++
                    userForProcess.push(task.sessionID)
                }
            })
            modelNew.dt1[2] = (Math.round(aspect3 / aspect3Len))

            //console.log('Model New ', modelNew);

            userForProcess = []
            aspect1 = 0
            aspect1Len = 0
            aspect2 = 0
            aspect2Len = 0
            aspect3 = 0
            aspect3Len = 0

            item2.map((task) => {
                if (userForProcess.indexOf(task.sessionID) === -1) {
                    aspect1 += parseInt(task.promCog)
                    aspect1Len++
                    userForProcess.push(task.sessionID)
                }
            })
            modelNew.dt2[0] = Math.round(aspect1 / aspect1Len)

            userForProcess = []
            item2.map((task) => {
                if (userForProcess.indexOf(task.sessionID) === -1) {
                    aspect2 += parseInt(task.promOf)
                    aspect2Len++
                    userForProcess.push(task.sessionID)
                }
            })
            modelNew.dt2[1] = (Math.round(aspect2 / aspect2Len))

            userForProcess = []
            item2.map((task) => {
                if (userForProcess.indexOf(task.sessionID) === -1) {
                    aspect3 += parseInt(task.promDef)
                    aspect3Len++
                    userForProcess.push(task.sessionID)
                }
            })
            modelNew.dt2[2] = (Math.round(aspect3 / aspect3Len))

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_FISICAL_TEST',
                    payload: modelNew
                }))


            let userInfo = tecnicas
            let prevEva = evaResult.data.message[5]
            //console.log('CURRENT USER EVALUACION ', tecnicas)

            // OBTENEMOS LA MEDIOA DE LA ULTIMA EVALAUCION
            let mediaModel = {
                cop1: {
                    fundamento1: {
                        item: []
                    },
                    fundamento2: {
                        item: []
                    }
                },
                cop2: {
                    fundamento1: {
                        item: []
                    },
                    fundamento2: {
                        item: []
                    }
                },
                cop3: {
                    fundamento1: {
                        item: []
                    },
                    fundamento2: {
                        item: []
                    }
                }
            }

            let component = []
            let fundamento1 = []
            let fundamento2 = []
            let itemMedia1 = []
            let itemMedia2 = []
            let component2 = []
            let component3 = []
            let process = []
            let divider = []
            let t_array = []
            let objM = {
                item: 0,
                media: 0
            }

            component = tecnicas.filter((task) => {
                if (parseInt(task.tecnicaTypeID) === 1) {
                    return task
                }
            });

            fundamento1 = component.filter((task) => {
                if (parseInt(task.field) === 1) {
                    return task
                }
            })

            fundamento2 = component.filter((task) => {
                if (parseInt(task.field) === 2) {
                    return task
                }
            })


            itemMedia1 = fundamento1.reduce((acumulador, valorActual) => {
                const elementoYaExiste = acumulador.find(elemento => elemento.option === valorActual.option);
                if (elementoYaExiste) {
                    return acumulador.map((elemento, i) => {
                        //console.log('ACUMULADOR ', elemento)
                        if (elemento.option === valorActual.option) {

                            if (divider.length <= 0) {
                                divider.push({
                                    item: elemento.option,
                                    total: 1
                                })
                                process.push(elemento.option)
                            } else {
                                if (process.indexOf(elemento.option) === -1) {
                                    divider.push({
                                        item: elemento.option,
                                        total: 1
                                    })
                                    process.push(elemento.option)
                                } else {
                                    divider.filter((task, id) => {
                                        if (task.item === elemento.option) {
                                            task.total += 1
                                        }
                                    })
                                }
                            }
                            if (elemento.media === undefined) {
                                elemento.media = parseInt(valorActual.percent)

                            } else {
                                elemento.media = elemento.media + parseInt(valorActual.percent)

                            }
                            return elemento
                        }

                        return elemento;
                    });
                }

                return [...acumulador, valorActual];
            }, []);

            //console.log('ITEM MEDIA 1 COMP 1', itemMedia1)
            for (let k = 0; k < itemMedia1.length; k++) {
                let elemt = itemMedia1[k];
                let mediaCal = [{
                    total: 0
                }]
                if (divider.length > 0) {
                    mediaCal = divider.filter(task => task.item === elemt.option)
                }


                objM.item = elemt.option

                if (elemt.media !== undefined) {
                    objM.media = Math.round(elemt.media / (mediaCal[0].total + 1))
                }
                t_array.push(objM)
                objM = {
                    item: 0,
                    media: 0
                }

            }

            mediaModel.cop1.fundamento1.item = t_array;

            t_array = []
            objM = {
                item: 0,
                media: 0
            }
            process = []
            divider = []
            itemMedia2 = fundamento2.reduce((acumulador, valorActual) => {
                const elementoYaExiste = acumulador.find(elemento => elemento.option === valorActual.option);
                if (elementoYaExiste) {
                    return acumulador.map((elemento, i) => {
                        //console.log('ACUMULADOR ', elemento)
                        if (elemento.option === valorActual.option) {
                            if (divider.length <= 0) {
                                divider.push({
                                    item: elemento.option,
                                    total: 1
                                })
                                process.push(elemento.option)
                            } else {
                                if (process.indexOf(elemento.option) === -1) {
                                    divider.push({
                                        item: elemento.option,
                                        total: 1
                                    })
                                    process.push(elemento.option)
                                } else {
                                    divider.filter((task, id) => {
                                        if (task.item === elemento.option) {
                                            task.total += 1
                                        }
                                    })
                                }
                            }
                            if (elemento.media === undefined) {
                                elemento.media = parseInt(valorActual.percent)

                            } else {
                                elemento.media = elemento.media + parseInt(valorActual.percent)

                            }
                            return elemento
                        }

                        return elemento;
                    });
                }

                return [...acumulador, valorActual];
            }, []);

            //console.log('ITEM MEDIA 2 COMP 1', itemMedia2)
            for (let k = 0; k < itemMedia2.length; k++) {
                let elemt = itemMedia2[k];
                let mediaCal = [{
                    total: 0
                }]
                if (divider.length > 0) {
                    mediaCal = divider.filter(task => task.item === elemt.option)
                }
                objM.item = elemt.option
                if (elemt.media !== undefined) {
                    objM.media = Math.round(elemt.media / (mediaCal[0].total + 1))
                }
                t_array.push(objM)
                objM = {
                    item: 0,
                    media: 0
                }
            }

            mediaModel.cop1.fundamento2.item = t_array;


            // CARGAMOS EL COMPONENTE 2
            fundamento1 = []
            fundamento2 = []
            itemMedia1 = []
            itemMedia2 = []
            t_array = []
            objM = {
                item: 0,
                media: 0
            }

            component2 = tecnicas.filter((task) => {
                if (parseInt(task.tecnicaTypeID) === 2) {
                    return task
                }
            });

            fundamento1 = component2.filter((task) => {
                if (parseInt(task.field) === 1) {
                    return task
                }
            })

            fundamento2 = component2.filter((task) => {
                if (parseInt(task.field) === 2) {
                    return task
                }
            })
            process = []
            divider = []
            itemMedia1 = fundamento1.reduce((acumulador, valorActual) => {
                const elementoYaExiste = acumulador.find(elemento => elemento.option === valorActual.option);
                if (elementoYaExiste) {
                    return acumulador.map((elemento, i) => {
                        //console.log('ACUMULADOR ', elemento)
                        if (elemento.option === valorActual.option) {
                            if (divider.length <= 0) {
                                divider.push({
                                    item: elemento.option,
                                    total: 1
                                })
                                process.push(elemento.option)
                            } else {
                                if (process.indexOf(elemento.option) === -1) {
                                    divider.push({
                                        item: elemento.option,
                                        total: 1
                                    })
                                    process.push(elemento.option)
                                } else {
                                    divider.filter((task, id) => {
                                        if (task.item === elemento.option) {
                                            task.total += 1
                                        }
                                    })
                                }
                            }
                            if (elemento.media === undefined) {
                                elemento.media = parseInt(valorActual.percent)

                            } else {
                                elemento.media = elemento.media + parseInt(valorActual.percent)

                            }
                            return elemento
                        }

                        return elemento;
                    });
                }

                return [...acumulador, valorActual];
            }, []);

            // console.log('ITEM MEDIA 1 COMP 2', itemMedia1)
            for (let k = 0; k < itemMedia1.length; k++) {
                let elemt = itemMedia1[k];
                let mediaCal = [{
                    total: 0
                }]
                if (divider.length > 0) {
                    mediaCal = divider.filter(task => task.item === elemt.option)
                }
                objM.item = elemt.option
                if (elemt.media !== undefined) {
                    objM.media = Math.round(elemt.media / (mediaCal[0].total + 1))
                }
                t_array.push(objM)
                objM = {
                    item: 0,
                    media: 0
                }

            }

            mediaModel.cop2.fundamento1.item = t_array

            t_array = []
            objM = {
                item: 0,
                media: 0
            }
            process = []
            divider = []
            itemMedia2 = fundamento2.reduce((acumulador, valorActual) => {
                const elementoYaExiste = acumulador.find(elemento => elemento.option === valorActual.option);
                if (elementoYaExiste) {
                    return acumulador.map((elemento, i) => {
                        //console.log('ACUMULADOR ', elemento)
                        if (elemento.option === valorActual.option) {
                            if (divider.length <= 0) {
                                divider.push({
                                    item: elemento.option,
                                    total: 1
                                })
                                process.push(elemento.option)
                            } else {
                                if (process.indexOf(elemento.option) === -1) {
                                    divider.push({
                                        item: elemento.option,
                                        total: 1
                                    })
                                    process.push(elemento.option)
                                } else {
                                    divider.filter((task, id) => {
                                        if (task.item === elemento.option) {
                                            task.total += 1
                                        }
                                    })
                                }
                            }
                            if (elemento.media === undefined) {
                                elemento.media = parseInt(valorActual.percent)

                            } else {
                                elemento.media = elemento.media + parseInt(valorActual.percent)

                            }
                            return elemento
                        }

                        return elemento;
                    });
                }

                return [...acumulador, valorActual];
            }, []);


            //console.log('ITEM MEDIA 2 COMP 2', itemMedia2)
            for (let k = 0; k < itemMedia2.length; k++) {
                let elemt = itemMedia2[k];
                let mediaCal = [{
                    total: 0
                }]
                if (divider.length > 0) {
                    mediaCal = divider.filter(task => task.item === elemt.option)
                }
                objM.item = elemt.option
                if (elemt.media !== undefined) {
                    objM.media = Math.round(elemt.media / (mediaCal[0].total + 1))
                }
                t_array.push(objM)
                objM = {
                    item: 0,
                    media: 0
                }
            }


            mediaModel.cop2.fundamento2.item = t_array

            // CARGAMOS EL COMPONENTE 3
            fundamento1 = []
            fundamento2 = []
            itemMedia1 = []
            itemMedia2 = []
            t_array = []
            objM = {
                item: 0,
                media: 0
            }

            component3 = tecnicas.filter((task) => {
                if (parseInt(task.tecnicaTypeID) === 3) {
                    return task
                }
            });

            fundamento1 = component3.filter((task) => {
                if (parseInt(task.field) === 1) {
                    return task
                }
            })

            fundamento2 = component3.filter((task) => {
                if (parseInt(task.field) === 2) {
                    return task
                }
            })
            process = []
            divider = []
            itemMedia1 = fundamento1.reduce((acumulador, valorActual) => {
                const elementoYaExiste = acumulador.find(elemento => elemento.option === valorActual.option);
                if (elementoYaExiste) {
                    return acumulador.map((elemento, i) => {
                        //console.log('ACUMULADOR ', elemento)
                        if (elemento.option === valorActual.option) {
                            if (divider.length <= 0) {
                                divider.push({
                                    item: elemento.option,
                                    total: 1
                                })
                                process.push(elemento.option)
                            } else {
                                if (process.indexOf(elemento.option) === -1) {
                                    divider.push({
                                        item: elemento.option,
                                        total: 1
                                    })
                                    process.push(elemento.option)
                                } else {
                                    divider.filter((task, id) => {
                                        if (task.item === elemento.option) {
                                            task.total += 1
                                        }
                                    })
                                }
                            }
                            if (elemento.media === undefined) {
                                elemento.media = parseInt(valorActual.percent)

                            } else {
                                elemento.media = elemento.media + parseInt(valorActual.percent)

                            }
                            return elemento
                        }

                        return elemento;
                    });
                }

                return [...acumulador, valorActual];
            }, []);

            //console.log('ITEM MEDIA 1 COMP 3', itemMedia1)
            for (let k = 0; k < itemMedia1.length; k++) {
                let elemt = itemMedia1[k];
                let mediaCal = [{
                    total: 0
                }]
                if (divider.length > 0) {
                    mediaCal = divider.filter(task => task.item === elemt.option)
                }
                objM.item = elemt.option
                if (elemt.media !== undefined) {
                    objM.media = Math.round(elemt.media / (mediaCal[0].total + 1))
                }
                t_array.push(objM)
                objM = {
                    item: 0,
                    media: 0
                }

            }
            mediaModel.cop3.fundamento1.item = t_array

            t_array = []
            objM = {
                item: 0,
                media: 0
            }
            process = []
            divider = []
            itemMedia2 = fundamento2.reduce((acumulador, valorActual) => {
                const elementoYaExiste = acumulador.find(elemento => elemento.option === valorActual.option);
                if (elementoYaExiste) {
                    return acumulador.map((elemento, i) => {
                        //console.log('ACUMULADOR ', elemento)
                        if (elemento.option === valorActual.option) {
                            if (divider.length <= 0) {
                                divider.push({
                                    item: elemento.option,
                                    total: 1
                                })
                                process.push(elemento.option)
                            } else {
                                if (process.indexOf(elemento.option) === -1) {
                                    divider.push({
                                        item: elemento.option,
                                        total: 1
                                    })
                                    process.push(elemento.option)
                                } else {
                                    divider.filter((task, id) => {
                                        if (task.item === elemento.option) {
                                            task.total += 1
                                        }
                                    })
                                }
                            }
                            if (elemento.media === undefined) {
                                elemento.media = parseInt(valorActual.percent)

                            } else {
                                elemento.media = elemento.media + parseInt(valorActual.percent)

                            }
                            return elemento
                        }

                        return elemento;
                    });
                }

                return [...acumulador, valorActual];
            }, []);

            //console.log('ITEM MEDIA 2 COMP 3', itemMedia2)
            for (let k = 0; k < itemMedia2.length; k++) {
                let elemt = itemMedia2[k];
                let mediaCal = [{
                    total: 0
                }]
                if (divider.length > 0) {
                    mediaCal = divider.filter(task => task.item === elemt.option)
                }
                objM.item = elemt.option
                if (elemt.media !== undefined) {
                    objM.media = Math.round(elemt.media / (mediaCal[0].total + 1))
                }
                t_array.push(objM)
                objM = {
                    item: 0,
                    media: 0
                }
            }

            mediaModel.cop3.fundamento2.item = t_array

            let precComp1 = prevEva.filter((task) => {
                if (parseInt(task.component) === 1) {
                    return task
                }
            })
            let precComp2 = prevEva.filter((task) => {
                if (parseInt(task.component) === 2) {
                    return task
                }
            })
            let precComp3 = prevEva.filter((task) => {
                if (parseInt(task.component) === 3) {
                    return task
                }
            })


            let prevComp1Fun1 = precComp1.filter((task) => {
                if (parseInt(task.fundamento) === 1) {
                    return task
                }
            })

            let prevComp1Fun2 = precComp1.filter((task) => {
                if (parseInt(task.fundamento) === 2) {
                    return task
                }
            })

            let prevComp2Fun1 = precComp2.filter((task) => {
                if (parseInt(task.fundamento) === 1) {
                    return task
                }
            })

            let prevComp2Fun2 = precComp2.filter((task) => {
                if (parseInt(task.fundamento) === 2) {
                    return task
                }
            })

            let prevComp3Fun1 = precComp3.filter((task) => {
                if (parseInt(task.fundamento) === 1) {
                    return task
                }
            })

            let prevComp3Fun2 = precComp3.filter((task) => {
                if (parseInt(task.fundamento) === 2) {
                    return task
                }
            })

            ///console.log('FUNFAMEND ', precComp1)
            for (let index = 0; index < userInfo.length; index++) {
                let elm = userInfo[index];

                if (elm.tecnicaTypeID === 1) {

                    //FUNDAMENTO 1
                    if (parseInt(elm.field) === 1) {
                        mediaModel.cop1.fundamento1.item.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['media'] = task.media
                            }
                        })

                        prevComp1Fun1.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['diff'] = Math.round(parseInt(elm.percent) - parseInt(task.percent)) === undefined ? 0 : Math.round(parseInt(elm.percent) - parseInt(task.percent))
                            }
                        })

                    }

                    // FUNDAMENTO 2
                    if (parseInt(elm.field) === 2) {
                        mediaModel.cop1.fundamento2.item.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['media'] = task.media
                            }
                        })
                        prevComp1Fun2.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['diff'] = Math.round(parseInt(elm.percent) - parseInt(task.percent)) === undefined ? 0 : Math.round(parseInt(elm.percent) - parseInt(task.percent))
                            }
                        })


                    }
                }

                if (elm.tecnicaTypeID === 2) {

                    //FUNDAMENTO 1
                    if (parseInt(elm.field) === 1) {
                        mediaModel.cop2.fundamento1.item.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['media'] = task.media
                            }
                        })
                        prevComp2Fun1.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['diff'] = Math.round(parseInt(elm.percent) - parseInt(task.percent)) === undefined ? 0 : Math.round(parseInt(elm.percent) - parseInt(task.percent))
                            }
                        })
                    }

                    // FUNDAMENTO 2
                    if (parseInt(elm.field) === 2) {
                        mediaModel.cop2.fundamento2.item.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['media'] = task.media
                            }
                        })
                        prevComp2Fun2.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['diff'] = Math.round(parseInt(elm.percent) - parseInt(task.percent)) === undefined ? 0 : Math.round(parseInt(elm.percent) - parseInt(task.percent))
                            }
                        })
                    }
                }

                if (elm.tecnicaTypeID === 3) {

                    //FUNDAMENTO 1
                    if (parseInt(elm.field) === 1) {
                        mediaModel.cop3.fundamento1.item.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['media'] = task.media
                            }
                        })
                        prevComp3Fun1.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['diff'] = Math.round(parseInt(elm.percent) - parseInt(task.percent)) === undefined ? 0 : Math.round(parseInt(elm.percent) - parseInt(task.percent))
                            }
                        })
                    }

                    // FUNDAMENTO 2
                    if (parseInt(elm.field) === 2) {
                        mediaModel.cop3.fundamento2.item.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['media'] = task.media
                            }
                        })
                        prevComp3Fun2.filter((task) => {
                            if (task.item === elm.option) {
                                userInfo[index]['diff'] = Math.round(parseInt(elm.percent) - parseInt(task.percent)) === undefined ? 0 : Math.round(parseInt(elm.percent) - parseInt(task.percent))
                            }
                        })
                    }

                }
            }

            //console.log('user info ', userInfo)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_TECNICA_INFO',
                    payload: userInfo
                })
            )

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_TECNICA_INFO_PREV_EVA',
                    payload: prevEva
                })
            )


            //CLUB CATEGORIE
            let clubCategori = evaResult.data.message[2];

            let userExists = JSON.parse(window.localStorage.getItem('user-exists'))

            let filterCatList = []
            if (userExists.data.isSuper || userExists.data.isClub) {
                filterCatList = clubCategori
            } else {
                filterCatList = clubCategori.filter((task) => {
                    let cat = task.categoriesID
                    return userExists.data.categoryID.filter((l) => {
                        if (cat === l.categoriesID) {
                            return task
                        }
                    })
                });
            }
            console.log('FILTER CATEGORIE ', filterCatList)
            window.localStorage.setItem('clubCategoriesList', JSON.stringify(filterCatList))

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_CLUB_CATEGORI_LIST',
                    payload: filterCatList
                }))


            let result = await api.GetFilterCriterial();
            // console.log('STATE RESULT ', result.data)
            if (result.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_FILTER_CRITERIAL',
                        payload: result.data
                    }))
            }


            // POSICION
            console.log({ evaResult })
            let positionSport = parseInt(evaResult.data.message[0][0].sportID)

            window.localStorage.setItem('sportID', positionSport);

            let modelSport = {
                sportID: positionSport
            }

            console.log({ modelSport })
            let resultPosition = await api.GetPosition(modelSport);
            //console.log('position', resultPosition)

            if (resultPosition.length > 0) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_POSITION',
                        payload: resultPosition
                    }))
            }



            // CATEGORIA 
            let resultCategoria = await api.GetCategoria();
            //console.log('CATEGORY LIST ', resultCategoria)
            if (resultCategoria.message.length > 0) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_CATEGORIA',
                        payload: resultCategoria.message
                    }))
            }


            // WEEK RANGE 
            let dateRange = evaResult.data.message[3];

            let obj = {
                startDate: dateRange[0].startWeekRange,
                endDate: dateRange[0].endWeekRange
            }


            //console.log('Week ', obj)
            dispatch({
                type: 'FETCHING_GET_WEEK_RANGE',
                payload: obj
            })


            // CURRENT EVALUACION (MONGO DATA)
            // EVALUACION

            let modelEva = {
                "clubID": model.sessionID,
                "evaluacionType": 'Técnica'
            }

            let resultEvaluacion = await api.GetEvaluacion(modelEva)

            if (resultEvaluacion.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_EVALUACION',
                        payload: resultEvaluacion.data.message
                    })
                )

            }

            // Get evaluacion partido
            let resultEvaluacionPartido = await api.GetEvaPartido();

            if (resultEvaluacionPartido.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_EVALUACION_PARTIDO',
                        payload: resultEvaluacionPartido.data.message
                    })
                )
            }

            // END LOADING...
            asyncDispatch(dispatch({ type: "END_LOADING" }))


        } catch (error) {
            dispatch({
                type: 'FETCHING_INITIAL_DATA_FAILURE',
                payload: error
            })

            return error
        }
    }

    const GetUserByCategorie = async (model) => {
        console.log('Filter Data ', model)
        dispatch({ type: 'FETCHING_INITIAL_DATA' })
        let club = {
            clubID: model.clubID,
            categoryID: model.categoryID
        }

        let resultUserByClubID = await api.GetUserByClubSession(club);
        //   console.log('RESULT STATE ',resultUserByClubID.message[0])

        asyncDispatch(
            dispatch({
                type: 'FETCHING_GET_USER_BYID',
                payload: resultUserByClubID.message[0]
            }))

        return resultUserByClubID.message[0]

    }

    const updateContextElemntByEvaluationType = async (model) => {
        // console.log('MODEL ', model)
        try {
            // COMPONENTES
            dispatch({ type: 'FETCHING_INITIAL_DATA' })

            let resultComponente = await api.GetComponente(model);
            //console.log('RESULT COMPONET ', resultComponente)
            if (resultComponente.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_COMPONENT',
                        payload: resultComponente.data.message
                    }))
            }

            // FUNDAMENTOS
            let resultFundamentos = await api.GetFundamento(model);
            // console.log('RESULT FUNDAMENTOS ', resultFundamentos)
            if (resultFundamentos.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_FUNDAMENTO',
                        payload: resultFundamentos.data.message
                    }))
            }

            // ITEMS
            let resultItem = await api.GetItem(model);
            // console.log('RESULT ITEM ', resultItem)
            if (resultItem.success) {
                let items = resultItem.data.message

                //console.log('ITEMS ', items.length)

                let obj = {}
                let array = {
                    evaluacionType: resultItem.data.message[0].evaluacionType,
                    item: []
                }
                for (let index = 0; index < items.length; index++) {
                    let element = items[index];
                    obj = {
                        id: parseInt(Math.random(100 * 5).toString().replace('.', 0)),
                        _id: element._id,
                        value: element.item.label,
                        definition: element.item.definition,
                        puntuacion: element.item.puntuacion.substring(0, 1)
                    }
                    array.item.push(obj)
                    obj = {}
                }
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_ITEM',
                        payload: array
                    }))
            }

            // PUNTUACION
            let resultPuntuacion = await api.GePuntuacion(model);

            if (resultPuntuacion.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_PUNTUACION',
                        payload: resultPuntuacion.data
                    }))
            }

            // EVALUACION
            let resultEvaluacion = await api.GetEvaluacion(model)
            //console.log('REsult Evaluacion ', resultEvaluacion)

            if (resultEvaluacion.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_EVALUACION',
                        payload: resultEvaluacion.data.message
                    })
                )

            }

            // CATEGORIA 
            let catSess = {
                sessionID: model.clubID
            }
            let resultCategoria = await api.GetCategoriaBySessionID(catSess);
            //console.log('CATEGORIA ', resultCategoria)
            if (resultCategoria.message.length > 0) {
                let userExists = JSON.parse(window.localStorage.getItem('user-exists'))
                let clubCategori = resultCategoria.message[0]
                let filterCatList = []
                if (userExists.data.isSuper || userExists.data.isClub) {
                    filterCatList = clubCategori
                } else {
                    filterCatList = clubCategori.filter((task) => {
                        if (task.categorieID === userExists.data.categoryID) {
                            return task
                        }
                    })
                }

                console.log('FILTER CATEGORIE LISTS UPDATE CONTEXT ', filterCatList)
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_CATEGORIA',
                        payload: filterCatList
                    }))
            }

            // POSICION
            let sportID = window.localStorage.getItem('sportID')
            let modelSport = {
                sportID: sportID
            }

            //console.log('SPORT ID ', modelSport)

            let resultPosition = await api.GetPosition(modelSport);

            console.log('POSITION RESULT ', resultPosition)

            if (resultPosition.length > 0) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_POSITION',
                        payload: resultPosition
                    }))
            }


            return resultItem.data.message

        } catch (error) {
            dispatch({
                type: 'FETCHING_INITIAL_DATA_FAILURE',
                payload: error
            })

            return error
        }
    }

    const getCurrentEvaluacion = async (model, type) => {
        // EVALUACION
        //console.log('type', type)
        dispatch({ type: 'FETCHING_INITIAL_DATA' })

        if (type) {
            //console.log('REsult Evaluacion ', model)
            let resultEvaluacion = await api.GetEvaluacion(model)
            //  console.log('REsult Evaluacion ', resultEvaluacion)
            if (resultEvaluacion.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_EVALUACION_CURRENT',
                        payload: resultEvaluacion.data.message
                    })
                )
            }

            // PUNTUACION
            let resultPuntuacion = await api.GePuntuacion(model);
            if (resultPuntuacion.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_PUNTUACION',
                        payload: resultPuntuacion.data
                    }))
            }

            return resultEvaluacion.data.message
        } else {

            let evaPartidoResult = await api.GetEvaPartido();

            if (evaPartidoResult.success) {
                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_EVALUACION_CURRENT',
                        payload: evaPartidoResult.data.message
                    })
                )
            }
        }



    }

    const addCurrentReport = async (model) => {

        dispatch({ type: 'FETCHING_INITIAL_DATA' })

        asyncDispatch(
            dispatch({
                type: "FETCHING_ADD_CURRENT_REPORT",
                payload: model
            }))

        //console.log('report', model)

        return { success: true }
    }


    const getFisicalData = async (model) => {
        dispatch({ type: 'FETCHING_INITIAL_DATA' })

        // TRAEMOS LAA DATA
        let result = await api.GetEvaluacionFisicasForClub(model)

        let tb = result.message.data.tbData
        let dt = result.message.data.tbDetails

        // OBTENEMOS LA CATAEGORIAA EN CONTEXTO
        let currentCategori = JSON.parse(window.localStorage.getItem('currentCategori'))

        // FILTRAMOS LA INFOR SEGUN L CTEGORIA SELECCIONDA
        let filterData = tb.filter((task) => {
            if (task.action[0].keyFilterFront === currentCategori.categoriesID) {
                return task
            }
        })

        // PROCEAMOS LOS MODELOS DEL REPORTE DESCARGABLE

        // // FILTRAAMOS LA LISTA DE ATLETAS POR CATEGORIA EN CONTEXTO
        // let atletaForProcess = atletList.filter((task) => {
        //     if (task.categoryID === currentCategori.categoriesID) {
        //         return task
        //     }
        // })

        // // CARGAMOS LA INFORMCION QUE NECESITA EL FRONT

        // let filterData = []
        // let obj = {
        //     nombre: "",
        //     position: "",
        //     edad: "",
        //     ovr: "",
        //     action: [
        //         {
        //             icon: "icon-zoom-split",
        //             classBtn: "mr-3 btn-icon btn-success btn-round",
        //             type: true,
        //             key: { extractor: '', date: '' }
        //         },
        //         {
        //             icon: "icon-cloud-download-93",
        //             classBtn: "btn-icon btn-success btn-round",
        //             type: false,
        //             key: { extractor: '', date: '' }
        //         },
        //     ],
        // }
        // let initDate = ''
        // // RECORREMOS LA LISTA DE ATLETA PARA OBTENER SU INFORMACION INCIAL
        // for (let index = 0; index < atletaForProcess.length; index++) {
        //     let elem = atletaForProcess[index];
        //     let sessionID = elem.sessionID

        //     // RECORREMOS LA LISTA DE EVALUAACION 
        //     for (let i = 0; i < evaluacionesList[0].length; i++) {
        //         let e = evaluacionesList[0][i];
        //         //console.log('E LIST ', e)
        //         // CARGAMOS LA FECHA INCIAL A MOSTRAR EN LA TABLA
        //         if (initDate === '') {
        //             initDate = e.date
        //         }

        //         if (e.sessionID === sessionID) {
        //             // FILTRAMOS POR EL ATLETAA EN CUROS
        //             obj.nombre = e.nombre
        //             obj.edad = e.edad
        //             obj.position = e.position
        //             obj.ovr = e.ovr
        //             obj.action[0].key.extractor = sessionID
        //             obj.action[0].key.date = initDate
        //             obj.action[1].key.extractor = sessionID
        //             obj.action[1].key.date = initDate
        //         }

        //     }

        //     filterData.push(obj)
        //     obj = {
        //         nombre: "",
        //         position: "",
        //         edad: "",
        //         ovr: "",
        //         action: [
        //             {
        //                 icon: "icon-zoom-split",
        //                 classBtn: "mr-3 btn-icon btn-success btn-round",
        //                 type: true,
        //                 key: '',
        //             },
        //             {
        //                 icon: "icon-cloud-download-93",
        //                 classBtn: "btn-icon btn-success btn-round",
        //                 type: false,
        //                 key: "",
        //             },
        //         ],
        //     }

        // }


        // ACTUAALIZAMOS EL CONTEXTO

        asyncDispatch(
            dispatch({
                type: "FETCHING_LOAD_EVALUCION_FISICA",
                payload: {
                    tableData: filterData,
                    filterData: dt,
                    dwData: []
                }
            }))

        endLoading()
    }

    const endLoading = () => {
        setTimeout(() => {
            asyncDispatch(dispatch({ type: 'END_LOADING' }));
        }, 1500);
    }


    return (
        <GlobalContext.Provider value={{
            state,
            getInitialData,
            updateContextElemntByEvaluationType,
            getCurrentEvaluacion,
            GetUserByCategorie,
            addCurrentReport,
            getFisicalData,
            endLoading
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;