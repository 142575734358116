import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
`;

const Input = styled.input`
    width: 90%;
    max-width: 100%;
    margin: 0;
    accent-color: #ce2d12;
`;

const Datalist = styled.datalist`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    writing-mode: vertical-lr;
    max-width: 100em;
    width: 37em;
`;

const Option = styled.option`
    padding: 0;
    color: #FFFFFF;
    writing-mode: lr;
`;

const Alert = styled.div`
    margin-top: 20px;
    padding: 10px;
    background-color: #ce2d1259;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    box-shadow: 1px 1px 1px #fff;
`;

// ESTILOS NUEVOS
const Content = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 100em;
width: ${props => props.width ? props.width : '100em'};
`
const Label = styled.label`
color: #FFF;
font-size: 12px
`
const Row = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: ${props => props.width ? props.width : '100em'};
`;

const Col = styled.div`
display: flex;
flex-direction: column;
align-items: ${props => props.ai ? props.ai : "center"};
justify-content: center;
width: ${props => props.calc ? props.calc : "100%"};
max-width: 100%;
`;


const Iris = styled.div`
position: relative;
display: flex;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
font-size: 12px;
font-family: Arial,sans-serif;
max-width: 100em;
margin-bottom: 3em;
align-items: center;
justify-content: center;
`
const IrisSlide = styled.div`
display: flex;
flex-direction: column;
position: absolute;
top: 0;
left: ${props => props.left ? props.left : "0"};
`
const IrisLine = styled.span`
     height: 4px;
    background-color: #dee4ec;
    border-radius: 4px;
    display: flex;
    max-width: 100em;
    width: 38em;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 29px;
`
const IrisLineActive = styled.span`
    height: 4px;
    background-color: ${props => props.active ? "#CE2D12" : "#dee4ec"} ;
    border-radius: 4px;
    display: flex;
    max-width: 100em;
    width: 38em;
    align-items: center;
    justify-content: center;
    margin-right: ${props => `${props.slide}px`}
`
const IrisLabel = styled.span`
font-size: 14px;
line-height: 1;
text-shadow: none;
padding: 3px 9px;
background-color: #CE2D12;
color: white;
border-radius: 4px;
cursor: default;
margin-top: -12px;
margin-bottom: 11px;
 margin-left: -7px;
`
const Clip = styled.span`
    position: absolute;
    display: block;
    bottom: 29px;
    left: 46.5%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #CE2D12;
`
const GripClip = styled.div`
display: flex;
width: 50em;
align-items: center;
justify-content: space-between;
`
const GripPol = styled.span`
width: 2px;
height: 4px;
background: #d5d5d5;
`
const GripText = styled.span`
color:#fff
`
const GripThumb = styled.span`
width: 24px;
height: 24px;
border: 4px solid #CE2D12;
background-color: white;
border-radius: 24px;
box-shadow: 0 1px 3px rgb(0 0 255 / 30%);
`

const InputRange = ({ ratio }) => {
    const [percent, setPercent] = React.useState(0);
    const [slider, setSlider] = React.useState(0)
    const [thumbActive, setThumbActive] = React.useState(false);
    const [cr, setCR] = React.useState(0);
    const media = [1, 2, 3, 4, 5]
    React.useEffect(() => {
        if (ratio > 0) {
            let calc = parseInt(110.6 * (ratio / 4))
            let slider = 0

            slider = Math.ceil((555 - calc))

            //parseInt((571 / (ratio / 3.7)))

            setCR(ratio)
            setPercent(prev => {
                prev = calc
                return prev
            })
            setSlider(prev => {
                prev = slider
                return prev
            })
            setThumbActive(true)
        }
    }, [ratio])
    //console.count('RENDERS:')
    return (
        <Content width='50em'>
            <Row width='38em'>
                <Label>Resistencia</Label>
                <Label>Potencia</Label>
            </Row>
            <Iris>
                <IrisLine>
                    <IrisLineActive active={thumbActive} slide={slider}></IrisLineActive>
                </IrisLine>
                <IrisSlide left={`${percent}px`} id='thumb'>
                    <IrisLabel>
                        {`${cr}cr`}
                        <Clip></Clip>
                    </IrisLabel>
                    <GripThumb ></GripThumb>
                </IrisSlide>
            </Iris>
            <GripClip>
                <Col ai='center' calc='20%'>
                    <GripPol></GripPol>
                    <GripText>0</GripText>
                </Col>
                <Col ai='center' calc='20%'>
                    <GripPol></GripPol>
                    <GripText>5</GripText>
                </Col>
                <Col ai='center' calc='20%'>
                    <GripPol></GripPol>
                    <GripText>10</GripText>
                </Col>
                <Col ai='center' calc='20%'>
                    <GripPol></GripPol>
                    <GripText>15</GripText>
                </Col>
                <Col ai='center' calc='20%'>
                    <GripPol></GripPol>
                    <GripText>20</GripText>
                </Col>
            </GripClip>

            <Alert>
                Mediocampista ofensivo - Rango min: 7, max: 12
                Dentro de rango, enfoque en trabajar deficiencias
            </Alert>
        </Content>
        // <>
        //     <Wrapper>
        //         <Row width='90%'>
        //             <MyLabel>Resistencia</MyLabel>
        //             <MyLabel for="temp">Potencia</MyLabel>
        //         </Row>
        //         <Row>
        //             <Col>
        //                 <Input value={100} type="range" readOnly={true} />
        //                 <Datalist id="tickmarks">
        //                     <Option value="0" label="0"></Option>
        //                     <Option value="25" label="5"></Option>
        //                     <Option value="50" label="10"></Option>
        //                     <Option value="75" label="15"></Option>
        //                     <Option value="100" label="20"></Option>
        //                 </Datalist>
        //             </Col>
        //         </Row>
        //     </Wrapper>
        //     <Alert>
        //         Mediocampista ofensivo - Rango min: 7, max: 12
        //         Dentro de rango, enfoque en trabajar deficiencias
        //     </Alert>
        // </>

    )
}

export default InputRange