import React, { useContext, createContext, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import ReactLoading from 'react-loading';
import Manager from '../../source/auth/index'
import { Card, CardBody } from "reactstrap";

export default function AuthExample() {
    const api = new Manager();
    const [inValidRedirect, setInvalidRedirect] = React.useState(false);
    const [msg, setMsg] = React.useState('')
    React.useEffect(() => {
        let controll = new AbortController();
        (async () => {

            // AQUI ENVIAMOS VERIFCAR EL TOKEN 

            let checkAccess = await api.Auth()
            console.log('checkAccess auth', checkAccess)
            if (checkAccess.message.success) {
                let session = checkAccess.message.data[0].data.sItl

                //console.log('TOKEN ', session)
                window.localStorage.setItem('user-exists', JSON.stringify({ lt: session, data: checkAccess.message.data[0].data }))

                LoginPage();
            } else {
                setInvalidRedirect(true)
                setMsg(checkAccess.message.error.message)
            }
        })()
        return () => { controll.abort() }
    }, [])


    let history = useHistory();

    const LoginPage = () => {
        let { from } = { from: { pathname: "/admin/dashboard" } };
        history.replace(from);
    }

    if (inValidRedirect) {
        return (
            <div className="outer mx-auto">
                <div className="inner" style={{ borderRadius: '4px', borderLeft: "4px solid red" }}>
                    <h3>Redirección automática...</h3>
                    <p style={{
                        fontSize: '18px',
                        color: '#fff',
                        fontFamily: 'Exo',
                        height: '7px'
                    }}>Usted no tiene acceso a este módulo...</p>
                    <p
                        style={{
                            fontSize: '18px',
                            color: '#fff',
                            fontFamily: 'Exo',
                            height: '7px',
                            marginTop: '20px'
                        }}
                    >Error: {msg}</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="outer mx-auto">
                <div className="inner justify-content-center" style={{ width: '36%', height: '115px' }}>
                    <Card style={{ background: "transparent", boxShadow: "none" }}>
                        <CardBody>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '-26px' }}>
                                <div>
                                    <h3>Autenticación en proceso</h3>
                                </div>
                                <ReactLoading type='bubbles' color='#fff' width={80} />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>

        );
    }

}



