import React, { useMemo } from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Button, Col, Row } from "reactstrap";

import PropTypes from 'prop-types';
import BeatLoader from "react-spinners/BeatLoader";

const DragItem = styled.li`
  color: #fff !important;
  margin-left: 1rem !important;
  margin-bottom: 0.5rem !important;
`;

const ListItem = ({
    list,
    showBottom = false,
    onClick = () => { },
    onDelete = () => { },
    disabled = false,
    extraDetails = false,
    field,
    closeButton = false }) => {

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!disabled) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 1200);
        }
    }, [disabled])

    return (
        list.map((task, i) => {
            let item = task.item
            let labelForDelete = task.value === "Componentes" ? "Componente" : ""
            let collect = task.value === 'Componentes' ? 'component' : ""
            return (
                <li key={i} style={{ flexWrap: 'wrap', width: '245px' }}>
                    <Row className="li-sidebarSetting">
                        <p className="text-white pLabel">{task.value}</p>
                        {
                            showBottom && <Button
                                onClick={() => onClick(field)}
                                disabled={disabled}
                                id={`${task.value.substring(0, 5)}id`}
                                className="btn-icon btn-success btn-round" size='sm' style={{ marginLeft: '27px', marginTop: '-3px' }}>
                                <i className="tim-icons icon-simple-add"></i>
                            </Button>
                        }
                    </Row>
                    {
                        loading ?
                            <div className="row-center mb-2">
                                <BeatLoader color="#fff" />
                            </div>

                            : <Row className="mr-0">
                                <ul >
                                    {
                                        item.map((i, l) => {
                                            if (i.value != null) {
                                                return (
                                                    <Draggable key={i.id} draggableId={`${i.id}`} index={i.id} isDragDisabled={false}>
                                                        {(draggablePrivider) => (
                                                            <li
                                                                {...draggablePrivider.draggableProps}
                                                                ref={draggablePrivider.innerRef}
                                                                {...draggablePrivider.dragHandleProps}
                                                                className="text-white ml-3 mb-2 tooltipBoundary" >
                                                                <span >{i.value}</span>
                                                                {
                                                                    extraDetails && <>
                                                                        <span className="mr-2">{i.definition}</span>
                                                                        <span className="itemLabel">{i.puntuacion}</span>
                                                                    </>
                                                                }
                                                                {/* {
                                                        closeButton &&
                                                        <Button className="closeSidebar" color="danger"
                                                            onClick={() => onDelete(i._id, `Desea eliminar el ${labelForDelete} ${i.value}`, collect, i.value)}
                                                            outline size="sm">
                                                            <i className="tim-icons icon-trash-simple " />
                                                        </Button>
                                                    } */}

                                                            </li>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                        })
                                    }

                                </ul>
                            </Row>
                    }

                    <hr className="divider mt-0" />
                </li>
            )
        })
    )

};
ListItem.propTypes = {
    list: PropTypes.array.isRequired,
    showBottom: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    extraDetails: PropTypes.bool,
    field: PropTypes.number,
    closeButton: PropTypes.bool,
    onClose: PropTypes.func
}
export default ListItem;