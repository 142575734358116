import Manager from '../../api/auth/index'
const api = new Manager();

class Auth {
    constructor() {
        this.response = {
            sucess: true,
            error: '',
            data: {}
        }
    }

    async Auth(model) {
        try {
            let result = await api.CheckAccess(model)
            return result
        } catch (error) {
            return error
        }
    }
}

export default Auth;