import React from 'react';
import Popup from 'reactjs-popup';
import { Button, Col, Row } from 'reactstrap';
import { ClockLoader } from "react-spinners";

export default function PopupComponent({
    isOpen = 0,
    msg = '',
    onAction = () => { },
    onClose = () => { },
    actionButtonHide = true,
    sideBar = false,
    autoHide = true,
    labelSave = 'Guardar',
    showLoading = false,
    timeOut = 2200 }) {
    let [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
        if (autoHide) {
            if (isOpen) {
                setTimeout(() => {
                    onClose();
                }, timeOut);
            }
        }
    }, [isOpen])

    if (isOpen) {
        return (
            <div className={sideBar ? "tooltip  center-sidebar" : "right tooltip"} >
                <div className="tooltip-arrow" />
                <div className="tooltip-label">
                    <Row>
                        <div className='col-12 colCenter'>
                            <div style={{ padding: '7px' }}>
                                <span style={{ color: '#000', fontSize: '12px' }}>{msg}</span>
                            </div>
                            {
                                showLoading &&
                                <div className='spinner-container'>
                                    <ClockLoader color={"teal"} loading={loading} size={25} css={{
                                        display: 'flex',
                                        margin: ' 0 auto',
                                        borderColor: 'red'
                                    }} />
                                </div>


                            }
                            {
                                !actionButtonHide && <div className='mt-1'>
                                    <Button onClick={() => onClose()} color="danger" style={{ fontSize: '12px', padding: '6px' }}>
                                        Cancelar
                                    </Button>
                                    <Button onClick={() => onAction()} style={{ fontSize: '12px', padding: '6px' }}>
                                        {labelSave}
                                    </Button>
                                </div>
                            }

                        </div>

                    </Row>
                </div>
            </div>
        )
    } else {
        return (<div></div>)
    }

};
