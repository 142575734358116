import React, { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import uuid from "react-uuid";
// reactstrap components
import { Collapse, Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import {
  BackgroundColorContext,
  backgroundColors,
} from "contexts/BackgroundColorContext";
import toast from "react-hot-toast";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName;
  };

  const [open, setOpen] = useState({
    test_state: true
  });

  const handleOpen = (state) => {
    let currentState = open[state] ? true : false;
  // console.log('Open', open);
    setOpen({
      [state]: !currentState,
    });
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const { routes, rtlActive, logo } = props;

  let logoImg = null;
  let logoText = null;
  if (logo != undefined) {
    if (logo.outterLink != undefined) {
      logoImg = (
        <a
          // href={logo.outterLink}
          className="simple-text logo-mini"
          // target="_blank"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          // href={logo.outterLink}
          className="simple-text logo-normal"
          // target="_blank"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }


  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" data={color}>
          <div className="sidebar-wrapper" ref={sidebarRef}>
            {logoImg != null || logoText != null ? (
              <div className="logo">
                {logoImg}
                {logoText}
              </div>
            ) : null}
            <Nav>
              {routes.map((prop, key) => {
                if (prop.redirect || prop.hidden) return null;
                if (prop.collapse) {
                  return (
                    <li key={uuid()} className="navbar-collapse nav-link">
                      <ReactstrapNavLink
                        onClick={() => {
                          if (prop.name === 'Fisicas') {
                            toast.success('En fitwave seguimos trabajando para mejor, es por eso que esta funcion estara activa en las proximas versione');
                            return false
                          }
                          handleOpen(prop.state)

                        }}
                        className="nav-link center-icon"
                      >
                        {!prop.img ? (
                          <i className={prop.icon} />
                        ) : prop.typeImg ? (
                          <img
                            src={require("../../assets/Iconos/TenicasIcon.png")}
                            className="icon-img"
                          />
                        ) : (
                          <img
                            src={require("../../assets/Iconos/FisicasIcon.png")}
                            className="icon-img"
                          />
                        )}
                        <p className="mr--6">
                          {rtlActive ? prop.rtlName : prop.name}
                        </p>
                      </ReactstrapNavLink>
                      <Collapse isOpen={open[prop.state]}>
                        <div className="ml-3">
                          {prop.views.map((row) => (
                            <NavLink
                              key={uuid()}
                              to={row.layout + row.path}
                              style={{ opacity: activeRoute(row.layout + row.path) ? "1" : ".5" }}
                              className={activeRoute(row.layout + row.path) ? "active-sidebar nav-link" : "nav-link"}
                            >
                              <i className={row.icon} />
                              <p>{rtlActive ? row.rtlName : row.name}</p>
                            </NavLink>
                          ))}
                        </div>
                      </Collapse>
                      <hr className="divider" />
                    </li>
                  );
                }
                return (
                  <li key={uuid()}>
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      style={{ opacity: activeRoute(prop.layout + prop.path) ? "1" : ".5" }}
                      key={uuid()}
                    >
                      <i className={prop.icon} />
                      <p>{rtlActive ? prop.rtlName : prop.name}</p>
                    </NavLink>
                    <hr className="divider" />
                  </li>
                );
              })}
              <li className="active-pro">
                <ReactstrapNavLink
                  onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_DASHBOARD_URL !== undefined
                      ? process.env.REACT_APP_DASHBOARD_URL
                      : "")
                  }
                >
                  <i className="tim-icons icon-minimal-left" />
                  <p>Regresar a dashboard</p>
                </ReactstrapNavLink>
              </li>
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.defaultProps = {
  rtlActive: false,
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
