
import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import toast from 'react-hot-toast'
import routes from "routes.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Modal from "components/Modal";
import Manager from '../../source/Setting';
import PopupComponent from '../../components/alert'
import PreviewComponent from "components/preview";

// reactstrap components
import {
    Button,
    Alert,
    Label,
    FormGroup,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    NavLink as ReactstrapNavLink,
    Form,
    FormFeedback,
    ButtonGroup,
} from "reactstrap";
import Tabs from "../../components/Tab/Tabs";
import Select from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableItem from '../../components/DnD/DraggableList';
import GlobalContext from "redux/context";
import StarRating from "components/Raiting";
import { useStateCallback } from "hooks/useStateCallback";
import ManagerValidate from "../../api/handler/index";
import Overlay from "components/overlay";
import ManagerEvaluacionInUser from "../../source/main/index"


const apiSetting = new Manager();
const handlerValidation = new ManagerValidate();
const api = new ManagerEvaluacionInUser();

function Setting(props) {
    const unmounted = React.useRef(true);
    const userContext = React.useContext(GlobalContext);
    const { getInitialData, state, updateContextElemntByEvaluationType , endLoading} = userContext;
    const { filterCriterial, currentFilterCritrial, position, categoriaList, componentes, fundamentos, items, puntuacion, evaluaciones } = state;

    const [isOpen, setIsOpen] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);
    const [innerHeight, setInnerHeight] = React.useState('25%');
    const [innerWidth, setInnerWidth] = React.useState('25vw');
    const [field, setField] = React.useState('');
    const [fieldType, setFieldType] = React.useState(0);
    const mainPanelRef = React.useRef(null);
    const [positionList, setPositionList] = React.useState([])
    const [categriaItems, setCategoriaItems] = React.useState([])
    const [showPopup, setShowPopup] = React.useState(false);
    const [deleteID, setDeleteID] = React.useState(0);
    const [collection, setCollection] = React.useState(null);
    const [componentForDelete, setComponentForDelete] = React.useState(null)
    const [sidebarOpened, setsidebarOpened] = React.useState(
        document.documentElement.className.indexOf("nav-open") != -1
    );
    const [alertType, setAlertType] = React.useState(null);
    const [msg, setMsg] = React.useState(null)
    const [visible, setVisible] = React.useState(false);
    const [showFilter, setShowFilter] = React.useState(false);
    const [filter1, setFilter1] = React.useState([]);
    const [itemsCollection, setItemsCollection] = React.useState([])
    const [previewActive, setPreviewActive] = React.useState(false);
    const [previewModel, setPreviewModel] = React.useState([]);
    const [previewTitle, setPreviewTitle] = React.useState('Vista previa');
    const [clubID, setClubID] = React.useState("")
    const [disabledFilter, setDisabledFilter] = React.useState(false)

    // CONTROL FORM CONSTANT
    {/*PARA DESACTIVAR LOS COMPONENTES Y ACTIVAR EL GLOBAL*/ }
    const [global, setGlobal] = React.useState(false);
    const [showAspect2, setShowAspect2] = React.useState(false);
    const [showAspect3, setShowAspect3] = React.useState(false);


    // ELEMENTP DEL SIDEBAR ////
    //////////////////////////////////////////////////////////////////////////////////////

    {/*TIPO DE EVALUACION ELEMENT*/ }
    const [evaluationType, setEvaluationType] = React.useState([]);
    const [disabledItem, setDisabledItem] = React.useState(false);
    const [evaluarionInProgressModel, setEvaluationInProgressModel] = React.useState([]);

    {/*FILTER CRITERIAL ELEMENT*/ }
    const [criterialFilter, setCriterialFilter] = useStateCallback([]);
    const [filterSelected, setFilterSelected] = React.useState('');
    const [criterialAlert, setCriterialAlert] = React.useState(false);
    const [disableCriterial, setDisableCriterial] = React.useState(false);

    {/*MODODAL PUNTUACION NUMERIC*/ }
    const [rangeSelect, setRangeSelect] = React.useState(5)
    const [itemSelected, setItemSelected] = React.useState({ value: "4", label: "5" }) // VALOR UTILIZADO PARA SER DE DEFAULT
    const [details, setDetails] = React.useState("Valor nulo");
    const [rangeDetails, setRangeDetails] = React.useState([]);
    const [modelPuntuacion, setModelPuntuacion] = React.useState([])
    const [optionPuntuacion, setOptionPuntuacion] = React.useState([]);

    {/*MODODAL PUNTUACION BOLEAN*/ }
    const [modalPuntuacionBolean, setModalPuntuacionBolean] = React.useState({
        trueCriterial: '',
        falseCriterial: ''
    })

    {/*COMPONENTES */ }
    const [componentItem, setComponentItem] = useStateCallback([]);
    const [componentItem2, setComponentItem2] = useStateCallback([]);
    const [componentItem3, setComponentItem3] = useStateCallback([]);
    const [componentDetail, setComponentDetail] = React.useState('');
    const [componentDetail1, setComponentDetail1] = React.useState('');
    const [componentDetail2, setComponentDetail2] = React.useState('');

    // FUNDAMENTOS
    const [fundamentoDetils, setFundamentoDetails] = React.useState({
        fundamento1: '',
        fundamento2: ''
    })

    {/*ITEM*/ }
    const [itemTmp, setItemTmp] = React.useState({
        label: '',
        definition: '',
        puntuacion: ''
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////


    {/*FUNDAMENTO 1*/ }
    const [fundamento1, setFundamento1] = useStateCallback([]);
    const [item1, setItem1] = useStateCallback([]);
    const [fund1Alert, setFun1Alert] = React.useState(false)
    const [item1Alert, setIem1Alert] = React.useState(false)

    {/*FUNDAMENTO 2*/ }
    const [fundamento2, setFundamento2] = useStateCallback([]);
    const [item2, setItem2] = useStateCallback([]);
    const [fund2Alert, setFun2Alert] = React.useState(false)
    const [item2Alert, setIem2Alert] = React.useState(false)

    {/*FUNDAMENTO 3*/ }
    const [fundamento3, setFundamento3] = useStateCallback([]);
    const [item3, setItem3] = useStateCallback([]);
    const [fund3Alert, setFun3Alert] = React.useState(false)
    const [item3Alert, setIem3Alert] = React.useState(false)

    {/*FUNDAMENTO 4*/ }
    const [fundamento4, setFundamento4] = useStateCallback([]);
    const [item4, setItem4] = useStateCallback([]);
    const [fund4Alert, setFun4Alert] = React.useState(false)
    const [item4Alert, setIem4Alert] = React.useState(false)

    {/*FUNDAMENTO 5*/ }
    const [fundamento5, setFundamento5] = useStateCallback([]);
    const [item5, setItem5] = useStateCallback([]);
    const [fund5Alert, setFun5Alert] = React.useState(false)
    const [item5Alert, setIem5Alert] = React.useState(false)

    {/*FUNDAMENTO 6*/ }
    const [fundamento6, setFundamento6] = useStateCallback([]);
    const [item6, setItem6] = useStateCallback([]);
    const [fund6Alert, setFun6Alert] = React.useState(false)
    const [item6Alert, setIem6Alert] = React.useState(false)

    // LOAD INITIAL DATA
    React.useEffect(() => {
        if (unmounted.current) {
            (async () => {
                //let initialResult = await getInitialData();
                 console.log('Criterio de filtrado ', currentFilterCritrial)
                setFilterSelected(currentFilterCritrial)

                let userExists = JSON.parse(window.localStorage.getItem('user-exists'))

                setClubID(userExists.lt)
            })();
        }
        endLoading()
        return () => { unmounted.current = false }
    }, [])

    // useEffect de poscion
    React.useEffect(() => {
        // console.log('Posición ', position)
        if (currentFilterCritrial !== '' && position.length > 0) {
            if (currentFilterCritrial === 'Posición') {
                setPositionList(position)
                setFilterCriterialList({ label: currentFilterCritrial, data: position })
                setDisableCriterial(true)
            }
        } else {
            setPositionList(position)
        }
    }, [position])

    //useEffect Categoria
    React.useEffect(() => {
        console.log('CurrentFIlter ', currentFilterCritrial , 'Categoria ', categoriaList)
        if (currentFilterCritrial !== '' && categoriaList.length > 0) {
        console.log('CurrentFIlter ', currentFilterCritrial , 'Categoria ', categoriaList)
            if (currentFilterCritrial === 'Categoría') {
                setCategoriaItems(categoriaList)
                setFilterCriterialList({ label: currentFilterCritrial, data: categoriaList })
                setDisableCriterial(true)
            }

        } else {
            setCategoriaItems(categoriaList)
        }
    }, [categoriaList])

    //useEffect Componentes
    React.useEffect(() => {
        let array = {
            id: 3,
            value: 'Componentes',
            item: []
        }
        if (componentes.length > 0) {
            console.log('Componentes ', componentes)
            if (componentes[0].evaluacionType === evaluationType[0].value) {
                let obj = {
                    id: parseInt(Math.random(100 * 5).toString().replace('.', 0)),
                    _id: componentes[0]._id,
                    value: componentes[0].component.component
                }
                array.item.push(obj)

                obj = {
                    id: parseInt(Math.random(100 * 5).toString().replace('.', 0)),
                    _id: componentes[0]._id,
                    value: componentes[0].component.component1
                }
                array.item.push(obj)
                obj = {
                    id: parseInt(Math.random(100 * 5).toString().replace('.', 0)),
                    _id: componentes[0]._id,
                    value: componentes[0].component.component2
                }
                array.item.push(obj)
                setListItemComponentes([array])
            }
        }
    }, [componentes])

    React.useEffect(() => {
        let array = {
            id: 4,
            value: 'Fundamentos',
            item: []
        }
        if (fundamentos.length > 0) {
            console.log('Fundamentos ', fundamentos)
            if (fundamentos[0].evaluacionType === evaluationType[0].value) {
                let obj = {
                    id: parseInt(Math.random(100 * 5).toString().replace('.', 0)),
                    _id: fundamentos[0]._id,
                    value: fundamentos[0].fundamentos.fundamento1
                }
                array.item.push(obj)

                obj = {
                    id: parseInt(Math.random(100 * 5).toString().replace('.', 0)),
                    _id: fundamentos[0]._id,
                    value: fundamentos[0].fundamentos.fundamento2
                }
                array.item.push(obj)
                setListItemFundamentos([array])
            }
        }
    }, [fundamentos])

    // ITEMS EFFECT
    React.useEffect(() => {
        if (evaluationType.length > 0) {
            if (items.evaluacionType === evaluationType[0].value) {
                let array = {
                    id: 5,
                    value: 'Items',
                    item: items.item
                }
                setListItemItems([array])
            }
        }
    }, [items])

    // PUNTUACION EFFECT
    React.useEffect(() => {
        console.log('Puntuacion ', puntuacion)
        if (puntuacion?.message) {
            setModelPuntuacion(puntuacion.message)
        }
    }, [puntuacion])

    // // EVALUCIONES
    React.useEffect(() => {
        console.log('Evaluaciones ', evaluaciones)
        if (evaluationType.length > 0) {
            if (evaluaciones.length > 0) {
                setCurrentEvalucion(evaluaciones)
            }
        }
    }, [evaluaciones])

    // CRITERIO DE FILTRO 
    // React.useEffect(() => {
    //     if (currentFilterCritrial !== '') {
    //         console.log('CUrrent criterial ', currentFilterCritrial)
    //         setFilterCriterialList({ label: currentFilterCritrial })
    //         setDisableCriterial(true)
    //     }

    // }, [currentFilterCritrial])

    // MODELOS
    const [listItemEvaluiaciones, setListItemEvaluiaciones] = React.useState([
        {
            id: 1,
            value: 'Tipo de Evaluiaciones',
            item: [{
                id: 62787,
                _id: '62787ac33d3577c711209dc1',
                value: 'Técnica'
            }
                // {
                //     id: 62788,
                //     _id: '62787b123d3577c711209dc2',
                //     value: 'Médica'
                // }, {
                //     id: 62789,
                //     _id: '62787b3b3d3577c711209dc3',
                //     value: 'Sicológica'
                // }, {
                //     id: 62790,
                //     _id: '62787b4e3d3577c711209dc4',
                //     value: 'Movimiento Funcional'
                // }, {
                //     id: 62791,
                //     _id: '62787b663d3577c711209dc5',
                //     value: 'Competencia'
                // }, {
                //     id: 62792,
                //     _id: '62787b783d3577c711209dc6',
                //     value: 'Complementaria'
                // }
            ]
        }])

    const [listItemFiltrado, setListItemFiltrado] = useStateCallback([
        {
            id: 2,
            value: 'Criterios de Filtrado',
            item: []
        }])

    const [listItemComponentes, setListItemComponentes] = useStateCallback([
        {
            id: 3,
            value: 'Componentes',
            item: []
        }])

    const [listItemFundamentos, setListItemFundamentos] = useStateCallback([
        {
            id: 4,
            value: 'Fundamentos',
            item: []
        }])

    const [listItemItems, setListItemItems] = useStateCallback([
        {
            id: 5,
            value: 'Items',
            item: []
        }])

    const [currentEvaluacion, setCurrentEvalucion] = useStateCallback([]);


    let objs = {
        id: 0,
        value: ''
    }

    // FUNCION DE FORMULARIO

    // Salvar formulario
    const handlerSaveForm = async (action) => {
        switch (action) {
            case 1:
                // console.log('Start Form Save')
                // Modelo de insercion
                // Cada modelo genera una collection en MongoDb
                let formModel = {
                    clubID: clubID,
                    evaluationType: 0,
                    global: global,
                    filterSelected: filterSelected,
                    filterCriterial: [],
                    aspect: []
                }

                try {
                    let formValidationResult = await handlerValidationForm();
                    if (formValidationResult.isEmpty) {
                        formError({ type: 'warning', msg: formValidationResult.msg })
                        return false
                    }

                    // CARGAMOS EL TIPO DE EVALUACION
                    formModel.evaluationType = evaluationType[0].value

                    // CARGAMOS LOS FILTROS 
                    let obj = {
                        filterType: filterSelected === 'Posición' ? 'sport_positionID' : 'categoryID',
                        filterItem: criterialFilter.map((task) => {
                            return task.id
                        })
                    };

                    formModel.filterCriterial.push(obj);


                    // SE CARGAN LOS COMPONENTES
                    let objAspect = {
                        component: [],
                        fundamento1: [],
                        fundamento2: []
                    }

                    let objAspectFundamento = {
                        fundamento: [],
                        item: []
                    }


                    // CARGAMOS EL COMPONENTE 1
                    objAspect.component = componentItem

                    // VALIDAMOS SI ES GLOBAL


                    // CARGAMOS LOS FUNDAMENTOS 
                    // FUNDAMENTO 1
                    objAspectFundamento.fundamento = fundamento1
                    objAspectFundamento.item = item1
                    objAspect.fundamento1.push(objAspectFundamento);

                    // LIMPIAMOS LA VARIABLE DE FUNDAMENTO 
                    objAspectFundamento = {
                        fundamento: [],
                        item: []
                    }

                    //FUNDAMENTO 2
                    objAspectFundamento.fundamento = fundamento2
                    objAspectFundamento.item = item2

                    objAspect.fundamento2.push(objAspectFundamento);


                    // AGREGAMOS EL ASPECTO AL MODELO
                    formModel.aspect.push(objAspect)

                    // LIMPIAMOS LAS VARIABLES
                    objAspect = {
                        component: [],
                        fundamento1: [],
                        fundamento2: []
                    }
                    // LIMPIAMOS LA VARIABLE DE FUNDAMENTO 
                    objAspectFundamento = {
                        fundamento: [],
                        item: []
                    }

                    if (!showAspect2) {

                        // CARGAMOS EL ASPECTO 2

                        // CARGAMOS EL COMPONENTE 
                        objAspect.component = componentItem2

                        // CARGAMOS LOS FUNDAMENTOS 

                        // FUNDAMENTO 1
                        objAspectFundamento.fundamento = fundamento3
                        objAspectFundamento.item = item3
                        objAspect.fundamento1.push(objAspectFundamento);

                        // LIMPIAMOS LA VARIABLE DE FUNDAMENTO 
                        objAspectFundamento = {
                            fundamento: [],
                            item: []
                        }

                        //FUNDAMENTO 2
                        objAspectFundamento.fundamento = fundamento4
                        objAspectFundamento.item = item4

                        objAspect.fundamento2.push(objAspectFundamento);


                        // AGREGAMOS EL ASPECTO AL MODELO
                        formModel.aspect.push(objAspect)

                        // LIMPIAMOS LAS VARIABLES
                        objAspect = {
                            component: [],
                            fundamento1: [],
                            fundamento2: []
                        }
                        // LIMPIAMOS LA VARIABLE DE FUNDAMENTO 
                        objAspectFundamento = {
                            fundamento: [],
                            item: []
                        }

                    }

                    if (!showAspect3) {
                        // AGREGAMOS EL ASPECTO 3

                        // CARGAMOS EL COMPONENTE 
                        objAspect.component = componentItem3

                        // CARGAMOS LOS FUNDAMENTOS 

                        // FUNDAMENTO 1
                        objAspectFundamento.fundamento = fundamento5
                        objAspectFundamento.item = item5
                        objAspect.fundamento1.push(objAspectFundamento);

                        // LIMPIAMOS LA VARIABLE DE FUNDAMENTO 
                        objAspectFundamento = {
                            fundamento: [],
                            item: []
                        }

                        //FUNDAMENTO 2
                        objAspectFundamento.fundamento = fundamento6
                        objAspectFundamento.item = item6

                        objAspect.fundamento2.push(objAspectFundamento);


                        // AGREGAMOS EL ASPECTO AL MODELO
                        formModel.aspect.push(objAspect)

                    }



                    //console.log('Modelo para insertar ', formModel)

                    let inserEvaluacionResult = await apiSetting.AddEvaluacion(formModel)
                   console.log('Insert Evaluaciones Result ', inserEvaluacionResult)

                    if (inserEvaluacionResult.message.success) {
                        setCriterialFilter([])
                        setFilterSelected('')
                        setComponentItem([])
                        setFundamento1([])
                        setItem1([])
                        setFundamento2([])
                        setItem2([])
                        setComponentItem2([])
                        setFundamento3([])
                        setItem3([])
                        setFundamento4([])
                        setItem4([])
                        setComponentItem3([])
                        setFundamento5([])
                        setItem5([])
                        setFundamento6([])
                        setItem6([])
                        setDisabledItem(true)
                        setDisableCriterial(true)
                        toast.success('Se agrego correctamente')
                        //formError({ type: 'success', msg: 'Se agrego correctamente' })
                    }

                } catch (error) {
                    console.log('Error ', error)
                }

                break;
            case 0:
                if (previewActive) {
                    setPreviewTitle('Vista previa')
                    setPreviewActive(false)
                    setPreviewModel([])
                } else {
                    try {
                        let formValidationResult = await handlerValidationForm();
                        if (formValidationResult.isEmpty) {
                            formError({ type: 'warning', msg: formValidationResult.msg })
                            return false
                        } else {
                            setPreviewTitle('Regresar')
                        }
                        let modelResult = handlerCreateModel();
                        setPreviewModel(modelResult)
                        setPreviewActive(true)
                    } catch (error) {
                        formError({ tye: 'warning', msg: `Error ${error}` })
                    }
                }

                break;
            case 2:
                setModal2(rev => !rev)
                break;
            default:
                break;
        }

    }

    const handlerCreateModel = () => {
        let objContent = {
            label: "",
            item: []
        }
        let item = {
            id: 0,
            label: "",
            description: "",
            type: "",
            rating: 0
        }
        let aspecModel = {
            name: "",
            rangeDetails: modelPuntuacion,
            content: []
        }
        let previewModel = []
        let string = []
        let numeric = []
        let boolean = []

        // CARGAMOS EL PRIMER ASPECTO
        aspecModel.name = componentItem[0].value

        // CARGAMOS EL RIMER FUNDAMENO
        objContent.label = fundamento1[0].value

        // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO

        string = item1.filter(task => {
            if (task.puntuacion === 'S') {
                return task
            }
        })

        numeric = item1.filter(task => {
            if (task.puntuacion === 'N') {
                return task
            }
        })

        boolean = item1.filter(task => {
            if (task.puntuacion === 'B') {
                return task
            }
        })

        // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
        boolean.filter(task => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = task.puntuacion
            item.type = 'boolean'

            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5
            }
        })

        string.filter(task => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = task.puntuacion
            item.type = 'string'

            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5
            }
        })

        numeric.filter(task => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = rangeSelect
            item.type = 'numeric'

            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5
            }
        })

        aspecModel.content.push(objContent)

        objContent = {
            label: "",
            item: []
        }

        // CARGAMOS EL RIMER FUNDAMENO 2
        objContent.label = fundamento2[0].value

        // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
        string = item2.filter(task => {
            if (task.puntuacion === 'S') {
                return task
            }
        })
        numeric = item2.filter(task => {
            if (task.puntuacion === 'N') {
                return task
            }
        })
        boolean = item2.filter(task => {
            if (task.puntuacion === 'B') {
                return task
            }
        })

        // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
        boolean.filter(task => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = task.puntuacion
            item.type = 'boolean'

            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5
            }
        })

        string.filter(task => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = task.puntuacion
            item.type = 'string'

            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5
            }
        })

        numeric.filter(task => {
            item.id = task.id
            item.label = task.value
            item.description = task.definition
            item.rating = rangeSelect
            item.type = 'numeric'

            objContent.item.push(item)
            item = {
                id: 0,
                label: "",
                description: "",
                type: "",
                rating: 5
            }
        })

        aspecModel.content.push(objContent)

        objContent = {
            label: "",
            item: []
        }

        previewModel.push(aspecModel)

        //////////////////////////////////////////////////////////////////// 
        // LImpiamos el aspect model para agregar la siguientes
        aspecModel = {
            name: "",
            rangeDetails: modelPuntuacion,
            content: []
        }


        // SEGUNDO ASPECTO 
        if (!showAspect2) {


            aspecModel.name = componentItem2[0].value


            // CARGAMOS EL RIMER FUNDAMENO
            objContent.label = fundamento3[0].value

            // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
            string = item3.filter(task => {
                if (task.puntuacion === 'S') {
                    return task
                }
            })
            numeric = item3.filter(task => {
                if (task.puntuacion === 'N') {
                    return task
                }
            })
            boolean = item3.filter(task => {
                if (task.puntuacion === 'B') {
                    return task
                }
            })

            // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
            boolean.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'boolean'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            string.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'string'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            numeric.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = rangeSelect
                item.type = 'numeric'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            aspecModel.content.push(objContent)

            objContent = {
                label: "",
                item: []
            }

            // CARGAMOS EL RIMER FUNDAMENO
            objContent.label = fundamento4[0].value

            // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
            string = item4.filter(task => {
                if (task.puntuacion === 'S') {
                    return task
                }
            })
            numeric = item4.filter(task => {
                if (task.puntuacion === 'N') {
                    return task
                }
            })
            boolean = item4.filter(task => {
                if (task.puntuacion === 'B') {
                    return task
                }
            })

            // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
            boolean.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'boolean'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            string.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'string'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            numeric.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = rangeSelect
                item.type = 'numeric'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })
            aspecModel.content.push(objContent)

            objContent = {
                label: "",
                item: []
            }

            previewModel.push(aspecModel)

            /////////////////////////////////////////////////

            // LImpiamos el aspect model para agregar la siguientes
            aspecModel = {
                name: "",
                rangeDetails: modelPuntuacion,
                content: []
            }
        }

        // ASPECTO 3
        if (!showAspect3) {


            aspecModel.name = componentItem3[0].value


            // CARGAMOS EL RIMER FUNDAMENO
            objContent.label = fundamento5[0].value

            // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
            string = item5.filter(task => {
                if (task.puntuacion === 'S') {
                    return task
                }
            })
            numeric = item5.filter(task => {
                if (task.puntuacion === 'N') {
                    return task
                }
            })
            boolean = item5.filter(task => {
                if (task.puntuacion === 'B') {
                    return task
                }
            })

            // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
            boolean.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'boolean'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            string.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'string'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            numeric.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = rangeSelect
                item.type = 'numeric'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })
            aspecModel.content.push(objContent)

            objContent = {
                label: "",
                item: []
            }

            // CARGAMOS EL RIMER FUNDAMENO
            objContent.label = fundamento6[0].value

            // CARGAMOS LOS ITEMS DEL PRIMER FUNDAMENTO
            string = item6.filter(task => {
                if (task.puntuacion === 'S') {
                    return task
                }
            })
            numeric = item6.filter(task => {
                if (task.puntuacion === 'N') {
                    return task
                }
            })
            boolean = item6.filter(task => {
                if (task.puntuacion === 'B') {
                    return task
                }
            })

            // CARGAR EL ORDEN DE LAS PREGUNTAS SEGUN EL TIPO
            boolean.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'boolean'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            string.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = task.puntuacion
                item.type = 'string'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            numeric.filter(task => {
                item.id = task.id
                item.label = task.value
                item.description = task.definition
                item.rating = rangeSelect
                item.type = 'numeric'

                objContent.item.push(item)
                item = {
                    id: 0,
                    label: "",
                    description: "",
                    type: "",
                    rating: 5
                }
            })

            aspecModel.content.push(objContent)

            objContent = {
                label: "",
                item: []
            }
        }

        previewModel.push(aspecModel)

        console.log('PREVIW MODEL ', previewModel)
        return previewModel

    }

    const handlerValidationForm = () => {
        let response = {
            field: '',
            isEmpty: false,
            msg: ''
        }
        return new Promise((resolve) => {
            // SE VALIDA SI SE SELECCION EL TIPO DE EVALUACION
            if (evaluationType.length <= 0) {
                response.field = 1
                response.isEmpty = true
                response.msg = 'Debe seleccionar una evaluación'
                resolve(response)
                return
            }

            // SE VALIDA QUE SE SELECCIONE LOS CRITERIOS DE FILTRADOS
            if (criterialFilter.length <= 0) {
                response.field = 2
                response.isEmpty = true
                response.msg = 'Debe seleccionar los criterios de filtrados'
                resolve(response)
                return
            }

            // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
            if (componentItem.length <= 0) {
                response.field = 3
                response.isEmpty = true
                response.msg = 'El componente del Aspecto 1 no puede estar vacio'
                resolve(response)
                return
            }

            // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
            if (fundamento1.length <= 0) {
                response.field = 4
                response.isEmpty = true
                response.msg = 'El fundamento 1 del Aspecto 1 no puede estar vacio'
                resolve(response)
                return
            }

            // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
            if (item1.length <= 0) {
                response.field = 5
                response.isEmpty = true
                response.msg = 'El item del Aspecto 1 no puede estar vacio'
                resolve(response)
                return
            }

            // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
            if (fundamento2.length <= 0) {
                response.field = 6
                response.isEmpty = true
                response.msg = 'El fundamento 2 del Aspecto 1 no puede estar vacio'
                resolve(response)
                return
            }

            // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
            if (item2.length <= 0) {
                response.field = 7
                response.isEmpty = true
                response.msg = 'El item 2 del Aspecto 1 no puede estar vacio'
                resolve(response)
                return
            }

            if (!showAspect2) {
                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (componentItem2.length <= 0) {
                    response.field = 3
                    response.isEmpty = true
                    response.msg = 'El componente del Aspecto 2 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (fundamento3.length <= 0) {
                    response.field = 4
                    response.isEmpty = true
                    response.msg = 'El fundamento 1 del Aspecto 2 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (item3.length <= 0) {
                    response.field = 5
                    response.isEmpty = true
                    response.msg = 'El item 1 del Aspecto 2 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (fundamento4.length <= 0) {
                    response.field = 6
                    response.isEmpty = true
                    response.msg = 'El fundamento 2 del Aspecto 2 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (item4.length <= 0) {
                    response.field = 7
                    response.isEmpty = true
                    response.msg = 'El item 2 del Aspecto 2 no puede estar vacio'
                    resolve(response)
                    return
                }
            }


            if (!showAspect3) {
                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (componentItem3.length <= 0) {
                    response.field = 3
                    response.isEmpty = true
                    response.msg = 'El componente del Aspecto 3 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (fundamento5.length <= 0) {
                    response.field = 4
                    response.isEmpty = true
                    response.msg = 'El fundamento 1 del Aspecto 3 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (item5.length <= 0) {
                    response.field = 5
                    response.isEmpty = true
                    response.msg = 'El item 1 del Aspecto 3 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (fundamento6.length <= 0) {
                    response.field = 6
                    response.isEmpty = true
                    response.msg = 'El fundamento 2 del Aspecto 3 no puede estar vacio'
                    resolve(response)
                    return
                }

                // SE VALIDA QUE SE SELECCIONE EL COMPONENTE 1
                if (item6.length <= 0) {
                    response.field = 7
                    response.isEmpty = true
                    response.msg = 'El item 2 del Aspecto 3 no puede estar vacio'
                    resolve(response)
                    return
                }
            }


            resolve(response)
        })
    }

    // CLEAR FORMULARIO 
    const handlerClearForm = () => {
        window.location.reload();
    }

    /// FUNCION DE PUNTUACION NUMERICA
    const updateRangeSelect = (item) => {
        setRangeSelect(parseInt(item.label))
        setItemSelected(item)
    }
    const updateRangeDetails = (item, type) => {
        if (rangeDetails.length <= 0) {
            setRangeDetails([{ id: type, value: item.target.value }])
        } else {
            let newArray = rangeDetails
            let isUpdate = false
            newArray.map((task, index) => {
                if (task.id === type) {
                    task.value = item.target.value
                    isUpdate = true
                }
            })

            if (!isUpdate) {
                newArray.push({ id: type, value: item.target.value })
            }
            setRangeDetails(newArray)
        }
        //console.log('Update DEtails ', item)
    }
    const updateDetailLabel = (index) => {
        //console.log('Index ', index)
        let indexArray = index
        rangeDetails.filter((tas) => {
            if (tas.id === indexArray) {
                setDetails(tas.value)
            }
        })
    }

    const handlerSavePuntuacion = async (e) => {
        if (e === 1) {
            if (evaluationType.length > 0) {
                if (rangeDetails.length > 0) {
                    // VALIDAR SI EL ARRAY DE DETALLES ES IGUAL A LA CANTIDAD DE LA PUNTUACION
                    if (rangeDetails.length < rangeSelect) {
                        // SE DEBE UTILIZAR LOS VALORES POR DEFECTO EN LOS CAMPOS QUE NO TENGAN DESCRIPCION
                        //console.log(' RANGE DETAILS ', rangeSelect)
                    } else {
                        let model = {
                            clubID: clubID,
                            evaluacionType: evaluationType[0].value,
                            puntuacionType: 'numeric',
                            puntuacionRange: rangeSelect,
                            Definition: rangeDetails
                        }

                        let result = await apiSetting.AddPuntuacion(model)

                        if (result.success) {

                            let model = {
                                "clubID": clubID,
                                "evaluacionType": evaluationType[0].value
                            }

                            updateContextElemntByEvaluationType(model)

                            formError({ type: 'success', msg: 'Se agrego correctamente' })
                            setIsOpen(false)
                        }
                    }
                } else {
                    formError({ type: 'warning', msg: 'Error en el formulario, no puede salvar campos vacios..' })
                }
            } else {
                formError({ type: 'warning', msg: 'Debe seleccionar una evaluación para agregar puntuación.' })
            }
        } else {
            if (evaluationType.length > 0) {
                console.log('Puntuacion Boleana ', modalPuntuacionBolean)
                if (modalPuntuacionBolean.trueCriterial !== "" || modalPuntuacionBolean.trueCriterial !== '') {
                    let model = {
                        clubID: clubID,
                        evaluacionType: evaluationType[0].value,
                        puntuacionType: 'bolean',
                        puntuacionRange: 0,
                        Definition: modalPuntuacionBolean
                    }
                    let result = await apiSetting.AddPuntuacion(model)
                    console.log('RESULT INSER ', result)
                    if (result.success) {

                        let model = {
                            "clubID": clubID,
                            "evaluacionType": evaluationType[0].value
                        }

                        updateContextElemntByEvaluationType(model)

                        formError({ type: 'success', msg: 'Se agrego correctamente' })
                        setIsOpen(false)
                    } else {
                        formError({ type: 'warning', msg: 'Error no se pudo agregar este valor ...' })
                    }
                } else {
                    formError({ type: 'warning', msg: 'Error en el formulario, no puede salvar campos vacios..' })
                }

            } else {
                formError({ type: 'warning', msg: 'Debe seleccionar una evaluación para agregar puntuación.' })
            }
        }
    }
    ////////////////////////////////////////////////////////////////////

    // FUNCION DE PUNTIACION BOLEANA

    const updateValues = (e, text) => {
        if (e) {
            setModalPuntuacionBolean(prev => {
                prev.trueCriterial = text.target.value
                return prev
            })
        } else {
            setModalPuntuacionBolean(prev => {
                prev.falseCriterial = text.target.value
                return prev
            })
        }
    }

    // FUNCION DE ITEMS
    const addItem = () => {
        setItemsCollection(prev => [...prev, 1])
    }

    const handleOnChangeItem = (e, elem) => {
        switch (elem) {
            case 1:
                setItemTmp(prev => {
                    prev.label = e.target.value
                    return prev
                })
                break;
            case 2:
                setItemTmp(prev => {
                    prev.definition = e.target.value
                    return prev
                })
                break;
            case 3:
                setItemTmp(prev => {
                    prev.puntuacion = e.label
                    return prev
                })
                break;
            default:
                break;
        }
    }

    const handleSubmit = async (e) => {

        // VALIDAMOS QUE TODOS LOS FIELD TENGAN VALOR 
        let noError = itemTmp.label != "" && itemTmp.puntuacion != "" ? false : true;

        if (noError) {
            formError({ type: 'warning', msg: 'Ups...? debe llenar todos los campos' })
        } else {
            let model = {
                clubID: clubID,
                evaluacionType: evaluationType[0].value,
                item: {
                    label: itemTmp.label,
                    definition: itemTmp.definition,
                    puntuacion: itemTmp.puntuacion
                }
            }
            //Guardar en base de datos
            let result = await apiSetting.AddItem(model)

            if (result.success) {
                let randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                // AGREGAR AL ARBOL DEL SIDEBAR
                setListItemItems(prev => {
                    objs.id = randomID
                    objs._id = result.data.message.data._id
                    objs.value = result.data.message.data.item.label
                    objs.definition = result.data.message.data.item.definition
                    objs.puntuacion = result.data.message.data.item.puntuacion.substring(0, 1)

                    prev[0].item.push(objs)
                    objs = {
                        id: 0,
                        value: ''
                    }
                    return prev
                })
                formError({ type: 'success', msg: 'Se agrego correctamente' })
                setIsOpen(false)
            }
            // Cargar al visual 

        }
    }
    /////////////////////////////////////////////////////////////////


    // FUNCION DE COMPONENTE
    const handleSaveComponent = async () => {

        // VALIDAMOS QUE LOS CAMPOS TENGA VALOR 
        let noError = componentDetail != "" || componentDetail1 != "" || componentDetail2 != "" ? false : true;

        if (noError) {

        } else {
            let model = {
                clubID: clubID,
                evaluacionType: evaluationType[0].value,
                component: {
                    component: componentDetail,
                    component1: componentDetail1,
                    component2: componentDetail2
                }
            }

            let result = await apiSetting.AddComponente(model)

            if (result.success) {
                let randomID = 0
                setListItemComponentes((prev, i) => {
                    //console.log('PREV ', prev)
                    //1
                    randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                    objs.id = randomID
                    objs._id = result.data.message.data._id
                    objs.value = result.data.message.data.component.component
                    prev[0].item.push(objs)
                    objs = {
                        id: 0,
                        value: ''
                    }

                    // 2
                    randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                    objs.id = randomID
                    objs._id = result.data.message.data._id
                    objs.value = result.data.message.data.component.component1
                    prev[0].item.push(objs)
                    objs = {
                        id: 0,
                        value: ''
                    }

                    // 3
                    randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                    objs.id = randomID
                    objs._id = result.data.message.data._id
                    objs.value = result.data.message.data.component.component2
                    prev[0].item.push(objs)
                    objs = {
                        id: 0,
                        value: ''
                    }

                    return prev
                })
                formError({ type: 'success', msg: 'Se agrego correctamente' })
                setIsOpen(false)
            } else {
                formError({ type: 'warning', msg: `Error al crear componente Error: ${result.error}` })
            }

            //console.log('RESULT ADD COMPONENT ', result)
        }
    }

    const handlerAddComponent = (e, elem) => {
        switch (elem) {
            case 1:
                // SI EL CAMPO NO SE MODIFICA SE CARGA CON EL QUE EXISTE 
                if (e.target.value === "") {
                    if (componentDetail === "") {
                        formError({ type: 'warning', msg: 'El compo de componente 1 no puede estar vacio' })
                        return false
                    } else {
                        e.target.value = componentDetail
                    }
                }
                setComponentDetail(e.target.value)
                break;
            case 2:
                // SI EL CAMPO NO SE MODIFICA SE CARGA CON EL QUE EXISTE 
                if (e.target.value === "") {
                    if (componentDetail1 === "") {
                        formError({ type: 'warning', msg: 'El compo de componente 2 no puede estar vacio' })
                        return false
                    } else {
                        e.target.value = componentDetail1
                    }
                }
                setComponentDetail1(e.target.value)
                break;
            case 3:
                // SI EL CAMPO NO SE MODIFICA SE CARGA CON EL QUE EXISTE 
                if (e.target.value === "") {
                    if (componentDetail2 === "") {
                        formError({ type: 'warning', msg: 'El compo de componente 3 no puede estar vacio' })
                        return false
                    } else {
                        e.target.value = componentDetail2
                    }
                }
                setComponentDetail2(e.target.value)
                break;
            default:
                break;
        }
    }
    ////////////////////////////////////////////////////////////////


    // FUNCIONES DE FUNDAMENTOS
    const handlerSaveFundamentos = async () => {
        let noError = fundamentoDetils.fundamento1 != "" || fundamentoDetils.fundamento2 != "" ? false : true;

        if (noError) {

        } else {
            let model = {
                clubID: clubID,
                evaluacionType: evaluationType[0].value,
                fundamentos: {
                    fundamento1: fundamentoDetils.fundamento1,
                    fundamento2: fundamentoDetils.fundamento2
                }
            }
            let result = await apiSetting.AddFundamento(model)

            if (result.success) {
                let randomID = 0
                setListItemFundamentos(prev => {
                    randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                    objs.id = randomID
                    objs._id = result.data.message.data._id
                    objs.value = result.data.message.data.fundamentos.fundamento1
                    prev[0].item.push(objs)
                    objs = {
                        id: 0,
                        value: ''
                    }

                    objs.value = result.data.message.data.fundamentos.fundamento2
                    prev[0].item.push(objs)
                    objs = {
                        id: 0,
                        value: ''
                    }
                    return prev
                })
                formError({ type: 'success', msg: 'Se agrego correctamente' })
                setIsOpen(false)
            } else {
            }

            //console.log('RESULT FUNDAMENTS ', result)
        }
    }

    const handlerAddFundamentos = (e, elem) => {
        switch (elem) {
            case 1:
                setFundamentoDetails(prev => {
                    prev.fundamento1 = e.target.value
                    return prev
                })
                break;
            case 2:
                setFundamentoDetails(prev => {
                    prev.fundamento2 = e.target.value
                    return prev
                })
                break;
            default:
                break;
        }
    }
    ////////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////
    // FUNCIONES GENERALES //
    const formError = (props) => {
        switch (props.type) {
            case 'warning':
                setAlertType(props.type)
                setMsg(props.msg)
                setVisible(true)
                break;
            case 'success':
                setAlertType(props.type)
                setMsg(props.msg)
                setVisible(true)
                break;
            default:
                break;
        }

        setTimeout(() => {
            setVisible(false)
        }, 1850);
    }

    const showModal = (fieldType) => {
        if (fieldType === 1) {
            setField('Criterios de FIltrados')
            setFieldType(fieldType)
        }
        if (fieldType === 2) {
            setField('Componentes')
            setInnerHeight('55%')
            setFieldType(fieldType)
        }

        if (fieldType === 3) {
            setField('Fundamentos')
            setInnerHeight('45%')
            setFieldType(fieldType)
        }
        if (fieldType === 4) {
            setField('Items')
            setInnerHeight('100%')
            setInnerWidth('50vw')
            setFieldType(fieldType)
            setItemsCollection([])
        }
        if (fieldType === 5) {
            setField('Puntuación Numérica')
            setInnerHeight('100%')
            setInnerWidth('60vw')
            setFieldType(fieldType)
        }
        if (fieldType === 6) {
            setField('Puntuacion de Seleccion')
            setInnerHeight('25%')
            setInnerWidth('30vw')
            setFieldType(fieldType)
        }
        setIsOpen(true)
    }

    const ShowPopup = (id, msg, collect, field) => {
        setCollection(collect)
        setComponentForDelete(field)
        setDeleteID(id)
        setMsg(msg)
        setShowPopup(true)
    }

    const HidePopup = () => {
        setDeleteID(0)
        setMsg(null)
        setComponentForDelete(null)
        setShowPopup(false)
    }

    const onDismiss = () => {
        setVisible(false)
    }

    const getListStyle = isDraggingOver => ({
        height: '48px',
        width: '400px',
        marginLeft: '29px',
        maxWidth: '100%'
    });

    // this function opens and closes the sidebar on small devices
    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setsidebarOpened(!sidebarOpened);
    };

    const SetGlobalAspect = (e) => {
        // console.log('Global value change', e)
        setGlobal(prev => !prev)
    };
    
    const showAspect = (e) => {
        if (e === 0) {
            setShowAspect2(prev => !prev)
        } else {
            setShowAspect3(prev => !prev)
        }
    }
    //////////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////
    // CRITERIO DE FILTRADO FUCTION //
    let newArray = []

    const setFilterCriterialList = (item) => {
        if (item.label === 'Posición') {
            setFilterSelected(item.label)
            item.data.filter((task) => {
                objs.id = task.sport_positionID
                objs.value = task.position
                newArray.push(objs)
                objs = {
                    id: 0,
                    value: ''
                }
            })
        } else {
            setFilterSelected(item.label)
            item.data.filter(task => {
                // console.log('TASK ', task)
                objs.id = task.categorieID
                objs.value = task.categoriesName
                newArray.push(objs)
                objs = {
                    id: 0,
                    value: ''
                }
            })
        }
        if (item.hasOwnProperty("length")) {
            if (item.length > 0) {
                // console.log('Criterial ', criterialFilter)
                newArray.filter((task, i) => {
                    if (task.id === criterialFilter[0].id) {
                        newArray.splice(i, 1)
                    }
                })
            }
        }

        let model = [{
            id: 2,
            value: 'Criterios de Filtrado',
            item: newArray
        }]
        setListItemFiltrado(
            model,
            (state) => console.log('New Satet', state)
        )

        setIsOpen(false)


        // if (item.label === 'Posición') {
        //     setFilterSelected(item.label)
        //     position.filter((task) => {
        //         objs.id = task.sport_positionID
        //         objs.value = task.position
        //         newArray.push(objs)
        //         objs = {
        //             id: 0,
        //             value: ''
        //         }
        //     })

        //     // VALIDAMOS SI EXISTE EL DRAG AND DROP ALGUN ELEMENTO PARA ELIMINAR DEL REGISTRO
        //     if (item.hasOwnProperty("length")) {
        //         if (item.length > 0) {
        //             // console.log('Criterial ', criterialFilter)
        //             newArray.filter((task, i) => {
        //                 if (task.id === criterialFilter[0].id) {
        //                     newArray.splice(i, 1)
        //                 }
        //             })
        //         }
        //     }


        //     let model = [{
        //         id: 2,
        //         value: 'Criterios de Filtrado',
        //         item: newArray
        //     }]
        //     setListItemFiltrado(
        //         model,
        //         (state) => console.log('New Satet', state)
        //     )

        //     setIsOpen(false)
        // } else {
        //     setFilterSelected(item.label)
        //     categriaItems.filter(task => {
        //         // console.log('TASK ', task)
        //         objs.id = task.categorieID
        //         objs.value = task.categoriesName
        //         newArray.push(objs)
        //         objs = {
        //             id: 0,
        //             value: ''
        //         }
        //     })


        //     // VALIDAMOS SI EXISTE EL DRAG AND DROP ALGUN ELEMENTO PARA ELIMINAR DEL REGISTRO
        //     if (item.hasOwnProperty("length")) {
        //         if (item.length > 0) {
        //             newArray.filter((task, i) => {
        //                 if (task.id === criterialFilter[0].id) {
        //                     newArray.splice(i, 1)
        //                 }
        //             })
        //         }
        //     }


        //     let model = [{
        //         id: 2,
        //         value: 'Criterios de Filtrado',
        //         item: newArray
        //     }]
        //     setListItemFiltrado(model, (state) => console.log('New Satet', state))

        //     setIsOpen(false)
        // }
    }
    /////////////////////////////////////////////////////////////////////////

    // FUNCIONES DE PREVIEW

    //////////////////////////////////////////////////////////////////////////
    // DRAG ADN DROP FUNCTION //
    const reorder = async (list, source, destination, destinationLeng) => {
        // console.log('List ', destination)
        let item = [];

        switch (destination.droppableId) {
            case 'et':
                if (destinationLeng > 1) {
                    return { succes: false, data: [] }
                } else {
                    item = listItemEvaluiaciones[0].item.map((task, index) => {
                        if (task.id === source.index) return task
                    }).filter(task => task != undefined)

                    const [removed] = item.splice(source.index, 1);
                    item.splice(destination.index, 0, removed);
                    item = item.filter(task => task != undefined)

                    // SE REMUEVE DE LA LISTA DRAGABLE
                    listItemEvaluiaciones[0].item.map((task, index) => {
                        if (task.id === source.index) {
                            listItemEvaluiaciones[0].item.splice(index, 1)
                        }
                        return task
                    })


                    if (item.length <= 0) {
                        return { succes: false, data: [] };
                    } else {
                        return { succes: true, data: item[0] };
                    }

                }
                break;

            case "cdf":
                if (destinationLeng > 7) {
                    return { succes: false, data: [] }
                } else {
                    item = listItemFiltrado[0].item.map((task, index) => {
                        if (task.id === source.index) return task
                    }).filter(task => task != undefined || task?.id)


                    if (currentEvaluacion.length > 0) {
                     let currentC=  JSON.parse(window.localStorage.getItem('currentCategori'))
                     let userExist = JSON.parse(window.localStorage.getItem('user-exists'))

                        let model = {
                            categoryID: item[0].id,
                            clubID: userExist.lt
                        };
                      //  console.log('modelSettings', model.categoryID)
                        let resultApi = await api.GetCheckEvaluacionesInUser(model)
                      //console.log('resultapi', resultApi)
                        if(resultApi.inUse <= 0){
                            setDisabledFilter(true)
                        }
                        
                        let sourceItem = listItemFiltrado[0].item.map((task, index) => {
                            if (task.id === source.index) return task
                        }).filter(task => task != undefined || task?.id)


                        let filterType = currentEvaluacion[0].filterCriterial[0].filterType === 'sport_positionID' ? 'Posición' : 'Categoría';

                        if (filterType === filterSelected) {
                            let validateDrag = await handlerValidation.Validate(1, sourceItem[0].id, currentEvaluacion)
                            if (validateDrag.error) {
                                setCriterialAlert(true)
                                setMsg('Cargando evaluación para este criterio....')
                                let evaluationInProgress = currentEvaluacion.filter((task) => {
                                    if (task.filterCriterial[0].filterItem.indexOf(sourceItem[0].id) !== -1) return task
                                })

                                // CARGAR LOS CRITERIOS 
                                //console.log('Carga este modelo', evaluationInProgress)

                                let itemFilter = evaluationInProgress[0].filterCriterial[0].filterItem;
                                //console.log('Item filter ', itemFilter)
                                item = listItemFiltrado[0].item.map((task, index) => {
                                    if (itemFilter.indexOf(task.id) !== -1) return task
                                }).filter(task => task != undefined || task?.id)



                                // CARGAR LOS COMONENTES 
                                let component = evaluationInProgress[0].aspect[0].component
                                setComponentItem(component)

                                let component1 = evaluationInProgress[0].aspect[1].component
                                setComponentItem2(component1)

                                let component2 = evaluationInProgress[0].aspect[2].component
                                setComponentItem3(component2)


                                setListItemComponentes([{
                                    id: 3,
                                    value: 'Componentes',
                                    item: []
                                }])

                                // CARGAR LOS FUNDAMENTOS

                                let fundamento1 = evaluationInProgress[0].aspect[0].fundamento1[0].fundamento
                                let item1 = evaluationInProgress[0].aspect[0].fundamento1[0].item
                                let fundamento2 = evaluationInProgress[0].aspect[0].fundamento2[0].fundamento
                                let item2 = evaluationInProgress[0].aspect[0].fundamento2[0].item
                                let fundamento3 = evaluationInProgress[0].aspect[1].fundamento1[0].fundamento
                                let item3 = evaluationInProgress[0].aspect[1].fundamento1[0].item
                                let fundamento4 = evaluationInProgress[0].aspect[1].fundamento2[0].fundamento
                                let item4 = evaluationInProgress[0].aspect[1].fundamento2[0].item
                                let fundamento5 = evaluationInProgress[0].aspect[2].fundamento1[0].fundamento
                                let item5 = evaluationInProgress[0].aspect[2].fundamento1[0].item
                                let fundamento6 = evaluationInProgress[0].aspect[2].fundamento2[0].fundamento
                                let item6 = evaluationInProgress[0].aspect[2].fundamento2[0].item

                                setFundamento1(fundamento1)
                                setFundamento2(fundamento2)
                                setFundamento3(fundamento3)
                                setFundamento4(fundamento4)
                                setFundamento5(fundamento5)
                                setFundamento6(fundamento6)

                                setItem1(item1)
                                setItem2(item2)
                                setItem3(item3)
                                setItem4(item4)
                                setItem5(item5)
                                setItem6(item6)
                            }
                        }
                    }

                    const [removed] = item.splice(source.index, 1);
                    item.splice(destination.index, 0, removed);
                    item = item.filter(task => task != undefined)

                    // SE REMUEVE DE LA LISTA DRAGABLE
                    listItemFiltrado[0].item.map((task, index) => {
                        if (task.id === source.index) {
                            listItemFiltrado[0].item.splice(index, 1)
                        }
                        return task
                    })
                    if (item.length <= 0) {
                        return { succes: false, data: [], error: 0 };
                    } else {
                        //console.log('ITEMS ', item)
                        return { succes: true, data: item };
                    }


                }
            case "compn1":
            case "compn2":
            case "compn3":
                if (destinationLeng > 0) {
                    return { succes: false, data: [] };
                } else {
                    item = listItemComponentes[0].item.map((task, index) => {
                        if (task.id === source.index) return task
                    }).filter(task => task != undefined)
                    const [removed] = item.splice(source.index, 1);
                    item.splice(destination.index, 0, removed);
                    item = item.filter(task => task != undefined)

                    // // SE REMUEVE DE LA LISTA DRAGABLE
                    listItemComponentes[0].item.map((task, index) => {
                        if (task.id === source.index) {
                            listItemComponentes[0].item.splice(index, 1)
                        }
                        return task
                    })
                    if (item.length <= 0) {
                        return { succes: false, data: [] };
                    } else {
                        return { succes: true, data: item[0] };
                    }
                }

            case "fund1":
            case 'fund2':
            case 'fund3':
            case 'fund4':
            case 'fund5':
            case 'fund6':
                if (destinationLeng > 0) {
                    return { succes: false, data: [] };
                } else {

                    // VALIDACIONES DE CONTENIDO
                    item = listItemFundamentos[0].item.map((task, index) => {
                        if (task.id === source.index) return task
                    }).filter(task => task != undefined)
                    const [removed] = item.splice(source.index, 1);
                    item.splice(destination.index, 0, removed);
                    item = item.filter(task => task != undefined)

                    // // SE REMUEVE DE LA LISTA DRAGABLE
                    // listItem[3].item.map((task, index) => {
                    //     if (task.id === source.index) {
                    //         listItem[3].item.splice(index, 1)
                    //     }
                    //     return task
                    // })
                    if (item.length <= 0) {
                        return { succes: false, data: [] };
                    } else {
                        return { succes: true, data: item[0] };
                    }
                }

            case 'item1':
            case 'item2':
            case 'item3':
            case 'item4':
            case 'item5':
            case 'item6':
                item = listItemItems[0].item.map((task, index) => {
                    if (task.id === source.index) return task
                }).filter(task => task != undefined)

                const [removed] = item.splice(source.index, 1);
                item.splice(destination.index, 0, removed);
                item = item.filter(task => task != undefined)

                //console.log('ITEMS ', item)
                if (item.length <= 0) {
                    return { succes: false, data: [] };
                } else {
                    return { succes: true, data: item[0] };
                }

                break;
            default:
                break;
        }

    }

    const onDragEnd = async (result) => {
        // console.log('RESULT DRAG ', result)
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (source.index === destination.index && source.droppableId === destination.droppableId) {
            return;
        }
        let destinecionLen = 0
        let itemResult = {}
        let item = []

        switch (destination.droppableId) {

            /*TIPO DE EVALUACION*/
            case 'et':
                // console.log('SOurce ', source, 'Destination ', destination)
                //let validateDrag = handlerValidation(1,)
                destinecionLen = evaluationType.length
                try {
                    itemResult = await reorder([], source, destination, destinecionLen)
                    if (itemResult.succes && itemResult.data != undefined) {
                        item = itemResult.data

                        if (evaluationType.length <= 0) {
                            setEvaluationType([item])
                        } else {

                            if (evaluationType.length < 1 || itemResult.data.length > 0) {
                                let elem = item
                                setEvaluationType(prev => [...prev, elem])
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten un tipo de evaluación' })
                            }

                        }

                        // HABILITAR LAS OPCIONES SEGUN EVALUACION
                        if (item.value === "Técnica") {
                            setOptionPuntuacion([
                                { value: "1", label: "Numerica" },
                            ])
                        } else {
                            setOptionPuntuacion([
                                { value: "1", label: "Numerica" },
                                { value: "2", label: "String" },
                                { value: "3", label: "Boolean" },
                            ])
                        }


                        // CARGA LAS LISTAS DE ELEMENTOS
                        //  console.log('EVALUACION ', item)
                        let model = {
                            "clubID": clubID,
                            "evaluacionType": item.value
                        }
                        // console.log('EVALUACION ', model)
                        try {
                            let resultUpdate = await updateContextElemntByEvaluationType(model)
                            // console.log('Result Update ', resultUpdate)
                        } catch (error) {
                            console.log('Error', error)
                        }
                        //console.log('RESULT UPDATE CONTEXT ', result)

                    } else {
                        formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                    }
                } catch (error) {
                    console.log(`Error en DragEnd Selecion de evaluacion Error: ${error}`)
                    return false
                }

                break;

            /*CRITERIOS DE FILTRO 2*/
            case 'cdf':
                destinecionLen = criterialFilter.length

                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            console.log('Criterial Filter ', criterialFilter, 'Item ', item)
                            if (criterialFilter.length <= 0) {
                                setCriterialFilter(item);
                            } else {

                                if (criterialFilter.length < 6 || itemResult.data.length > 0) {
                                    let elem = item
                                    if (item.length > 1) {
                                        setCriterialFilter(item)
                                    } else {
                                        setCriterialFilter(prev => [...prev, item[0]])
                                    }

                                } else {
                                    formError({ type: 'warning', msg: 'Ups...? solo se permiten dos criterios de filtros' })
                                }

                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de criterio de filtrado Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;


                {/*COMPONENT 1*/ }
            case 'compn1':
                destinecionLen = componentItem.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (componentItem.length <= 0) {
                                setComponentItem([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 componente' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de componente 1 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'fund1':
                destinecionLen = fundamento1.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (fundamento1.length <= 0) {

                                // Validamos que no se repita el fundamento en el mismo componente
                                if (fundamento2.length > 0) {
                                    if (fundamento2[0].id === item.id) {
                                        setMsg('Ups...? ya seleccionó este fundamento para este componente')
                                        setFun1Alert(true)
                                        return false
                                    }
                                }

                                setFundamento1([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 fundamento' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de Fundamento 1 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;

            case 'item1':
                destinecionLen = item1.length

                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        if (destinecionLen < 9) {
                            itemResult = await reorder([], source, destination, destinecionLen)
                            if (itemResult.succes && itemResult.data != undefined) {
                                item = itemResult.data
                                if (item1.length <= 0) {
                                    setItem1([item]);
                                } else {
                                    let elem = item
                                    let itemExists = item1.map((task) => {
                                        if (task.id === item.id) {
                                            return true
                                        }
                                    })

                                    if (itemExists[0]) {
                                        setMsg('Ups...? ya seleccionó este item para este fundamento')
                                        setIem1Alert(true)
                                        return false
                                    }
                                    setItem1(prev => [...prev, elem])
                                }
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                            }
                        } else {
                            setMsg('Ups...? llego a límite permitido de item (maximo 9 items)')
                            setIem1Alert(true)
                            return false
                        }

                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de Item 1 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;

            case 'fund2':
                destinecionLen = fundamento2.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (fundamento2.length <= 0) {

                                // Validamos que no se repita el fundamento en el mismo componente
                                if (fundamento1.length > 0) {
                                    if (fundamento1[0].id === item.id) {
                                        setMsg('Ups...? ya seleccionó este fundamento para este componente')
                                        setFun2Alert(true)
                                        return false
                                    }
                                }
                                setFundamento2([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 fundamento' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de fundamento 2 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }


                break;
            case 'item2':
                destinecionLen = item2.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        if (destinecionLen < 9) {
                            itemResult = await reorder([], source, destination, destinecionLen)
                            if (itemResult.succes && itemResult.data != undefined) {
                                item = itemResult.data
                                if (item2.length <= 0) {
                                    setItem2([item]);
                                } else {
                                    let elem = item
                                    let itemExists = item2.map((task) => {
                                        if (task.id === item.id) {
                                            return true
                                        }
                                    })

                                    if (itemExists[0]) {
                                        setMsg('Ups...? ya seleccionó este item para este fundamento')
                                        setIem2Alert(true)
                                        return false
                                    }
                                    setItem2(prev => [...prev, elem])
                                }
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                            }
                        } else {
                            setMsg('Ups...? llego a límite permitido de item (maximo 9 items)')
                            setIem2Alert(true)
                            return false
                        }

                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de fundamento 2 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;

                {/*COMPONENT 2*/ }
            case 'compn2':
                destinecionLen = componentItem2.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (componentItem2.length <= 0) {
                                setComponentItem2([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 componente' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de comonent 2 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'fund3':
                destinecionLen = fundamento3.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (fundamento3.length <= 0) {

                                // Validamos que no se repita el fundamento en el mismo componente
                                if (fundamento4.length > 0) {
                                    if (fundamento4[0].id === item.id) {
                                        setMsg('Ups...? ya seleccionó este fundamento para este componente')
                                        setFun3Alert(true)
                                        return false
                                    }
                                }

                                setFundamento3([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 fundamento' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de fundamento 3 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'item3':
                destinecionLen = item3.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        if (destinecionLen < 9) {
                            itemResult = await reorder([], source, destination, destinecionLen)
                            if (itemResult.succes && itemResult.data != undefined) {
                                item = itemResult.data
                                if (item3.length <= 0) {
                                    setItem3([item]);
                                } else {
                                    let elem = item
                                    let itemExists = item3.map((task) => {
                                        if (task.id === item.id) {
                                            return true
                                        }
                                    })

                                    if (itemExists[0]) {
                                        setMsg('Ups...? ya seleccionó este item para este fundamento')
                                        setIem3Alert(true)
                                        return false
                                    }
                                    setItem3(prev => [...prev, elem])
                                }
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                            }
                        } else {
                            setMsg('Ups...? llego a límite permitido de item (maximo 9 items)')
                            setIem3Alert(true)
                            return false
                        }

                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de item 3 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'fund4':
                destinecionLen = fundamento4.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (fundamento4.length <= 0) {

                                // Validamos que no se repita el fundamento en el mismo componente
                                if (fundamento3.length > 0) {
                                    if (fundamento3[0].id === item.id) {
                                        setMsg('Ups...? ya seleccionó este fundamento para este componente')
                                        setFun4Alert(true)
                                        return false
                                    }
                                }
                                setFundamento4([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 fundamento' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de fundamento 4 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'item4':
                destinecionLen = item4.length
                if (listItemComponentes[0].item.length > 0 && listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        if (destinecionLen < 9) {
                            itemResult = await reorder([], source, destination, destinecionLen)
                            if (itemResult.succes && itemResult.data != undefined) {
                                item = itemResult.data
                                if (item4.length <= 0) {
                                    setItem4([item]);
                                } else {
                                    let elem = item
                                    let itemExists = item4.map((task) => {
                                        if (task.id === item.id) {
                                            return true
                                        }
                                    })

                                    if (itemExists[0]) {
                                        setMsg('Ups...? ya seleccionó este item para este fundamento')
                                        setIem4Alert(true)
                                        return false
                                    }
                                    setItem4(prev => [...prev, elem])
                                }
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                            }
                        } else {
                            setMsg('Ups...? llego a límite permitido de item (maximo 9 items)')
                            setIem4Alert(true)
                            return false
                        }

                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de item 4 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;


                {/*COMPONENT 3*/ }
            case 'compn3':
                destinecionLen = componentItem3.length
                if (listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (componentItem3.length <= 0) {
                                setComponentItem3([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 componente' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de componente 3 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'fund5':
                //console.log('Aqui ')
                destinecionLen = fundamento5.length
                if (listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (fundamento5.length <= 0) {

                                // Validamos que no se repita el fundamento en el mismo componente
                                if (fundamento6.length > 0) {
                                    if (fundamento6[0].id === item.id) {
                                        setMsg('Ups...? ya seleccionó este fundamento para este componente')
                                        setFun5Alert(true)
                                        return false
                                    }
                                }
                                setFundamento5([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 fundamento' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de fundamento 5 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'item5':
                destinecionLen = item5.length
                if (listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        if (destinecionLen < 9) {
                            itemResult = await reorder([], source, destination, destinecionLen)
                            if (itemResult.succes && itemResult.data != undefined) {
                                item = itemResult.data
                                if (item5.length <= 0) {
                                    setItem5([item]);
                                } else {
                                    let elem = item
                                    let itemExists = item5.map((task) => {
                                        if (task.id === item.id) {
                                            return true
                                        }
                                    })

                                    if (itemExists[0]) {
                                        setMsg('Ups...? ya seleccionó este item para este fundamento')
                                        setIem5Alert(true)
                                        return false
                                    }
                                    setItem5(prev => [...prev, elem])
                                }
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                            }
                        } else {
                            setMsg('Ups...? llego a límite permitido de item (maximo 9 items)')
                            setIem5Alert(true)
                            return false
                        }

                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de item 5 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'fund6':
                destinecionLen = fundamento6.length
                if (listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        itemResult = await reorder([], source, destination, destinecionLen)
                        if (itemResult.succes && itemResult.data != undefined) {
                            item = itemResult.data
                            if (fundamento6.length <= 0) {

                                // Validamos que no se repita el fundamento en el mismo componente
                                if (fundamento5.length > 0) {
                                    if (fundamento5[0].id === item.id) {
                                        setMsg('Ups...? ya seleccionó este fundamento para este componente')
                                        setFun6Alert(true)
                                        return false
                                    }
                                }
                                setFundamento6([item]);
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? solo se permiten 1 fundamento' })
                            }
                        } else {
                            formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                        }
                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de fundamento 6 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;
            case 'item6':
                destinecionLen = item6.length
                if (listItemFundamentos[0].item.length > 0 && listItemItems[0].item.length > 0) {
                    try {
                        if (destinecionLen < 9) {
                            itemResult = await reorder([], source, destination, destinecionLen)
                            if (itemResult.succes && itemResult.data != undefined) {
                                item = itemResult.data
                                if (item6.length <= 0) {
                                    setItem6([item]);
                                } else {
                                    let elem = item
                                    let itemExists = item6.map((task) => {
                                        if (task.id === item.id) {
                                            return true
                                        }
                                    })

                                    if (itemExists[0]) {
                                        setMsg('Ups...? ya seleccionó este item para este fundamento')
                                        setIem6Alert(true)
                                        return false
                                    }
                                    setItem6(prev => [...prev, elem])
                                }
                            } else {
                                formError({ type: 'warning', msg: 'Ups...? este elemento no se puede dropear en esta sección' })
                            }
                        } else {
                            setMsg('Ups...? llego a límite permitido de item (maximo 9 items)')
                            setIem6Alert(true)
                            return false
                        }

                    } catch (error) {
                        console.log(`Error en DragEnd Selecion de item 6 Error: ${error}`)
                        return false
                    }
                } else {
                    formError({ type: 'warning', msg: 'Antes de crear una evaluacion debe haber creado los siguientes elementos: Componentes, Fundamentos , Items' })
                }

                break;

            default:
                break;
        }

    }

    ////////////////////////////////////////////////////////////////////////////

    // DRAG AND DROP DELETE FUNCION

    const deleteItem = (index, i) => {
      //  console.log('Index', index)
        if (index > 1) {
            if (disabledFilter) {
                toast.error('No se puede modificar la evaluacion de esta categoria porque existe atletas con evaluacion aplicada')
                return false
            }
        }
        switch (index) {
            case 1:
                handlerClearForm()
                break;
            case 2:
                const temp = [...criterialFilter];
                temp.splice(i, 1);
                setCriterialFilter(
                    temp,
                    (state) => {
                        setFilterCriterialList({ label: filterSelected, data: filterSelected === 'Categoría' ? categoriaList : position })
                        // ELIMINAR SI EL criterialFilter.length === 0 debe elimar todos los componentes
                        if (state.length <= 0) {
                            setComponentItem([])
                            setComponentItem2([])
                            setComponentItem3([])
                            setFundamento1([])
                            setFundamento2([])
                            setFundamento3([])
                            setFundamento4([])
                            setFundamento5([])
                            setFundamento6([])

                            setItem1([])
                            setItem2([])
                            setItem3([])
                            setItem4([])
                            setItem5([])
                            setItem6([])
                        }
                    }

                )


                break;
            case 3:
                const temps = [...componentItem];
                const deleteItem = componentItem[0];

                //console.log('DELETE ITEM ', deleteItem)
                setListItemComponentes(prev => {
                    prev[0].item.push(deleteItem)
                    return prev
                })
                temps.splice(i, 1);
                setComponentItem(temps)
                break;
            case 4:
                const tmps = [...fundamento1]
                tmps.splice(i, 1);
                setFundamento1(tmps)
                break;
            case 5:
                const itmTmp = [...item1]
                itmTmp.splice(i, 1);
                setItem1(itmTmp)
                break;
            case 6:
                const fun2 = [...fundamento2]
                fun2.splice(i, 1);
                setFundamento2(fun2)
                break;
            case 7:
                const item2Temp = [...item2]
                item2Temp.splice(i, 1);
                setItem2(item2Temp)
                break;

            case 8:
                const compn2 = [...componentItem2];
                const deleteItem2 = componentItem2[0];

                //console.log('DELETE ITEM ', deleteItem)
                setListItemComponentes(prev => {
                    prev[0].item.push(deleteItem2)
                    return prev
                })
                compn2.splice(i, 1);
                setComponentItem2(compn2)
                break;
            case 9:
                const fund3 = [...fundamento3]
                fund3.splice(i, 1);
                setFundamento3(fund3)
                break;
            case 10:
                const itm3Tmp = [...item3]
                itm3Tmp.splice(i, 1);
                setItem3(itm3Tmp)
                break;
            case 11:
                const fun4 = [...fundamento4]
                fun4.splice(i, 1);
                setFundamento4(fun4)
                break;
            case 12:
                const item4Temp = [...item4]
                item4Temp.splice(i, 1);
                setItem4(item4Temp)
                break;

            case 13:
                const compn3 = [...componentItem3];
                const deleteItem3 = componentItem3[0];

                //console.log('DELETE ITEM ', deleteItem)
                setListItemComponentes(prev => {
                    prev[0].item.push(deleteItem3)
                    return prev
                })
                compn3.splice(i, 1);
                setComponentItem3(compn3)
                break;
            case 14:
                const fund5 = [...fundamento5]
                fund5.splice(i, 1);
                setFundamento5(fund5)
                break;
            case 15:
                const itm5Tmp = [...item5]
                itm5Tmp.splice(i, 1);
                setItem5(itm5Tmp)
                break;
            case 16:
                const fun6 = [...fundamento6]
                fun6.splice(i, 1);
                setFundamento6(fun6)
                break;
            case 17:
                const item6Temp = [...item6]
                item6Temp.splice(i, 1);
                setItem6(item6Temp)
                break;
            default:
                break;
        }
    }

    const UpdateDbCollection = async (collection) => {
        //console.log('COLLECTION ', collection, 'ID ', id)

        switch (collection) {
            case 'component':
                let data = {
                    clubID: clubID,
                    evaluacionType: evaluationType[0].value,
                    component: {
                        component: componentDetail === "" ? listItemComponentes[0].item[0].value : componentDetail,
                        component1: componentDetail1 === "" ? listItemComponentes[0].item[1].value : componentDetail1,
                        component2: componentDetail2 === "" ? listItemComponentes[0].item[2].value : componentDetail2
                    }

                }
                //console.log('model ', data , 'Item ', item , "componente 1" , componentDetail, 'Componente 2', componentDetail1, 'Componente 3',componentDetail2 )

                let updateComponent = await apiSetting.UpdateCompomonent(data)
                // console.log('Udate Data ', updateComponent)
                if (updateComponent.success) {
                    formError({ type: 'success', msg: 'Se actualizó correctamente' })
                    HidePopup()
                    let model = {
                        "clubID": clubID,
                        "evaluacionType": evaluationType[0].value
                    }
                    let result = await updateContextElemntByEvaluationType(model)

                    setListItemComponentes([{
                        id: 3,
                        value: 'Componentes',
                        item: []
                    }],
                        (state) => {
                            let prev = [{
                                id: 3,
                                value: 'Componentes',
                                item: []
                            }]
                            // AREGAMOS LOS NUEVOS COMPONENTES
                            //1
                            let randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                            objs.id = randomID
                            objs._id = updateComponent.data.message.data._id
                            objs.value = updateComponent.data.message.data.component.component
                            prev[0].item.push(objs)
                            objs = {
                                id: 0,
                                value: ''
                            }

                            // 2
                            randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                            objs.id = randomID
                            objs.value = updateComponent.data.message.data.component.component1
                            prev[0].item.push(objs)
                            objs = {
                                id: 0,
                                value: ''
                            }

                            // 3
                            randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                            objs.id = randomID
                            objs.value = updateComponent.data.message.data.component.component2
                            prev[0].item.push(objs)
                            objs = {
                                id: 0,
                                value: ''
                            }
                            console.log('Prev ', prev)
                            setListItemComponentes(prev)

                            setComponentDetail("")
                            setComponentDetail1("")
                            setComponentDetail2("")
                            setIsOpen(false)
                        }
                    )

                } else {
                    formError({ type: 'warning', msg: 'Lo sentimos no se pudo realizar la acción' })
                }
                break;
            case 'fundamento':
                //console.log('FUndam ', listItemFundamentos[0].item)
                let dataF = {
                    clubID: clubID,
                    evaluacionType: evaluationType[0].value,
                    fundamentos: {
                        fundamento1: fundamentoDetils.fundamento1 === "" ? listItemFundamentos[0].item[0].value : fundamentoDetils.fundamento1,
                        fundamento2: fundamentoDetils.fundamento2 === "" ? listItemFundamentos[0].item[1].value : fundamentoDetils.fundamento2
                    }
                }
                let resultUpdateFundament = await apiSetting.UpdateFundamento(dataF)

                //console.log('DUANMANETOS UDATE ', resultUpdateFundament)
                if (resultUpdateFundament.success) {
                    formError({ type: 'success', msg: 'Se actualizó correctamente' })
                    HidePopup()
                    let model = {
                        "clubID": clubID,
                        "evaluacionType": evaluationType[0].value
                    }
                    let result = await updateContextElemntByEvaluationType(model)

                    // setListItemFundamentos([
                    //     {
                    //         id: 4,
                    //         value: 'Fundamentos',
                    //         item: []
                    //     }], () => {
                    //         let prev = [{
                    //             id: 4,
                    //             value: 'Fundamentos',
                    //             item: []
                    //         }]

                    //         let randomID = parseInt(Math.random(100 * 5).toString().replace('.', 0))
                    //         objs.id = randomID

                    //     })

                } else {
                    formError({ type: 'warning', msg: 'Lo sentimos no se pudo realizar la acción' })
                }
                break;
            default:
                break;
        }


    }

    // RENDER //
    //////////////////////////////////

    //console.log('LIst Component ', listItemComponentes)
    return (
        <DragDropContext onDragEnd={(res) => onDragEnd(res)} >
            <React.Fragment>
                <div className="wrapper">

                    <div className="sidebar">

                        <div className="sidebar-wrapper">
                            <PopupComponent isOpen={showPopup} onDelete={() => UpdateDbCollection(collection, deleteID, componentForDelete)} msg={msg} onClose={() => HidePopup()} />
                            <Droppable droppableId="qti">
                                {(droppableProvider, snapshot) => (
                                    <ul className="nav ulScroll"
                                        {...droppableProvider.droppableProps}
                                        ref={droppableProvider.innerRef}
                                        style={{ width: '100%', height: window.innerHeight - (window.innerHeight * 0.22) }}
                                    >
                                        <li className="li-sidebarSetting-column">
                                            <h4 className="text-white">Evaluaciones Técnicas</h4>
                                            <hr className="divider mt-0" />
                                        </li>

                                        {/*SIDE BAR DRAGGABLE COMPONENTS*/}

                                        {/*EVALUACIONES*/}
                                        <DraggableItem
                                            list={listItemEvaluiaciones}
                                            showBottom={false}

                                        />

                                        {/*FILTROS*/}
                                        <DraggableItem
                                            list={listItemFiltrado}
                                            showBottom={true}
                                            disabled={evaluationType.length <= 0 ? true : false}
                                            onClick={(item) => showModal(1)}
                                        />

                                        {/*COMPONENTE*/}
                                        <DraggableItem
                                            list={listItemComponentes}
                                            showBottom={true}
                                            closeButton={true}
                                            disabled={evaluationType.length <= 0 ? true : false}
                                            onClick={(item) => showModal(2)}
                                            onDelete={(item, msg, collect, field) => ShowPopup(item, msg, collect, field)}
                                        />

                                        {/*FUNDAMENTOS*/}
                                        <DraggableItem
                                            list={listItemFundamentos}
                                            showBottom={true}
                                            closeButton={true}
                                            disabled={evaluationType.length <= 0 ? true : false}
                                            onClick={(item) => showModal(3)}
                                            onClose={(id) => console.log('FUNDAMENTO ID ', id)}
                                        />


                                        {/*ITEM*/}
                                        <DraggableItem
                                            list={listItemItems}
                                            showBottom={true}
                                            closeButton={true}
                                            disabled={evaluationType.length <= 0 ? true : false}
                                            extraDetails={true}
                                            onClick={(item) => showModal(4)}
                                            onClose={(id) => console.log('ITEM ID ', id)}
                                        />

                                        <li className="li-sidebarSetting">
                                            <Row className="li-sidebarSetting">
                                                <p className="text-white pLabel">Puntuación</p>
                                            </Row>
                                            <Row className="mr-0">
                                                <ul>
                                                    <li className="text-white ml-3 mb-2">
                                                        < Button
                                                            className="btn-success"
                                                            size="sm"
                                                            onClick={() => showModal(5)}
                                                        >
                                                            <span style={{ fontSize: '10px' }}>Numerica</span>
                                                        </Button>
                                                    </li>
                                                    {/* <li className="text-white ml-3 mb-2">
                                                        < Button
                                                            className="btn-success"
                                                            size="sm"
                                                            onClick={() => showModal(6)}
                                                        >
                                                            <span style={{ fontSize: '10px' }}>Selección</span>
                                                        </Button>
                                                    </li> */}

                                                </ul>
                                            </Row>
                                        </li>
                                        {droppableProvider.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                            <ul className="navBack">
                                <li className="activepro_back">
                                    <ReactstrapNavLink
                                        onClick={() => window.location.href = process.env.REACT_APP_DASHBOARD_URLS !== undefined ? process.env.REACT_APP_DASHBOARD_URLS : ''}
                                    >
                                        <i className="tim-icons icon-minimal-left" />
                                        <p>Regresar</p>
                                    </ReactstrapNavLink>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="main-panel" ref={mainPanelRef}>
                        <AdminNavbar
                            brandText={null}
                            toggleSidebar={toggleSidebar}
                            sidebarOpened={sidebarOpened}
                        />

                        <div className="content" >
                            <Alert
                                className='bottom'
                                color={alertType}
                                isOpen={visible}
                                toggle={onDismiss}
                            >
                                <h4 className="alert-heading text-white">
                                    Warning
                                </h4>
                                <p className="text-white">
                                    {msg}
                                </p>

                            </Alert>
                            <Form >
                                <Row>
                                    <Col xs="12">
                                        <Tabs>
                                            <div label="Agregar Evluacion">
                                                <PreviewComponent
                                                    show={previewActive ? '' : 'hideForm'}
                                                    aspectModel={previewModel}
                                                    evaluation={evaluationType.length > 0 ? evaluationType[0].value : ''}
                                                />
                                                <div className={previewActive ? 'hideForm' : ''}>
                                                    <Row className="p-2 ml-2">
                                                        <Col className="col-3">
                                                            <h4 className="title mb-0">Tipo de Evaluacion</h4>
                                                            <small className="lead" style={{ fontSize: '10px' }}>Seleccione el tipo de evaluacion que desea crear</small>
                                                        </Col>
                                                    </Row>
                                                    <FormGroup>
                                                        <div className="row">
                                                            <Droppable droppableId="et">
                                                                {(droppableProvider, snapshot) => (
                                                                    <div
                                                                        className="ulDroppable"
                                                                        {...droppableProvider.droppableProps}
                                                                        ref={droppableProvider.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        {
                                                                            evaluationType.map((task, i) => (
                                                                                <span className="liDroppableItem" key={i}>
                                                                                    {task.value}
                                                                                    <Button close onClick={() => deleteItem(1)} disabled={disabledItem} />
                                                                                </span>))
                                                                        }
                                                                        {droppableProvider.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>
                                                    </FormGroup>
                                                    <hr className="divider" style={{ width: "100%" }} />
                                                    <Row className="p-2 ml-2">
                                                        <Col className="col-3">
                                                            <h4 className="title mb-0">Criterios de Filtrado</h4>
                                                            <small className="lead" style={{ fontSize: '10px' }}>Selecciona la agrupacion de las evaluaciones segun. Categoria, Posicion o edad</small>
                                                        </Col>
                                                    </Row>

                                                    <FormGroup>
                                                        <div className="row">
                                                            <Droppable droppableId="cdf">
                                                                {(droppableProvider, snapshot) => (
                                                                    <div
                                                                        className="ulDroppable"
                                                                        {...droppableProvider.droppableProps}
                                                                        ref={droppableProvider.innerRef}
                                                                        style={{ width: '100%', marginLeft: '29px', maxHeight: '100%', padding: '8px', height: '100%' }}
                                                                    >
                                                                        {
                                                                            criterialFilter.map((task, i) => (
                                                                                <span className="liDroppableItem" key={i} >
                                                                                    {task.value}
                                                                                    <Button close onClick={() => deleteItem(2, i)} />
                                                                                </span>))
                                                                        }
                                                                        {droppableProvider.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                            <PopupComponent
                                                                isOpen={criterialAlert}
                                                                msg={msg}
                                                                autoHide={true}
                                                                onClose={() => setCriterialAlert(false)}
                                                                showLoading={true}
                                                                labelSave="Cargar"
                                                            />
                                                        </div>
                                                    </FormGroup>

                                                    <hr className="divider" style={{ width: "100%" }} />

                                                    {/*ASPECTO 1*/}
                                                    <div key={1}>
                                                        {/*HEADER*/}
                                                        <Row className="p-2 ml-2">
                                                            <Row>
                                                                <Col>
                                                                    <h4 className="title mb-0">Aspecto de evaluacion 1</h4>
                                                                </Col>
                                                            </Row>

                                                        </Row>

                                                        <Row className="p-2 ml-2">
                                                            <Col xs={10}>
                                                                <Row>
                                                                    <Col xs={6}>
                                                                        <h5 className="title mb-0">Componente</h5>
                                                                        <small className="lead" style={{ fontSize: '10px' }}>
                                                                            (Elemento principal de la evaluación deportiva.)
                                                                            Ej: Evaluación ofensiva
                                                                        </small>
                                                                    </Col>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" checked={global} onChange={(text) => SetGlobalAspect(text.target.value)} />
                                                                            <span className="form-check-sign"></span> Global
                                                                        </label>
                                                                    </div>
                                                                </Row>

                                                            </Col>
                                                            <Col xs={6} className='mb-4 mt-2'>
                                                                <Droppable droppableId="compn1">
                                                                    {(droppableProvider, snapshot) => (
                                                                        <div

                                                                            className="ulDroppable"
                                                                            {...droppableProvider.droppableProps}
                                                                            ref={droppableProvider.innerRef}
                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                        >
                                                                            {
                                                                                componentItem.map((task, i) => (
                                                                                    <span className="liDroppableItem" key={i}>
                                                                                        {task.value}
                                                                                        <Button close onClick={() => deleteItem(3, i)} disabled={global} />
                                                                                    </span>))
                                                                            }
                                                                            {droppableProvider.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xs='6'>
                                                                <FormGroup style={{ marginLeft: "50px" }}>
                                                                    <Label for="exampleEmail">
                                                                        <h5 className="title mb-0">Fundamentos</h5>
                                                                        <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                    </Label>
                                                                    <Row className="mt-2">
                                                                        <Droppable droppableId="fund1">
                                                                            {(droppableProvider, snapshot) => (
                                                                                <div
                                                                                    className="ulDroppable"
                                                                                    {...droppableProvider.droppableProps}
                                                                                    ref={droppableProvider.innerRef}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                                >
                                                                                    {
                                                                                        fundamento1.map((task, i) => (
                                                                                            <span className="liDroppableItem" key={i}>
                                                                                                {task.value}
                                                                                                <Button close onClick={() => deleteItem(4, i)} disabled={global} />
                                                                                            </span>))
                                                                                    }
                                                                                    {droppableProvider.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                        <PopupComponent isOpen={fund1Alert} msg={msg} autoHide={true} onClose={() => setFun1Alert(false)} />
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs='12'>
                                                                <FormGroup style={{ marginLeft: "50px" }}>
                                                                    <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                    <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                    <Row className="mb-3">
                                                                        <Droppable droppableId="item1">
                                                                            {(droppableProvider, snapshot) => (
                                                                                <div

                                                                                    className="ulDroppable"
                                                                                    {...droppableProvider.droppableProps}
                                                                                    ref={droppableProvider.innerRef}
                                                                                    style={{ width: '100%', marginLeft: '29px', maxHeight: '100%', padding: '8px', height: '100%' }}
                                                                                >
                                                                                    {
                                                                                        item1.map((task, i) => (
                                                                                            <p className="liDroppableItem" key={i} style={{ width: '24%' }}>
                                                                                                <span className="mr-2">{task.value}</span>
                                                                                                <span className="mr-2">({task.definition})</span>
                                                                                                <span className="mr-1 lbal">{task.puntuacion}</span>
                                                                                                <Button close onClick={() => deleteItem(5, i)} disabled={global} />
                                                                                            </p>))
                                                                                    }
                                                                                    {droppableProvider.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                        <PopupComponent isOpen={item1Alert} msg={msg} autoHide={true} onClose={() => setIem1Alert(false)} />
                                                                    </Row>
                                                                </FormGroup>

                                                            </Col>
                                                        </Row>
                                                        <hr className="divider" style={{ width: "100%" }} />
                                                        <Row>
                                                            <Col xs='6'>
                                                                <FormGroup style={{ marginLeft: "50px" }}>
                                                                    <Label for="exampleEmail">
                                                                        <h5 className="title mb-0">Fundamentos</h5>
                                                                        <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                    </Label>
                                                                    <Row className="mt-2">
                                                                        <Droppable droppableId="fund2">
                                                                            {(droppableProvider, snapshot) => (
                                                                                <div
                                                                                    className="ulDroppable"
                                                                                    {...droppableProvider.droppableProps}
                                                                                    ref={droppableProvider.innerRef}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                                >
                                                                                    {
                                                                                        fundamento2.map((task, i) => (
                                                                                            <span className="liDroppableItem" key={i}>
                                                                                                {task.value}
                                                                                                <Button close onClick={() => deleteItem(6, i)} disabled={global} />
                                                                                            </span>))
                                                                                    }
                                                                                    {droppableProvider.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                        <PopupComponent isOpen={fund2Alert} msg={msg} autoHide={true} onClose={() => setFun2Alert(false)} />
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs='12'>
                                                                <FormGroup style={{ marginLeft: "50px" }}>
                                                                    <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                    <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                    <Row className="mb-3">
                                                                        <Droppable droppableId="item2">
                                                                            {(droppableProvider, snapshot) => (
                                                                                <div

                                                                                    className="ulDroppable"
                                                                                    {...droppableProvider.droppableProps}
                                                                                    ref={droppableProvider.innerRef}
                                                                                    style={{ width: '100%', marginLeft: '29px', maxHeight: '100%', padding: '8px', height: '100%' }}
                                                                                >
                                                                                    {
                                                                                        item2.map((task, i) => (
                                                                                            <p className="liDroppableItem" key={i} style={{ width: '24%' }}>
                                                                                                <span className="mr-2">{task.value}</span>
                                                                                                <span className="mr-2">({task.definition})</span>
                                                                                                <span className="mr-1 lbal">{task.puntuacion}</span>
                                                                                                <Button close onClick={() => deleteItem(7, i)} disabled={global} />
                                                                                            </p>))
                                                                                    }
                                                                                    {droppableProvider.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                        <PopupComponent isOpen={item2Alert} msg={msg} autoHide={true} onClose={() => setIem2Alert(false)} />
                                                                    </Row>
                                                                </FormGroup>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <hr className="divider" style={{ width: "100%" }} />

                                                    {/*ASPECTO 2*/}
                                                    <div key={2}>
                                                        {/*HEADER*/}
                                                        <Row className="p-2 ml-2">
                                                            <Row>
                                                                <Col xs={10}>
                                                                    <h4 className="title mb-0">Aspecto de evaluacion 2</h4>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <div className="form-check" style={{ marginTop: '0rem' }}>
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" checked={showAspect2} onChange={(text) => showAspect(0, text.target.value)} />
                                                                            <span className="form-check-sign"></span> Ocultar
                                                                        </label>
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                        </Row>
                                                        {
                                                            !showAspect2 && <>
                                                                <Row className="p-2 ml-2">
                                                                    <Col xs={10}>
                                                                        <Row>
                                                                            <Col xs={6}>
                                                                                <h5 className="title mb-0">Componente</h5>
                                                                                <small className="lead" style={{ fontSize: '10px' }}>
                                                                                    (Elemento principal de la evaluación deportiva.)
                                                                                    Ej: Evaluación ofensiva
                                                                                </small>
                                                                            </Col>

                                                                        </Row>

                                                                    </Col>
                                                                    <Col xs={6} className='mb-4 mt-2'>
                                                                        <Droppable droppableId="compn2">
                                                                            {(droppableProvider, snapshot) => (
                                                                                <div

                                                                                    className="ulDroppable"
                                                                                    {...droppableProvider.droppableProps}
                                                                                    ref={droppableProvider.innerRef}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                                >
                                                                                    {
                                                                                        componentItem2.map((task, i) => (
                                                                                            <span className="liDroppableItem" key={i}>
                                                                                                {task.value}
                                                                                                <Button close onClick={() => deleteItem(8, i)} />
                                                                                            </span>))
                                                                                    }
                                                                                    {droppableProvider.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col xs='6'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail">
                                                                                <h5 className="title mb-0">Fundamentos</h5>
                                                                                <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                            </Label>
                                                                            <Row className="mt-2">
                                                                                <Droppable droppableId="fund3">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div
                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                                        >
                                                                                            {
                                                                                                fundamento3.map((task, i) => (
                                                                                                    <span className="liDroppableItem" key={i}>
                                                                                                        {task.value}
                                                                                                        <Button close onClick={() => deleteItem(9, i)} />
                                                                                                    </span>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={fund3Alert} msg={msg} autoHide={true} onClose={() => setFun3Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                            <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                            <Row className="mb-3">
                                                                                <Droppable droppableId="item3">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div

                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={{ width: '100%', marginLeft: '29px', maxHeight: '100%', padding: '8px', height: '100%' }}
                                                                                        >
                                                                                            {
                                                                                                item3.map((task, i) => (
                                                                                                    <p className="liDroppableItem" key={i} style={{ width: '24%' }}>
                                                                                                        <span className="mr-2">{task.value}</span>
                                                                                                        <span className="mr-2">({task.definition})</span>
                                                                                                        <span className="mr-1 lbal">{task.puntuacion}</span>
                                                                                                        <Button close onClick={() => deleteItem(10, i)} />
                                                                                                    </p>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={item3Alert} msg={msg} autoHide={true} onClose={() => setIem3Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>
                                                                <hr className="divider" style={{ width: "100%" }} />
                                                                <Row>
                                                                    <Col xs='6'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail">
                                                                                <h5 className="title mb-0">Fundamentos</h5>
                                                                                <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                            </Label>
                                                                            <Row className="mt-2">
                                                                                <Droppable droppableId="fund4">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div
                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                                        >
                                                                                            {
                                                                                                fundamento4.map((task, i) => (
                                                                                                    <span className="liDroppableItem" key={i}>
                                                                                                        {task.value}
                                                                                                        <Button close onClick={() => deleteItem(11, i)} />
                                                                                                    </span>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={fund4Alert} msg={msg} autoHide={true} onClose={() => setFun4Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                            <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                            <Row className="mb-3">
                                                                                <Droppable droppableId="item4">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div

                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={{ width: '100%', marginLeft: '29px', maxHeight: '100%', padding: '8px', height: '100%' }}
                                                                                        >
                                                                                            {
                                                                                                item4.map((task, i) => (
                                                                                                    <p className="liDroppableItem" key={i} style={{ width: '24%' }}>
                                                                                                        <span className="mr-2">{task.value}</span>
                                                                                                        <span className="mr-2">({task.definition})</span>
                                                                                                        <span className="mr-1 lbal">{task.puntuacion}</span>
                                                                                                        <Button close onClick={() => deleteItem(12, i)} />
                                                                                                    </p>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={item4Alert} msg={msg} autoHide={true} onClose={() => setIem4Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        }
                                                    </div>


                                                    <hr className="divider" style={{ width: "100%" }} />

                                                    {/*ASEPCTO 3 */}
                                                    <div key={3}>
                                                        {/*HEADER*/}
                                                        <Row className="p-2 ml-2">
                                                            <Row>
                                                                <Col xs={10}>
                                                                    <h4 className="title mb-0">Aspecto de evaluacion 3</h4>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <div className="form-check" style={{ marginTop: '0rem' }}>
                                                                        <label className="form-check-label" >
                                                                            <input type="checkbox" className="form-check-input" checked={showAspect3} onChange={(text) => showAspect(1, text.target.value)} />
                                                                            <span className="form-check-sign"></span> Ocultar
                                                                        </label>
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                        </Row>

                                                        {
                                                            !showAspect3 && <>
                                                                <Row className="p-2 ml-2">
                                                                    <Col xs={10}>
                                                                        <Row>
                                                                            <Col xs={6}>
                                                                                <h5 className="title mb-0">Componente</h5>
                                                                                <small className="lead" style={{ fontSize: '10px' }}>
                                                                                    (Elemento principal de la evaluación deportiva.)
                                                                                    Ej: Evaluación ofensiva
                                                                                </small>
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                    <Col xs={6} className='mb-4 mt-2'>
                                                                        <Droppable droppableId="compn3">
                                                                            {(droppableProvider, snapshot) => (
                                                                                <div
                                                                                    className="ulDroppable"
                                                                                    {...droppableProvider.droppableProps}
                                                                                    ref={droppableProvider.innerRef}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                                >
                                                                                    {
                                                                                        componentItem3.map((task, i) => (
                                                                                            <span className="liDroppableItem" key={i}>
                                                                                                {task.value}
                                                                                                <Button close onClick={() => deleteItem(13, i)} />
                                                                                            </span>))
                                                                                    }
                                                                                    {droppableProvider.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs='6'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail">
                                                                                <h5 className="title mb-0">Fundamentos</h5>
                                                                                <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                            </Label>
                                                                            <Row className="mt-2">
                                                                                <Droppable droppableId="fund5">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div
                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                                        >
                                                                                            {
                                                                                                fundamento5.map((task, i) => (
                                                                                                    <span className="liDroppableItem" key={i}>
                                                                                                        {task.value}
                                                                                                        <Button close onClick={() => deleteItem(14, i)} />
                                                                                                    </span>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={fund5Alert} msg={msg} autoHide={true} onClose={() => setFun5Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                            <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                            <Row className="mb-3">
                                                                                <Droppable droppableId="item5">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div

                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={{ width: '100%', marginLeft: '29px', maxHeight: '100%', padding: '8px', height: '100%' }}
                                                                                        >
                                                                                            {
                                                                                                item5.map((task, i) => (
                                                                                                    <p className="liDroppableItem" key={i} style={{ width: '24%' }}>
                                                                                                        <span className="mr-2">{task.value}</span>
                                                                                                        <span className="mr-2">({task.definition})</span>
                                                                                                        <span className="mr-1 lbal">{task.puntuacion}</span>
                                                                                                        <Button close onClick={() => deleteItem(15, i)} />
                                                                                                    </p>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={item5Alert} msg={msg} autoHide={true} onClose={() => setIem5Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>
                                                                <hr className="divider" style={{ width: "100%" }} />
                                                                <Row>
                                                                    <Col xs='6'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail">
                                                                                <h5 className="title mb-0">Fundamentos</h5>
                                                                                <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                            </Label>
                                                                            <Row className="mt-2">
                                                                                <Droppable droppableId="fund6">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div
                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                                        >
                                                                                            {
                                                                                                fundamento6.map((task, i) => (
                                                                                                    <span className="liDroppableItem" key={i}>
                                                                                                        {task.value}
                                                                                                        <Button close onClick={() => deleteItem(16, i)} />
                                                                                                    </span>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={fund6Alert} msg={msg} autoHide={true} onClose={() => setFun6Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <FormGroup style={{ marginLeft: "50px" }}>
                                                                            <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                            <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                            <Row className="mb-3">
                                                                                <Droppable droppableId="item6">
                                                                                    {(droppableProvider, snapshot) => (
                                                                                        <div

                                                                                            className="ulDroppable"
                                                                                            {...droppableProvider.droppableProps}
                                                                                            ref={droppableProvider.innerRef}
                                                                                            style={{ width: '100%', marginLeft: '29px', maxHeight: '100%', padding: '8px', height: '100%' }}
                                                                                        >
                                                                                            {
                                                                                                item6.map((task, i) => (
                                                                                                    <p className="liDroppableItem" key={i} style={{ width: '24%' }}>
                                                                                                        <span className="mr-2">{task.value}</span>
                                                                                                        <span className="mr-2">({task.definition})</span>
                                                                                                        <span className="mr-1 lbal">{task.puntuacion}</span>
                                                                                                        <Button close onClick={() => deleteItem(17, i)} />
                                                                                                    </p>))
                                                                                            }
                                                                                            {droppableProvider.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <PopupComponent isOpen={item6Alert} msg={msg} autoHide={true} onClose={() => setIem6Alert(false)} />
                                                                            </Row>
                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>
                                                            </>

                                                        }
                                                    </div>


                                                </div>

                                            </div>

                                            <div
                                                label='action'
                                                config={[{ name: previewTitle, active: false }, { name: 'Salvar', active: previewActive ? true : false }, { name: 'Finalizar', active: previewActive ? true : false }]}
                                                handlerButton={(action) => handlerSaveForm(action)}></div>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <Modal show={isOpen} onClose={() => setIsOpen(false)} height={innerHeight} width={innerWidth} left={3}>
                            <Alert
                                className='top'
                                color={alertType}
                                isOpen={visible}
                                toggle={onDismiss}
                            >
                                <h4 className="alert-heading text-white">
                                    Warning
                                </h4>
                                <p className="text-white">
                                    {msg}
                                </p>

                            </Alert>
                            <Row className="p-3">
                                <Col xs={12}>
                                    {
                                        fieldType === 4 ?
                                            <Row className="p-2 ml-2" style={{ alignItems: 'center' }}>
                                                <span className="mr-3">{field}</span>
                                                < Button
                                                    className="btn-success"
                                                    size="sm"
                                                    onClick={() => addItem()}
                                                >
                                                    <span style={{ fontSize: '10px' }}>Add</span>
                                                </Button>
                                            </Row> : field
                                    }

                                    <hr className="divider mt-0" style={{ marginLeft: '-6px' }} />
                                </Col>
                                {
                                    fieldType === 1 && <Col xs={12}>
                                        <FormGroup>
                                            <Select
                                                onChange={(item) => {
                                                    if (item.label === 'Categoría') {
                                                        setFilterCriterialList({ label: 'Categoría', data: categoriaList })
                                                    } else {
                                                        setFilterCriterialList({ label: 'Posición', data: position })
                                                    }
                                                }}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                isDisabled={disableCriterial}
                                                options={[
                                                    { value: "62787bd03d3577c711209dc9", label: "Posición" },
                                                    { value: "62787bf73d3577c711209dca", label: "Categoría" },
                                                ]}
                                                placeholder="Seleccione un criterio de filtrado"
                                            />
                                            <FormFeedback >
                                                Debe seleccionar los criterios de filtrado
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    fieldType === 2 &&

                                    <Col xs={12}>
                                        <Col xs={10}>
                                            <FormGroup>
                                                <Label for="exampleEmail">
                                                    Componente 1
                                                </Label>
                                                <Input
                                                    onBlur={(e) => handlerAddComponent(e, 1)}
                                                    id="compon1"
                                                    name="compon1"
                                                    placeholder="Nombre Componente 1"
                                                    maxLength={25}
                                                    type="text"
                                                />
                                                <FormFeedback >
                                                    Debe seleccionar los criterios de filtrado
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={10}>
                                            <FormGroup>
                                                <Label for="exampleEmail">
                                                    Componente 2
                                                </Label>
                                                <Input
                                                    onBlur={(e) => handlerAddComponent(e, 2)}
                                                    id="compon2"
                                                    name="compon2"
                                                    placeholder="Nombre Componente 2"
                                                    type="text"
                                                    maxLength={25}

                                                />
                                                <FormFeedback >
                                                    Debe seleccionar los criterios de filtrado
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={10}>
                                            <FormGroup >
                                                <Label for="exampleEmail">
                                                    Componente 3
                                                </Label>
                                                <Input
                                                    onBlur={(e) => handlerAddComponent(e, 3)}
                                                    id="compon3"
                                                    name="compon3"
                                                    placeholder="Nombre Componente 3"
                                                    type="text"
                                                    maxLength={25}
                                                />
                                                <FormFeedback >
                                                    Debe seleccionar los criterios de filtrado
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        {
                                            listItemComponentes[0].item.length > 0 ? < Button
                                                className="btn-info"
                                                size="sm"
                                                onClick={() => UpdateDbCollection('component')}
                                            >

                                                <span style={{ fontSize: '10px' }}>Actualizar</span>
                                            </Button> :
                                                < Button
                                                    className="btn-success"
                                                    size="sm"
                                                    onClick={() => handleSaveComponent()}
                                                >

                                                    <span style={{ fontSize: '10px' }}>Salvar</span>
                                                </Button>
                                        }
                                        <hr className="divider" style={{ width: '75%' }} />
                                    </Col>

                                }
                                {
                                    fieldType === 3 &&
                                    <Col xs={12}>
                                        <Col xs={10}>
                                            <FormGroup>
                                                <Label for="exampleEmail">
                                                    Fundamento 1
                                                </Label>
                                                <Input
                                                    onBlur={(item) => handlerAddFundamentos(item, 1)}
                                                    id="fun1"
                                                    name="fun1"
                                                    placeholder="Nombre Fudamento 1"
                                                    type="text"
                                                    maxLength={25}

                                                />
                                                <FormFeedback >
                                                    Debe seleccionar los criterios de filtrado
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={10}>
                                            <FormGroup>
                                                <Label for="exampleEmail">
                                                    Fundamento 2
                                                </Label>
                                                <Input
                                                    onBlur={(item) => handlerAddFundamentos(item, 2)}
                                                    id="fun2"
                                                    name="fun2"
                                                    placeholder="Nombre Fudamento 2"
                                                    type="text"
                                                    maxLength={25}

                                                />
                                                <FormFeedback >
                                                    Debe seleccionar los criterios de filtrado
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        {
                                            listItemFundamentos[0].item.length > 0 ?
                                                <Button
                                                    className="btn-info"
                                                    size="sm"
                                                    onClick={() => UpdateDbCollection('fundamento')}
                                                >

                                                    <span style={{ fontSize: '10px' }}>Actualizar</span>
                                                </Button> :
                                                <Button
                                                    className="btn-success"
                                                    size="sm"
                                                    onClick={() => handlerSaveFundamentos()}
                                                >

                                                    <span style={{ fontSize: '10px' }}>Salvar</span>
                                                </Button>
                                        }
                                        <hr className="divider" style={{ width: '75%' }} />
                                    </Col>
                                }
                                {
                                    fieldType === 4 &&
                                    itemsCollection.map((task, i) => {
                                        return (
                                            <Form key={i}>
                                                <Row className="p-custom" >
                                                    <Col xs={6}>
                                                        <FormGroup>
                                                            <Label for="exampleEmail">
                                                                Concepto
                                                            </Label>
                                                            <Input
                                                                onBlur={(item) => handleOnChangeItem(item, 1)}
                                                                invalid={false}
                                                                id="label"
                                                                name="label"
                                                                placeholder="Concepto"
                                                                type="text"
                                                                maxLength={22}
                                                            />
                                                            <FormFeedback >
                                                                Debe seleccionar los criterios de filtrado
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FormGroup>
                                                            <Label for="sub">
                                                                Definición
                                                            </Label>
                                                            <Input
                                                                onBlur={(item) => handleOnChangeItem(item, 2)}
                                                                invalid={false}
                                                                id="sub"
                                                                name="sub"
                                                                placeholder="Definición"
                                                                type="text"
                                                                maxLength={24}
                                                            />
                                                            <FormFeedback >
                                                                Debe seleccionar los criterios de filtrado
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FormGroup>
                                                            <Label for="exampleEmail" style={{ display: 'flex', flexDirection: 'row' }} >
                                                                Puntuación
                                                                <span id="TooltipExample" style={{ marginLeft: '10px' }} >
                                                                    <i className="tim-icons icon-alert-circle-exc"></i>
                                                                    <UncontrolledTooltip
                                                                        placement="right"
                                                                        target="TooltipExample"

                                                                    >
                                                                        <small className="lead" style={{ fontSize: '10px' }}> Los elementos se utilizan para mostrar la manera en que se presentaran los items y su diseño de calificación</small>
                                                                    </UncontrolledTooltip>
                                                                </span>
                                                            </Label>
                                                            <Select
                                                                onChange={(item) => handleOnChangeItem(item, 3)}
                                                                invalid={false}
                                                                defaultValue={'1'}
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="singleSelect"
                                                                options={optionPuntuacion}
                                                                placeholder="Puntuación"
                                                            />
                                                            <FormFeedback >
                                                                Debe seleccionar los criterios de filtrado
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}></Col>
                                                    < Button
                                                        className="btn-success"
                                                        size="sm"
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        <span style={{ fontSize: '10px' }}>Salvar</span>
                                                    </Button>
                                                    <hr className="divider" style={{ width: '75%' }} />
                                                </Row>
                                            </Form>
                                        )
                                    })
                                }

                                {
                                    fieldType === 5 &&
                                    <Row className="p-custom">
                                        <Col xs={3}>
                                            <FormGroup>
                                                <Label for="exampleEmail" style={{ display: 'flex', flexDirection: 'row' }} >
                                                    Rango: 1 - {rangeSelect}
                                                    <span id="TooltipExample" style={{ marginLeft: '10px' }} >
                                                        <i className="tim-icons icon-alert-circle-exc"></i>
                                                        <UncontrolledTooltip
                                                            placement="right"
                                                            target="TooltipExample"
                                                        >
                                                            <small className="lead" style={{ fontSize: '10px' }}> Los elementos se utilizan para mostrar la manera en que se presentaran los items y su diseño de calificación</small>
                                                        </UncontrolledTooltip>
                                                    </span>
                                                </Label>
                                                <Select
                                                    onChange={(item) => updateRangeSelect(item)}
                                                    value={itemSelected}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="singleSelect"
                                                    options={[
                                                        { value: "2", label: "3" },
                                                        { value: "3", label: "4" },
                                                        { value: "4", label: "5" },
                                                        { value: "5", label: "6" },
                                                        { value: "6", label: "7" },
                                                        { value: "7", label: "8" },
                                                        { value: "8", label: "9" },
                                                        { value: "9", label: "10" },
                                                    ]}
                                                    placeholder="Puntuación"
                                                />
                                                <FormFeedback >
                                                    Debe seleccionar los criterios de filtrado
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <ul className='ulPuntuacion'>
                                                <li className='liPuntuacion'>
                                                    <Input
                                                        onBlur={(item) => updateRangeDetails(item, 0)}
                                                        id='nullValue'
                                                        name='nullValue'
                                                        placeholder="Valor Nulo"
                                                        type="text"
                                                        maxLength={25}
                                                    />
                                                </li>
                                                {
                                                    Array(rangeSelect).fill(null).map((task, i) => {
                                                        return (
                                                            <li key={i} className='liPuntuacion'>
                                                                <Input
                                                                    onBlur={(item) => updateRangeDetails(item, (i + 1))}
                                                                    id={`sub${i}`}
                                                                    name={`sub${i}`}
                                                                    placeholder="Descripción"
                                                                    type="text"
                                                                    maxLength={25}
                                                                />
                                                            </li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </Col>

                                        <Col xs={12}>
                                            <h5>Vista previa</h5>
                                        </Col>
                                        <Col xs={10}>
                                            <StarRating puntuar={rangeSelect} onChange={(index) => updateDetailLabel(index)} />
                                        </Col>
                                        <Col xs={2} className='mb-5'>
                                            <div className="labelPositionDetails">
                                                <span className="text-white" >{details}</span>
                                            </div>
                                        </Col>
                                        < Button
                                            className="btn-success"
                                            size="sm"
                                            onClick={() => handlerSavePuntuacion(1)}
                                        >
                                            <span style={{ fontSize: '10px' }}>Salvar</span>
                                        </Button>
                                        <hr className="divider" style={{ width: '75%' }} />
                                    </Row>
                                }
                                {
                                    fieldType === 6 &&
                                    <Row className="p-custom">
                                        <Col xs={12} className='mb-2'>
                                            <ul className='ulPuntuacion'>
                                                <li className='liPuntuacion' style={{ width: '40%' }}>
                                                    <Input
                                                        id='nullValue'
                                                        name='nullValue'
                                                        placeholder="Criterio verdadero"
                                                        type="text"
                                                        maxLength={25}
                                                        onBlur={(text) => updateValues(true, text)}
                                                    />
                                                </li>

                                                <li className='liPuntuacion' style={{ width: '40%' }}>
                                                    <Input
                                                        id='nullValue'
                                                        name='nullValue'
                                                        placeholder="Criterio falso"
                                                        type="text"
                                                        maxLength={25}
                                                        onBlur={(text) => updateValues(false, text)}
                                                    />
                                                </li>
                                            </ul>
                                        </Col>
                                        < Button
                                            className="btn-success"
                                            size="sm"
                                            onClick={() => handlerSavePuntuacion(2)}
                                        >
                                            <span style={{ fontSize: '10px' }}>Salvar</span>
                                        </Button>
                                        <hr className="divider" style={{ width: '75%' }} />
                                    </Row>
                                }
                            </Row>
                        </Modal>
                        <Modal show={modal2} onClose={() => setIsOpen(false)} height={'40%'} width={'50vw'} left={3}>
                            <Row style={{ padding: '40px' }}>
                                <Row>
                                    <h3>Está seguro que desea Finalizar la creación de evaluación</h3>
                                    <span>Esta acción no salva ningún cambio en el formulario, asegúrese de haber salvado sus cambios antes de finalizar</span>
                                </Row>
                                <Row className="w-100 align-items-center justify-content-center">
                                    <ButtonGroup size="sm">
                                        <Button color="danger" className="mr-3" onClick={() => setModal2(rev => !rev)}>Cancelar</Button>{'  '}

                                        <Button className="btn-success" onClick={() => handlerClearForm()}>Finalizar</Button>
                                    </ButtonGroup>
                                </Row>
                            </Row>
                        </Modal>
                        <Footer fluid />
                    </div>
                </div>
            </React.Fragment>

        </DragDropContext>
    );
}

export default Setting;
