import React from "react";
import { Row } from "reactstrap";
import styled from "styled-components";
const OverlayBack = styled.div`
position: absolute;
    background: rgba(0,0,0,0.4);
    height: 90vh;
    z-index: 9;
    width: 80%;
    margin-left: 279px;
    padding: 78px 30px 30px 280px;
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 75px;
`
const Overlay = () => {

    return (
        <OverlayBack>
            <Row>
                <h3 style={{ color: '#fff' }}>Se deben crear los siguientes elementos antes de crear una evaluacion</h3>
            </Row>
            <div className="row">
                <div className="col-12">
                    <p style={{ color: '#fff' , fontSize:'20px' }}>-- Componentes</p>
                    <p style={{ color: '#fff', fontSize:'20px'  }}>-- Fundamentos</p>
                    <p style={{ color: '#fff', fontSize:'20px'  }}>-- Items</p>
                </div>
            </div>
        </OverlayBack>

    )
}

export default Overlay;