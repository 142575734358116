import axios from 'axios'
import { urlBaseDev, urlBaseLocal } from '../url'

const urlBase = urlBaseDev;

function objToQueryString(obj, url) {
    let params = ''
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
        params = `${urlBase}/${url}`
    } else {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        params = `${urlBase}/${url}?` + keyValuePairs.join('&');
    }

    // console.log('URL DELETE',params)
    return params
}

const get = (url = '', paramsObj = {}) => fetch(objToQueryString(paramsObj, url), {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
}).then((response) => response.json())

const post = (url = '', body = {}, headers = {}) =>
    fetch(`${urlBase}/${url}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers
        }
    }).then((response) => response.json())


const put = (url = '', body = {}, headers = {}) => fetch(`${urlBase}/${url}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
    }
}).then((response) => response.json())


const del = (url = '', headers = {}) => fetch(`${urlBase}/${url}`, {
    method: 'DELETE',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
    }
}).then((response) => response.json())


// AXIOS
const postAxios = (url = '', body = {}, headers = {}) =>
    axios.post(`${urlBase}/${url}`, body, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(function (resp) {
        return resp;
    })
        .catch(function (err) {
            console.error(err.response.data);
            return err.response.data
        });

const getAxios = (url = '', headers = {}) =>
    axios.get(`${urlBase}/${url}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers
        }
    }).then(function (resp) {
        return resp;
    })
        .catch(function (err) {
            console.error(err.response.data);
            return err.response.data
        });
const method = { get, post, put, delete: del, postAxios, getAxios }
export default method