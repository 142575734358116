// import React, { Children } from "react";
// import ReactLoading from "react-loading";
// import styled from "styled-components";
// const Overlay = styled.div`
// position: absolute;
//     background: rgba(0,0,0,0.8);
//     width: 100%;
//     height: 100vh;
//     z-index: 9999;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     backdrop-filter: blur(6px);
// `;
// const Loading = styled.div`
// display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
// `
// const LoadingScreen = ({ type = 'spinningBubbles', active, Children }) => {
//     const [isLoading, setIsLoading] = React.useState(false)
//     React.useEffect(() => {
//         setIsLoading(active)
//     }, [active])
//     if (isLoading)
//         return (
//             <Overlay>
//                 <Loading>
//                     <ReactLoading type={type} color="#fff" />
//                     <h2 style={{ color: '#fff' }} className='text-white mt-3'>Cargando información...</h2>
//                 </Loading>
//             </Overlay>
//         )

//     return (<>{Children}</>)
// }


// export default LoadingScreen;

import React, { useRef } from 'react';
import Loader from './Loader';
import GlobalContext from "redux/context";
import { useMemo } from 'react';
const LoaderContext = React.createContext();

export function LoaderProvider({ children }) {
    const userContext = React.useContext(GlobalContext);
    const { state: { isLoading } } = userContext;
    const value = useMemo(() => {
        return isLoading
    }, [isLoading])
    return (
        <LoaderContext.Provider value={value}>
            {children}
            <Loader active={value} />
        </LoaderContext.Provider>
    );
}

