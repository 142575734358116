import React from "react";
import styled from "styled-components";
import AspectComponent from "components/aspect/Aspectcomponent";
import { Row } from "reactstrap";
const PreviewContainer = styled.div`
border-radius: 20px;
padding: 5px 75px;
margin-bottom: -47px;
`;
const PreviewComponent = ({ aspectModel = [], show, evaluation }) => {
    return (
        <PreviewContainer className={show}>
            <Row className="justify-content-center aligth-item-center">
                <h1>{evaluation}</h1>
            </Row>
            {/* Categoría principal 1 */}
            {
                aspectModel.map((task, i) => {
                    return <AspectComponent data={task} key={i} />
                })
            }

        </PreviewContainer>
    )
}

export default PreviewComponent;