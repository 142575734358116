import React from 'react'
import { Row, Col, Input, FormGroup, Form, Label, } from "reactstrap";
import { FONTS } from 'variables/typography';
import StarRating from "../Raiting/index";

const BooleanElement = ({ name, definicion, details = { trueCriterial: 'Si', falseCriterial: "No" } }) => {

    const [label, setLabel] = React.useState(name || 'Aspecto')

    return (
        <>
            <Row className='mb-3'>
                <Form inline className='w-100'>
                    <Col xs={1}>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                            <Label
                                className="me-sm-2"
                                for="exampleEmail"
                            >
                                <span>°</span>
                            </Label>

                        </FormGroup>
                    </Col>
                    <Col xs={8}>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                            <Label
                                className="me-sm-2"
                                for="examplePassword"
                            >
                                <span className='mr-2'>{label}</span>
                                <span>{definicion}</span>
                            </Label>

                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="checkbox" className="form-check-input" />
                                    <span className="form-check-sign"></span> {details.trueCriterial}
                                </label>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="checkbox" className="form-check-input" />
                                    <span className="form-check-sign"></span> {details.falseCriterial}
                                </label>
                            </div>
                        </FormGroup>
                    </Col>

                </Form>
            </Row>
            <hr className='divider' style={{ width: '75%' }} />
        </>

    )
}
export default BooleanElement;