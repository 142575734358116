import React from 'react'
import { Button, ButtonGroup, Col, Row } from 'reactstrap'
import { FONTS } from 'variables/typography'
import Imgbck from 'components/Background_image/Index'
import background from '../../assets/img/Valores.png'
import NumericElement from './Numeric'
import InputElement from './Input'
import BooleanElement from './Boolean'

const AspectComponent = ({ data = [], showDetails = false }) => {
    // console.log("DATA ", data)
    const [categoriappl, setCategoriappl] = React.useState("Categoria");
    const [list, setList] = React.useState([])
 
    const [modelPuntuacion, setModelPuntucion] = React.useState({
        numeric: {
            details: [],
            rating: 0
        },
        bolean: {}
    })
    React.useEffect(() => {
          console.log('Data ', data)
        if (data.hasOwnProperty("name") > 0) {
            setCategoriappl(data.name)
            // ORDENAR LA DATA 
            let string = []
            let boleand = []
            let numeric = []

            setList(data.content)

            // SET BOLEAN 
            for (let index = 0; index < data.rangeDetails.length; index++) {
                let elem = data.rangeDetails[index];
                if (elem.puntuacionType === 'bolean') {
                    setModelPuntucion(prev => {
                        prev.bolean = elem.Definition
                        return prev
                    })
                } else {
                    let obj =
                        setModelPuntucion(prev => {
                            prev.numeric.rating = elem.puntuacionRange
                            prev.numeric.details = elem.Definition
                            return prev
                        })

                }
            }

        }
    }, [data])

    return (
        <Row className="boxcard mt-3 mb-5">
            <Row>
                <Col className="titlestrech">
                    <h2 className="mb-1" style={{ color: '#000' }}>
                        {categoriappl}
                    </h2>
                    <hr className="titleLineDivider" />
                </Col>

            </Row>
            {
                list.map((task, i) => {
                    return (
                        <Row key={i}>

                            <Col xs='12'>

                                <Row className="p-3 ml-4 justify-content-center align-items-baseline">

                                    <div>
                                        <h4 style={{ color: '#000' }}>
                                            {task.label}
                                        </h4>
                                    </div>

                                    <div>
                                        <Imgbck props={{ background: background }}><span className='labelImbck'>0%</span> </Imgbck>
                                    </div>
                                </Row>
                                {
                                    task.item.map((task, index) => {
                                       // console.log('Type ELemen', task.type)
                                        if (task.type === 'boolean') {
                                            return (
                                                <BooleanElement name={task.label} definicion={task.description} details={modelPuntuacion.bolean} key={index} />
                                            )
                                        }
                                        if (task.type === 'string') {
                                            return (
                                                <InputElement name={task.label} definicion={task.description} key={index} />
                                            )
                                        }

                                        if (task.type === 'numeric') {
                                            return (
                                                <NumericElement key={index} name={task.label} definicion={task.description} ratingtotal={modelPuntuacion.numeric.rating} rangeDetails={modelPuntuacion.numeric.details} type={task.type} percent={task.percent} />
                                            )
                                        }
                                    })
                                }
                            </Col>
                        </Row>)
                })
            }

        </Row >

    )
}

export default AspectComponent