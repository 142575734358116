import React from 'react'
import { Row, Col, Input, FormGroup, } from "reactstrap";
import { FONTS } from 'variables/typography';
import StarRating from "../Raiting/index";

const NumericElement = ({ name, definicion, ratingtotal, rangeDetails = [], type, onChange = () => { }, owner = 0, fundamento = 0 , percent = 0}) => {

    const [ratingDesc, setRatingDesc] = React.useState("Detalle");
    const [label, setLabel] = React.useState(name || 'Aspecto')
    const [definition, setDefinicion] = React.useState(definicion || 'Definición')
    const [rating, setRatingtotal] = React.useState(ratingtotal || 5)
    const [porcent, setPorcent] = React.useState(0)

    const handler = (index) => {
        //console.log('Index ', index)
        let indexArray = index
        rangeDetails.filter((tas) => {
            if (tas.id === indexArray) {
                setRatingDesc(tas.value)
            }
        })

        setPorcent(parseInt(indexArray * rating))
        onChange({
            owner: owner,
            fundamento: fundamento,
            total: indexArray
        })
    }

    React.useEffect(()=>{
    //console.log('ercent ', percent)
    if(percent > 0){
        setPorcent(parseInt(percent * rating))
        rangeDetails.filter((tas) => {
            if (tas.id === percent) {
                setRatingDesc(tas.value)
            }
        })

    }
    },[percent])
    // console.log('Owner ', owner)
    return (
        <>
            <Row className='mb-3'>
                <Col className="centercol">
                    <span>{label}</span>
                </Col>

                <Col className="centercol">
                    <span>{definicion}</span>
                </Col>
                <Col xs={5} className="centercol">
                    <StarRating onChange={(index) => handler(index)} puntuar={ratingtotal} active={percent} />
                </Col>
                <Col xs='1' className="centercol">
                    <span>0%</span>
                </Col>
                <Col className="centercol">
                    <span className="configbutton text-white" style={{ width: '170px', padding: '3px' }}>{ratingDesc}</span>
                </Col>
            </Row>
            <hr className='divider' style={{ width: '75%' }} />
        </>

    )
}
export default NumericElement;