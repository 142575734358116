// import React, { Children } from "react";
// import ReactLoading from "react-loading";
// import styled from "styled-components";
// const Overlay = styled.div`
// position: absolute;
//     background: rgba(0,0,0,0.8);
//     width: 100%;
//     height: 100vh;
//     z-index: 9999;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     backdrop-filter: blur(6px);
// `;
// const Loading = styled.div`
// display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
// `
// const LoadingScreen = ({ type = 'spinningBubbles', active }) => {
//     const [isLoading, setIsLoading] = React.useState(false)
//     React.useEffect(() => {
//    // console.log('LOADING ', active)
//         setIsLoading(active)
//     }, [active])
//     if (isLoading) {
//         return (
//             <Overlay>
//                 <Loading>
//                     <ReactLoading type={type} color="#fff" />
//                     <h2 style={{ color: '#fff' }} className='text-white mt-3'>Cargando información...</h2>
//                 </Loading>
//             </Overlay>
//         )
//     }
//      return null
// }


// export default LoadingScreen;

import React from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";
const Loader = ({ active }) => {
  return active && (
    <LoaderContainer>
      <ReactLoading type={'bars'} color="#fff" width={'5%'} />
    </LoaderContainer>
  );
};


const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
  position: fixed;
  background: rgba(0,0,0,0.9);
  z-index: 99999;
`;


export default Loader;