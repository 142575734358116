import React, { Component } from "react";
import PropTypes from "prop-types";
import {Button, ButtonGroup} from "reactstrap";

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, config, handlerButton },
    } = this;

    let className = "tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active";
    }
    if (label === 'action') {
      return (
        <li className={className}  style={{
          float: 'right',
          marginTop: '-10px'
        }}>
          <ButtonGroup>
            {
              config.map((task, i) => {
                return (
                  < Button
                  disabled={task.active}
                    key={i}
                    className="btn-success"
                    size="sm"
                    onClick={() => handlerButton(i)}
                  >
                    {task.name}
                  </Button>
                )

              })
            }
          </ButtonGroup>
        </li>
      )

    } else {
      return (
        <li className={className} onClick={onClick}>
          {label}
        </li>
      );
    }

  }
}

export default Tab;