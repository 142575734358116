import React from 'react'
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { FONTS } from 'variables/typography'
import Imgbck from 'components/Background_image/Index'
import background from '../../assets/img/Valores.png'
import NumericElement from './Numeric'
import InputElement from './Input'
import BooleanElement from './Boolean'
import StarRating from "../Raiting/index";
import { useStateCallback } from 'hooks/useStateCallback'

const AspectComponentPartido = ({ data = [], showDetails = false, owner = 0, onUpdate = () => { }, puntuacionRange = [] }) => {
    // console.log("DATA ", data)
    const [name, setName] = React.useState("Seleccione atleta");
    const [list, setList] = React.useState([])
    const [ovr, setOvr] = React.useState(0);

    const [modelPuntuacion, setModelPuntucion] = React.useState({
        numeric: {
            details: [],
            rating: 0
        },
    })

    React.useEffect(() => {
        if (puntuacionRange.length > 0) {
            // console.log('untuacion Range ', puntuacionRange)
            let obj = {
                numeric: {
                    details: [],
                    rating: 0
                },
            }
            obj.numeric.rating = puntuacionRange[0].puntuacionRange
            obj.numeric.details = puntuacionRange[0].Definition
            setModelPuntucion(obj)
        }
    }, [puntuacionRange])

    React.useEffect(() => {
        if (data.hasOwnProperty("name")) {
             console.log('NAME ', data.name)
            setName(data.name)
            let newArray = []

            let objpuntuacion = {
                numeric: {
                    details: [],
                    rating: 10
                },
            }
            setModelPuntucion(objpuntuacion)
            let objItem = {
                label: '',
                type: "",
                porcent: 0,
                value: 0
            }

            // console.log('CONTENT ', data)

            for (let index = 0; index < data.content.length; index++) {
                let element = data.content[index];
                objItem.label = element.name
                objItem.type = element.type
                newArray.push(objItem);
                objItem = {
                    label: '',
                    type: "",
                    porcent: 0,
                    value: 0
                }
            }

            //console.log(newArray)
        
            setList(newArray)

        }
    }, [data])

    const calculation = (i) => {
        let wp = ((100 / modelPuntuacion.numeric.rating))
        let total = parseInt(i * wp)

        setOvr(total)
        return total
    }

    const handler = (index, itemIndex) => {
        //console.log('Index ', index)

        let value = index
        let indexArray = itemIndex
        let newList = list
        // console.log('LIST ', list)
        newList.map((e, i) => {
            let percent = calculation(value)
            if (i === indexArray) {
                e.porcent = percent
            }
        })
        setList(newList)
        onUpdate(value, indexArray)
    }

    return (
        <Row className="boxcard mt-3 mb-5">
            <Row>
                <Col className="titlestrech">
                    <h2 className="mb-1" style={{ color: '#000' }}>
                        {name}
                    </h2>
                    <hr className="titleLineDivider" />
                </Col>

            </Row>
            <Row>
                {
                    list.map((task, index) => {
                        // console.log('TASK ', task)
                        if (task.type === 'range') {
                            return (
                                <Col xs='12' key={index}>
                                    <Row className='mb-3' >
                                        <Col className="centercol" xs={3}>
                                            <span>{task.label}</span>
                                        </Col>

                                        <Col xs={6} className="centercol">
                                            <StarRating onChange={(item) => handler(item, index)} puntuar={modelPuntuacion.numeric.rating} active={task.value} />
                                        </Col>
                                        <Col xs='1' className="centercol">
                                            <span>{task.porcent}%</span>
                                        </Col>
                                        <hr className='divider' style={{ width: '75%' }} />
                                    </Row>
                                </Col>
                            )
                        } else {
                            return (
                                <Col xs={4} key={index}>
                                    <Row className='mb-3'>
                                        <Form inline className='w-100'>
                                            <Col xs={1}>
                                                <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                                    <Label
                                                        className="me-sm-2"
                                                        for="exampleEmail"
                                                    >
                                                        <span>°</span>
                                                    </Label>

                                                </FormGroup>
                                            </Col>
                                            <Col xs={6}>
                                                <Label style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <span className='mr-2' style={{ ...FONTS.text, fontSize: 14 }}>{task.label}</span>
                                                </Label>
                                            </Col>
                                            <Col className="centercol" >
                                                <FormGroup className='w-100'>
                                                    <Input
                                                        className='w-100'
                                                        id="compon2"
                                                        name="compon2"
                                                        placeholder=""
                                                        type="number"
                                                        min={0}
                                                        maxLength={25}
                                                        onChange={(e) => handler(e.target.value, index)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Form>
                                    </Row>
                                </Col>
                            )

                        }
                    })
                }
            </Row>
        </Row >

    )
}

export default AspectComponentPartido