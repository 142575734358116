import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
    Button,
    Alert,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Tooltip,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    CardFooter,
    Form,
    FormFeedback,
} from "reactstrap";
import Tabs from "../components/Tab/Tabs";
import CustomizableSelect from "components/customSelect/customSelect";
import Select from "react-select";
import { Droppable, Draggable } from "react-beautiful-dnd";
function Settings(props) {

    const [alertType, setAlertType] = React.useState(null);
    const [msg, setMsg] = React.useState(null)
    const [visible, setVisible] = React.useState(false);
    const [showFilter, setShowFilter] = React.useState(false);
    const [filter1, setFilter1] = React.useState([])
    const [criterialFilter, setCriterialFilter] = React.useState([])
    const [model, setModel] = React.useState({
        clubID: 1,
        criteriaItem: {
            searchFilter: [],
            searchFilterCriterios: []
        }
    })

    const [modelEva, setModelEva] = React.useState(['1'])
    const [modelValidator, setModelValidator] = React.useState({
        componente: false,
        fundamento1: false,
        fundamento2: false
    })

    //DIMENSION searchFilterCriterios
    let obj = {
        aspectName: '',
        searchFilter: [],
        item: []
    }

    //DIMENSION searchFilterCriterios NIVEL 2
    let objFd = {
        fundamento: '',
        items: []
    }

    const addSearchFilter = (item, type) => {
        if (model.criteriaItem.searchFilter.length >= 1 && model.criteriaItem.searchFilter.length <= 2) {
            setModel(prev => {
                if (type === 1) {
                    prev.criteriaItem.searchFilter.splice(0, 1)
                    prev.criteriaItem.searchFilter.push(item.id)
                } else {
                    prev.criteriaItem.searchFilter.splice(1, 1)
                    prev.criteriaItem.searchFilter.push(item.id)
                }
                return prev
            })

        } else {

            setModel(prev => {
                prev.criteriaItem.searchFilter.push(item.id)
                return prev
            })
        }


    }
    const addCriteriaItem = (item, value) => {

        // // ANTES DE AGREGAR UN ITEM VALIDAR QUE LOS CRITERIOS DE FILTROS EXISTAN
        // if (model.criteriaItem.searchFilter.length <= 0) {
        //     setAlertType('warning')
        //     setMsg('Debe seleccionar los criterios de filtrado...')
        //     setVisible(true)
        //     return false
        // }
        // if (model.criteriaItem.searchFilterCriterios.length <= 0) {
        //     setAlertType('warning')
        //     setMsg('Ups..? antes de agregar un nuevo componente favor llenar el formulario existente')
        //     setVisible(true)
        //     return false
        // }

        if (modelEva.length < 3) {
            setModelEva(prev => [...prev, 'y'])
        } else {
            formError({ type: 'warning', msg: 'Cantidad máxima alcanzada' })
        }

    }

    const deleteCriterialItem = (index) => {
        //console.log('INDEXX ', index)
        setModelEva(prev => {
            prev.splice(index, 1)
            return prev
        })
    }
    const formError = (props) => {
        switch (props.type) {
            case 'warning':
                setAlertType(props.type)
                setMsg(props.msg)
                setVisible(true)
                break;

            default:
                break;
        }

        setTimeout(() => {
            setVisible(false)
        }, 1850);
    }
    const addAspectComponent = (item, aspect, field) => {

        if (aspect === 1) {
            //console.log('ITEM ', item)
            switch (field) {
                case 1:
                    // NOMBRE DE COMPONENTE
                    obj.aspectName = item
                    setModel(prev => {
                        prev.criteriaItem.searchFilterCriterios.push(obj)
                        return prev
                    })
                    break;
                case 2:
                    if (model.criteriaItem.searchFilterCriterios.length > 0) {
                        setModel(prev => {
                            prev.criteriaItem.searchFilterCriterios[0].searchFilter = item
                            return prev
                        })
                        setFilter1(item)
                    } else {
                        formError({ type: 'warning', msg: 'Error...? debe seleccionar los criterios de filtrado' })
                    }

                    break;
                case 3:
                    if (model.criteriaItem.searchFilterCriterios.length > 0) {
                        objFd.fundamento = item
                        setModel(prev => {
                            if (prev.criteriaItem.searchFilterCriterios[0].item <= 0) {
                                prev.criteriaItem.searchFilterCriterios[0].item.push(objFd)
                            } else {
                                prev.criteriaItem.searchFilterCriterios[0].item[0].fundamento = item
                            }

                            return prev
                        })
                    } else {
                        formError('warning')
                    }
                    break;
                default:
                    break;
            }


        }
        console.log('MODEL ', model)
    }

    const onDismiss = () => {
        setVisible(false)
    }
    const getListStyle = isDraggingOver => ({
        height: '48px',
        width: '100%'
    });
    
    return (
        <>
            <div className="content">
                <Alert
                    className='top'
                    color={alertType}
                    isOpen={visible}
                    toggle={onDismiss}
                >
                    <h4 className="alert-heading text-white">
                        Warning
                    </h4>
                    <p className="text-white">
                        {msg}
                    </p>

                </Alert>
                <Form>
                    <Row>
                        <Col xs="12">
                            <Tabs>
                                <div label="Agregar Evluacion">
                                    <Row className="p-2 ml-2">
                                        <Col className="col-3">
                                            <h4 className="title mb-0">Criterios de Filtrado</h4>
                                            <small className="lead" style={{ fontSize: '10px' }}>Selecciona la agrupacion de las evaluaciones segun. Categoria, Posicion o edad</small>
                                        </Col>


                                    </Row>

                                    <FormGroup>
                                        <div className="row">
                                            <Droppable droppableId="cdf">
                                                {(droppableProvider, snapshot) => (
                                                    <ul
                                                        {...droppableProvider.droppableProps}
                                                        ref={droppableProvider.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                    >
                                                        {
                                                            criterialFilter.map(task => (<li>Items</li>))
                                                        }
                                                    </ul>
                                                )}
                                            </Droppable>
                                        </div>
                                    </FormGroup>

                                    <hr className="divider" style={{ width: "100%" }} />

                                    <Row>
                                        <Col xs='12'>
                                            <h5>Seleccione los filtros</h5>
                                        </Col>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <Select
                                                    invalid
                                                    onChange={(item) => addAspectComponent(item, 1, 2)}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="singleSelect"
                                                    isMulti
                                                    value={filter1}
                                                    options={[
                                                        { value: "1", label: "Portero" },
                                                        { value: "2", label: "Defensa" },
                                                        { value: "3", label: "Centro" }
                                                    ]}
                                                    placeholder="Seleccione una posicion"
                                                />
                                                <FormFeedback >
                                                    Debe seleccionar los criterios de filtrado
                                                </FormFeedback>
                                            </FormGroup>

                                        </Col>

                                        {
                                            showFilter && <Col xs='3'>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="singleSelect"
                                                    value=''
                                                    options={[
                                                        {
                                                            value: "",
                                                            label: "Single Option",
                                                            isDisabled: true
                                                        },
                                                        { value: "2", label: "Foobar" },
                                                        { value: "3", label: "Is great" }
                                                    ]}
                                                    placeholder="Single Select"
                                                />
                                            </Col>
                                        }

                                    </Row>

                                    <hr className="divider" style={{ width: "100%" }} />

                                    {
                                        modelEva.map((task, i) => {
                                            return (
                                                <div key={i}>
                                                    <FormGroup>
                                                        <Row className="p-2 ml-2">
                                                            <Row>
                                                                <Col>
                                                                    <h4 className="title mb-0">Aspecto de evaluacion</h4>
                                                                </Col>

                                                                {
                                                                    modelEva.length < 3 ? <Button
                                                                        id="TooltipExample"
                                                                        onClick={() => addCriteriaItem()}
                                                                        className="btn-icon btn-success btn-round" size='sm' style={{ marginLeft: '27px', marginTop: '-3px' }}>
                                                                        <i className="tim-icons icon-simple-add"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="right"
                                                                            flip
                                                                            target="TooltipExample"
                                                                        >
                                                                            <small className="lead" style={{ fontSize: '10px' }}> Puede agregar un máximo de 3 Aspectos</small>

                                                                        </UncontrolledTooltip>
                                                                    </Button> : i >= 1 && <Button
                                                                        id="TooltipExample"
                                                                        onClick={() => deleteCriterialItem(i)}
                                                                        className="btn-icon btn-danger btn-round" size='sm' style={{ marginLeft: '27px', marginTop: '-3px' }}>
                                                                        <i className="tim-icons icon-trash-simple"></i>
                                                                    </Button>
                                                                }
                                                            </Row>

                                                        </Row>
                                                    </FormGroup>

                                                    <Row>

                                                        <Col xs={4}>
                                                            <h5 className="title mb-0">Componente</h5>
                                                            <small className="lead" style={{ fontSize: '10px' }}>
                                                                (Elemento principal de la evaluación deportiva.)
                                                                Ej: Evaluación ofensiva
                                                            </small>
                                                        </Col>
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                <span className="form-check-sign"></span> Global
                                                            </label>
                                                        </div>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col xs='3'>
                                                            <FormGroup>
                                                                <Input
                                                                    invalid={modelValidator.componente}
                                                                    type="text"
                                                                    name="email"
                                                                    id="exampleEmail"
                                                                    placeholder="Nombre de componente"
                                                                    onBlur={(text) => addAspectComponent(`${text.target.value}`, 1, 1)}
                                                                />
                                                                <FormFeedback >
                                                                    Debe seleccionar los criterios de filtrado
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>


                                                    <hr className="divider" style={{ width: "100%" }} />

                                                    <Row>
                                                        <Col xs='3'>
                                                            <FormGroup style={{ marginLeft: "50px" }}>
                                                                <Label for="exampleEmail">
                                                                    <h5 className="title mb-0">Fundamentos</h5> <br />
                                                                    <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                </Label>

                                                                <Input
                                                                    type="text"
                                                                    name="fundamento1"
                                                                    id="fundamento1"
                                                                    placeholder=""
                                                                    onBlur={(item) => addAspectComponent(`${item.target.value}`, 1, 3)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="mt-4">
                                                            <ButtonGroup size='sm'>
                                                                <Button className="btn-icon btn-success" color="reddit">
                                                                    <i className="tim-icons icon-simple-add"></i>
                                                                </Button>
                                                                <Button className="btn-icon btn-info" color="reddit">
                                                                    <i className="tim-icons icon-scissors"></i>
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col xs='12'>
                                                            <FormGroup style={{ marginLeft: "50px" }}>
                                                                <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                <Row className="mb-3">
                                                                    <Col>
                                                                        <Input type="text" placeholder="First name" />
                                                                    </Col>
                                                                    <Col>
                                                                        <Input type="text" placeholder="Last name" />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>

                                                        </Col>
                                                    </Row>

                                                    <hr className="divider" style={{ width: "100%" }} />

                                                    <Row>
                                                        <Col xs='3'>
                                                            <FormGroup style={{ marginLeft: "50px" }}>
                                                                <h5 className="title mb-0">Fundamentos</h5> <br />
                                                                <small className="lead" style={{ fontSize: '10px' }}>(Principios a evaluar desde la vista del deporte.) </small>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    id="exampleEmail"
                                                                    placeholder="Enter email"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="mt-4">
                                                            <ButtonGroup size='sm'>
                                                                <Button className="btn-icon btn-success" color="reddit">
                                                                    <i className="tim-icons icon-simple-add"></i>
                                                                </Button>
                                                                <Button className="btn-icon btn-info" color="reddit">
                                                                    <i className="tim-icons icon-scissors"></i>
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col xs='12'>
                                                            <FormGroup style={{ marginLeft: "50px" }}>
                                                                <Label for="exampleEmail" className="font-weight-bold">Item</Label>
                                                                <small className="lead" style={{ fontSize: '10px' }}>(Actividades específicas deportivas a evaluar)</small>
                                                                <Row className="mb-3">
                                                                    <Col>
                                                                        <Input type="text" placeholder="First name" />
                                                                    </Col>
                                                                    <Col>
                                                                        <Input type="text" placeholder="Last name" />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            )
                                        })
                                    }




                                </div>
                                <div label="Actualizar Evaluacion">
                                    After 'while, <em>Crocodile</em>!
                                </div>

                            </Tabs>
                        </Col>
                    </Row>

                </Form>

            </div>
        </>
    );
}

export default Settings;