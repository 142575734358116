import React, { useEffect, useRef } from "react";
import { Button, Col, Row } from "reactstrap";
import styled from "styled-components";

const Modal = styled.div`
position: fixed;
display: block;
display: flex;
flex-wrap: wrap;
justify-content: center;
margin: 0 auto;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
z-index: 9999;
overflow-x: hidden;
background-color: rgba(31, 32, 41, 0.1);
pointer-events: none;
opacity: 0;
transition: opacity 250ms 700ms ease;
${props => props.isOpen && ` pointer-events: auto;
opacity: 1;
transition: all 300ms ease-in-out;`
    }
`;

const ModalWarp = styled.div`
overflow-y: scroll;
overflow-x: hidden;
position: relative;
display: block;
${props => props.width ? `width: ${props.width};` : `width: 25vw;`}
${props => props.height ? `height ${props.height};` : `height: 25%;`}
min-height: 200px;
min-width: 200px;
margin: 0 auto;
${props => props.top > 0 ? `margin-top:${props.top}` : 'margin-top: 20px;'}
${props => props.left > 0 && 'margin-left: 302px;'}
margin-bottom: 20px;
border-radius: 4px;
padding-bottom: 20px;
background-color: #fff;
align-self: center;
box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
opacity: 0;
transform: scale(0.6);
transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
transform: scale(0);
${props => props.isOpen && ` opacity: 1;
transform: scale(1);
transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;`}
`;

const ModalBody = styled.div``

const ModalComponent = ({ modalStyle, children, show, onClose, backdropStyle, height, width }) => {

    return (
        <React.Fragment>
            <Modal style={backdropStyle} isOpen={show}>
                <ModalWarp style={modalStyle} isOpen={show} height={height} width={width}>
                    <Row style={{height:'40px'}}>
                        <Col xs={12}>
                            <Button
                                close
                                onClick={onClose}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '15px',
                                    cursor: 'pointer',
                                    marginTop: '9px',
                                    marginRight: '9px',
                                    marginBottom: '-16px'
                                }}
                            />
                        </Col>
                    </Row>

                    {children}
                </ModalWarp>
            </Modal>
        </React.Fragment>
    );
};

export default ModalComponent;
