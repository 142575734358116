//import Evaluaciones from "views/EvaluacionesTecnicas";
import Settings from 'views/Settings';
import AddEvaluacionScreen from "views/AddEvaluaciones";
import SettingReports from "views/SettingReports"
import AddReports from "views/AddReports"
import Fisicas from "views/Fisicas"

var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  // {
  //   path: "/atleta",
  //   name: "Atletas",
  //   rtlName: "الرموز",
  //   icon: "tim-icons icon-single-02",
  //   component: AtletaDashboard,
  //   layout: "/admin",
  // },
   /* {
    path: "/evaTec",
    name: "Inicio",
    rtlName: "خرائط",
    icon: "tim-icons icon-minimal-right",
    component: Evaluaciones,
    layout: "/admin",
  }, */ 
  {
    name: "Tecnicas",
    rtlName: "خرائط",
    img: true,
    typeImg: true,
    collapse: true,
    state: "test_state",
    views: [{
      path: "/addTec",
      name: "Crear",
      rtlName: "خرائط",
      icon: "tim-icons icon-pencil",
      component: AddEvaluacionScreen,
      layout: "/admin",
    },
    {
      path: "/setting",
      name: "Configurar",
      rtlName: "خرائط",
      icon: "tim-icons icon-settings-gear-63",
      component: Settings,
      layout: "/setting",
    }]
  },
  // {
  //   name: "Fisicas",
  //   rtlName: "خرائط",
  //   img: true,
  //   typeImg: false,
  //   collapse: true,
  //   state: "test_state_2",
  //   views: [{
  //     path: "/download-fisic",
  //     name: "Descargar",
  //     rtlName: "خرائط",
  //     icon: "tim-icons icon-cloud-download-93",
  //     component: Fisicas,
  //     layout: "/admin",
  //   }]
  // },
  {
    name: "Reportes",
    rtlName: "خرائط",
    img: false,
    icon: "tim-icons icon-chart-bar-32",
    collapse: true,
    state: "test_state_3",
    views: [{
      path: "/Addreportes",
      name: "Crear",
      rtlName: "خرائط",
      icon: "tim-icons icon-pencil",
      component: AddReports,
      layout: "/admin",
    },
    {
      path: "/reportes",
      name: "Configurar",
      rtlName: "خرائط",
      icon: "tim-icons icon-settings-gear-63",
      component: SettingReports,
      layout: "/admin",
    }]
  },
  // {
  //   path: "/setting",
  //   name: "Fisicas",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-settings-gear-63",
  //   component: Settings,
  //   layout: "/setting",
  // },

  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: "tim-icons icon-single-02",
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl",
  // },
];
export default routes;