class ValidateDragStart {
    constructor() {
        this.baseSchema = {
            sessionID: function (value) {
                let result = /([0-9][A-Z] || [A-Z][0-9] || [a-z][0-9])\w+/.test(value);
                return !result ? true : false;
            },
            clubID: function (value) {
                return value <= 0;
            },
            promCog: function (value) {
                return value <= 0 && !isNaN(value);
            },
            promOf: function (value) {
                return value <= 0 && !isNaN(value);
            },
            promDef: function (value) {
                return value <= 0 && !isNaN(value);
            },
            ovr: function (value) {
                return value <= 0 && !isNaN(value);
            },
            observacion: function (value) {
                let result = /([A-Z])\w+/.test(value);
                return !result ? true : false;
            },
            date: function (value) {
            console.log('Validatot value ', value)
                let result = /[0-9]{4}[-](0[0-9]|1[0-2])[-](0[0-9]|1[0-9]|2[0-9]|3[0-9])/.test(value);
                return !result ? true : false;
            }
        };
    }
    async Validate(field, value, model) {
        switch (field) {
            case 1:
                let response = { error: false }
                let filterItems = []
                // EXTRAER LA RESPUESTA DEL MODELO 
                for (let index = 0; index < model.length; index++) {
                    let elem = model[index];

                    filterItems = elem.filterCriterial[0].filterItem

                    if (filterItems.indexOf(value) !== -1) {
                        response.error = true
                    }
                }

                return response


            default:
                break;
        }
    }

    async ValidateTecnicaInsertModel(object, validate) {
        let schema = this.baseSchema
        if (validate) {
            var errors = Object.keys(schema).map(function (key) {
                let errorResult = schema[key](object[key])
                if (errorResult) {
                    if (key !== 'observacion') {
                        let msg = { key: key, value: (schema[key](object[key])), success: false }
                        return msg;
                    }
                }
            }).filter(task => task !== undefined)
            // .map(function (key) {
            //     return { error: (key + " is invalid.") };
            // });

            if (errors.length > 0) {
                return errors
                // errors.forEach(function (error) {
                //     console.log(error.message);
                // });
            } else {
                let i = []
                return i
                //console.log("info is valid");
            }
        }
    }
}

export default ValidateDragStart;