import React, { useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

const InputGroup = ({ id = 1, handler = () => { }, label = "" }) => {

    const [value, setValue] = React.useState({
        id: id,
        value: 0,
        textValue: ""
    })
    
    const GetInputChange = (e, type) => {
        if (type) {
            setValue(prev => {
                prev.id = id
                prev.value = e.target.value
                return prev
            })
        }else{
            setValue(prev => {
                prev.id = id
                prev.textValue = e.target.value
                return prev
            })
        }

        handler(value)
    }
    return (
        <FormGroup>
            <Label tag='h5' style={{height: '50px'}}>{label}</Label>
            <Row>
                <Col md={4}>
                    <Input 
                        defaultValue={value.value}
                        type="number"
                        min="0"
                        onBlur={(e) => GetInputChange(e, true)}
                        
                    />
                </Col>
                <Col md={8}>
                    <Input 
                        placeholder={`Ingresa texto aquí`}
                        type="text"
                        onBlur={(e) => GetInputChange(e, false)}
                         
                    />
                </Col>
            </Row>
        </FormGroup>
    )
}

InputGroup.prototype = ({
    id: PropTypes.number.isRequired,
    handler: PropTypes.func.isRequired
})


export default InputGroup;