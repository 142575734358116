import httpClient from '../service/index';
import GetDefinition from "../definition";


class EvaluacionServices {
    constructor() {
        this.response = {
            success: true,
            error: '',
            data: []
        }
    }

    async addComponente(model) {
        try {
            let url = GetDefinition('AddComponente')

            let result = await this.postData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async addFundamento(model) {
        try {
            let url = GetDefinition('AddFundamento')

            let result = await this.postData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en EvaluacionServices ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async addItems(model) {
        try {
            let url = GetDefinition('AddItems')

            let result = await this.postData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async addPuntuacion(model) {
        try {
            let url = GetDefinition('AddPuntuacion')

            let result = await this.postData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async addEvaluacion(model) {
        try {
            let url = GetDefinition('AddEvaluacion')

            let result = await this.postData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddEvaluacionesBase(model) {
        try {
            let url = GetDefinition('AddEvaluacionesBase')

            let result = await this.postData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddEvaluacionesTecnicaInfo(model) {
        try {
            let url = GetDefinition('AddEvaluacionTecnicaInfo')

            let result = await this.postData(url[0], model)

            console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }


    async GetFilterCriterial() {
        try {
            let url = GetDefinition('GetFilterCriterial')

            let result = await this.getData(url[0])

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en EvaluacionServices ', error)
            this.response.error = `Error en el metodo GetFilterCriterial ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetPosition(model) {
        try {
            let url = GetDefinition('GetPosition')

            let result = await this.postData(url[0], model)

            //  console.log('Result BD Position', result)

            return result.message[0];
        } catch (error) {
            console.log('Error en EvaluacionServices Metodo GetPosition', error)
            this.response.error = `Error en el metodo GetPosition ${error}`
            this.response.success = false
            return error;
        }
    }

    async GetCategoria(model) {
        try {
            let url = GetDefinition('GetCategoria')

            let result = await this.postData(url[0], model)

            //console.log('Result BD Categoria', result)

            return result;
        } catch (error) {
            console.log('Error en EvaluacionServices Metodo GetCategoria', error)
            this.response.error = `Error en el metodo GetCategoria ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetCategoriaBySessionID(model) {
        try {
            let url = GetDefinition('GetCategoriaBySession')

            let result = await this.postData(url[0], model)

            //console.log('Result BD Categoria', result)

            return result;
        } catch (error) {
            console.log('Error en EvaluacionServices Metodo GetCategoria', error)
            this.response.error = `Error en el metodo GetCategoria ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async GetImageB64(model) {
        try {
            let url = GetDefinition('GetImgData')

            let result = await this.getDataImg(url[0], model)

            console.log('Result BD Categoria', result)

            return result;
        } catch (error) {
            console.log('Error en EvaluacionServices Metodo GetImageB64', error)
            this.response.error = `Error en el metodo GetImageB64 ${error}`
            this.response.success = false
            return this.response;
        }


    }
    async GetUserByClubID(model) {
        try {
            let url = GetDefinition('GetUserByClubID')

            let result = await this.postData(url[0], model)

            //console.log('Result BD user', result)

            return result;
        } catch (error) {
            console.log('Error en EvaluacionServices Metodo GetCategoria', error)
            this.response.error = `Error en el metodo GetCategoria ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async GetUserByClubSession(model) {
        try {
            let url = GetDefinition('GetUserByClubSession')

            let result = await this.postData(url[0], model)

            //console.log('Result BD user', result)

            return result;
        } catch (error) {
            console.log('Error en EvaluacionServices Metodo GetCategoria', error)
            this.response.error = `Error en el metodo GetCategoria ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetComponente(model) {
        try {
            let url = GetDefinition('GetComponente')

            let result = await this.getData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async GetFundamento(model) {
        try {
            let url = GetDefinition('GetFundamento')

            let result = await this.getData(url[0], model)

            //console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async GetItem(model) {
        try {
            let url = GetDefinition('GetItems')

            let result = await this.getData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service SigninProcess ', error)
            this.response.error = `Error en service SigninProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async GePuntuacion(model) {
        try {
            let url = GetDefinition('GetPuntuacion')

            let result = await this.getData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service GePuntuacion ', error)
            this.response.error = `Error en service GePuntuacion ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetEvaluacion(model) {
        try {
            let url = GetDefinition('GetEvaluacion')

            let result = await this.getData(url[0], model)
            // console.log('AI RESUT ', result)
            return result;

        } catch (error) {
            console.log('Error en service GePuntuacion ', error)
            this.response.error = `Error en service GePuntuacion ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async GetEvaluacionesByDate(model) {
        try {
            let url = GetDefinition('GetEvaluacionByCategory')

            let result = await this.getData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service GePuntuacion ', error)
            this.response.error = `Error en service GePuntuacion ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetEvaluacionesInUser(model) {
        //  console.log('modelGetEva', model)
        try {
            let url = GetDefinition('GetEvaluacionesInUser')
            let result = await this.postData(url[0], model)
            //  console.log('resultEvalu', result)
            return result;
        } catch (error) {
            console.log('Error en service GePuntuacion ', error)
            this.response.error = `Error en service GetEvaluacionInUser ${error}`
            this.response.success = false
            return this.response;
        }

    }

    // Evaluaciones Fisicas

    async GetBringPhysicalsAPI(model) {
        try {
            let url = GetDefinition('MapModelForProcessFront')
            let result = await this.postData(url[0], model);

            return result
        } catch (error) {
            console.log({ MyError: error });
        }
    }

    async GetEvaluacionFisicasForClub(model) {
        try {
            let url = GetDefinition('GetEvaluacionesFisicas')
            let result = await this.postData(url[0], model);

            return result
        } catch (error) {
            console.log({ MyError: error });
        }
    }

    async UpdateCompomonent(model) {
        try {
            let url = GetDefinition('UpdateComponent')

            let result = await this.putData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service GePuntuacion ', error)
            this.response.error = `Error en service GePuntuacion ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async UpdateFundamento(model) {
        try {
            let url = GetDefinition('UpdateFundamento')

            let result = await this.putData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service UpdateFundamento ', error)
            this.response.error = `Error en service UpdateFundamento ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetEvaPartido() {
        try {

            let url = GetDefinition("GetEvaPartido")

            let result = await this.getData(url[0], {})

            return result
        } catch (error) {
            console.log('Error en service GetEvaPartido ', error)
            this.response.error = `Error en service GetEvaPartido ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetTorneoList(model) {
        try {

            let url = GetDefinition("GetTorneoListByClub")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service GetTorneoList ', error)
            this.response.error = `Error en service GetTorneoList ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddTorneo(model) {
        try {

            let url = GetDefinition("AddTorneo")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service AddTorneo ', error)
            this.response.error = `Error en service GetTorneoList ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async AddEvaluacionPartidoClub(model) {
        try {

            let url = GetDefinition("AddEvaluacionPartidoClub")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service AddEvaluacionPartidoClub ', error)
            this.response.error = `Error en service GetTorneoList ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async AddEvaluacionPartidoUser(model) {
        try {

            let url = GetDefinition("AddEvaluacionPartidoUser")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service AddEvaluacionPartidoUser ', error)
            this.response.error = `Error en service GetTorneoList ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async CheckEvaluacionPartidoStatus(model) {

        try {

            let url = GetDefinition("CheckEvaluacionPartido")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service CheckEvaluacionPartidoStatus ', error)
            this.response.error = `Error en service CheckEvaluacionPartidoStatus ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async UpdateStatusPartido(model) {
        try {

            let url = GetDefinition("UpdateStatusPartido")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service UpdateStatusPartido ', error)
            this.response.error = `Error en service UpdateStatusPartido ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async CheckEvaluacionDiagnostica(model) {
        try {

            let url = GetDefinition("CheckEvaluacionDiagnostica")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service CheckEvaluacionDiagnostica ', error)
            this.response.error = `Error en service CheckEvaluacionDiagnostica ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async AddEvaluacionDiagnostica(model) {
        try {

            let url = GetDefinition("AddEvaluacionDiagnostica")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service AddEvaluacionDiagnostica ', error)
            this.response.error = `Error en service AddEvaluacionDiagnostica ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddReport(model) {
        try {

            let url = GetDefinition("AddReports")

            let result = await this.postData(url[0], model)

            return result
        } catch (error) {
            console.log('Error en service UpdateReport ', error)
            this.response.error = `Error en service UpdateReport ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetReportsByClubID(model) {
        try {
            let url = GetDefinition("GetAllReport")
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service UpdateReport ', error)
            this.response.error = `Error en service UpdateReport ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddReportsMain(model) {
        try {
            let url = GetDefinition("AddReportMain")
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service UpdateReport ', error)
            this.response.error = `Error en service UpdateReport ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddReportsDetails(model) {
        try {
            let url = GetDefinition("AddReportDetails")
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service UpdateReport ', error)
            this.response.error = `Error en service UpdateReport ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetReportsEva(model) {
        try {
            let url = GetDefinition("GetReportEvaluation")
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service UpdateReport ', error)
            this.response.error = `Error en service GetReportEvaluation ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetReportsDownload(model) {
        try {
            let url = GetDefinition("GetReportsDownload")
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service UpdateReport ', error)
            this.response.error = `Error en service GetReportEvaluation ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async MapModelForProcessFront(model) {
        try {
            let url = GetDefinition("MapModelForProcessFront")
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service UpdateReport ', error)
            this.response.error = `Error en service GetReportEvaluation ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetAllReportsName(model) {
        try {
            let url = GetDefinition("GetAllReportName")
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service reportsNames ', error)
            this.response.error = `Error en service GetAllReportsName ${error}`
            this.response.success = false
            return this.response;
        }
    }



    postData(url, model) {
        return new Promise((resolve) => {
            httpClient.post(url, model)
                .then((result) => {
                    resolve(result)
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }

    getData(url, req) {
        return new Promise((resolve) => {
            httpClient.get(url, req)
                .then((result) => {
                    //console.log('Result Get Service ', result)
                    if (result.message.length > 0) {
                        resolve(
                            {
                                success: true,
                                error: '',
                                data: result
                            }
                        )
                    } else {
                        resolve(
                            {
                                success: false,
                                error: '',
                                data: []
                            }
                        )
                    }

                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }

    getDataImg(url, req) {
        return new Promise((resolve) => {
            httpClient.get(url, req)
                .then((result) => {
                    // console.log('RESULT ', result)
                    resolve(result)
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }


    putData(url, model) {
        return new Promise((resolve) => {
            httpClient.put(url, model)
                .then((result) => {
                    //console.log('API RESULT ', result)
                    resolve(
                        {
                            success: true,
                            error: '',
                            data: result
                        }
                    )
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }
}


export default EvaluacionServices;
