import React, { useState } from "react";

const StarRating = ({ onChange = () => { }, puntuar, type, active = 0 }) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [selected, setSelected] = useState(0);

    const handler = (index) => {
        //console.log('Index Component ', index)
        let result = 0
        if (selected === index) {
            setRating(index - 1)
            setSelected(index - 1)
            result = index - 1
        } else {
            setRating(index)
            setSelected(index)
            result = index
        }
        onChange(result)
    }

    React.useEffect(() => {
        //console.log('Index Component active', active)
        if (active > 0) {
            setRating(active)
            setSelected(active)
        }
    }, [active])
    return (
        <div className="Sliderstyle">
            {[...Array(puntuar)].map((star, index) => {
                index += 1;
                return (
                    <button
                        type="button"
                        key={index}
                        className={index <= (hover || rating) ? "on sliderbutton" : "off sliderbutton"}
                        onClick={() => handler(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        <span className={index <= (hover || rating) ? "ratingnumbers text-white" : "ratingnumbers"}>{index}</span>
                    </button>
                );
            })}
        </div>
    );
};

export default StarRating;