import React from "react";

function Imgbck({ props, children }) {

  return (
    <div style={{
      backgroundImage: `url(${props?.background})`, 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'contain',
      height: `${props?.height || '45%'}`, 
      marginLeft: '8px', 
      textAlign: 'center', 
      display: 'flex',
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'center'
    }} >
      {children}
    </div>
  );
}

export default Imgbck;