import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./itemTab";

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label,
        };
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const {
            onClickTabItem,
            props: { children },
            state: { activeTab },
        } = this;

        return (
            <div className="tabs">
                <ol className="tab-list">
                    {children.map((child, i) => {
                        const { label, config, handlerButton } = child.props;

                        return (
                            <Tab
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                config={config}
                                handlerButton={handlerButton}
                                onClick={onClickTabItem}
                            />
                        );
                    })}
                </ol>
                <div className="tabs-content">
                    {children.map((child) => {
                        if (child.props.label != activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

export default Tabs;