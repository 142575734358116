
import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import Login from 'components/login/index.js';


import routes from "routes.js";

import logo from "assets/img/logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { CardBody, Card, Row, Col } from "reactstrap";

var ps;

function Auth(props) {
  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div></div>
          <Login />
          <div></div>
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Auth;
