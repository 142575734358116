import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Alert,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Card,
  Col,
  CardBody
} from "reactstrap";
import styled from 'styled-components'
import GlobalContext from "redux/context";
import { useStateCallback } from "hooks/useStateCallback";
import uuid from "react-uuid";
import Manager from '../../api/main/EvaluacionServices'
import {
  useQuery
} from 'react-query'

const Label = styled.div`
background: #fff;
display: flex;
align-items: center;
justify-content: center;
justify-content: space-between;
border-radius: 8px;
`
const api = new Manager()

function AdminNavbar(props) {
  const userContext = React.useContext(GlobalContext);
  const { state, getCurrentEvaluacion, getInitialData, addCurrentReport } = userContext;
  const { clubCategori } = state;
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(true);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [showAlert, setShowAlert] = React.useState(false);
  const [msg, setMsg] = React.useState('Esta opción no se encuentra activa en esta versión..')
  const [selectedItem, setSelectedItem] = React.useState({})
  const [categoryList, setCategoryList] = useStateCallback([])
  const [evaSelect, setEvaSelect] = React.useState('Seleccionar evaluación')
  const [reportSelect, setReportSelect] = React.useState('Seleccionar un reporte')
  const [location, setLocation] = React.useState(0)
  const [need, setNeed] = React.useState(1)
  const [disabled, setDisabled] = React.useState(false);



  //CARGA INICIAL

  const handlerQuery = async () => {
    let result = await api.GetAllReportsName({})
    return { reportsName: result.message }
  }

  const { data, isLoading, isSuccess } = useQuery('ReportsName', () => handlerQuery(), {
    refetchOnWindowFocus: false
  })

  React.useEffect(() => {
    setNeed(1)
    if (window.location.pathname === '/admin/addTec' || window.location.pathname === '/admin/download-fisic') {
      setLocation(1)

      // console.log('location', location)
    }

    if (window.location.pathname === '/setting/setting') {
      setNeed(0)
    }

    if (window.location.pathname === '/admin/reportes') {
      setLocation(2)
      //  console.log('location', location)
    }

    if (window.location.pathname === '/admin/Addreportes') {
      setLocation(3)
      //
      // console.log('location', location)
    }
  });

  React.useEffect(() => {

    let userExists = JSON.parse(window.localStorage.getItem('user-exists'))
    let categori = 0
    // console.log('FILTER CAT FILTER LIST ', clubCategori)
    // VALIDAMOS SI ES CLUB O COUCH 
    if (userExists.data.isSuper || userExists.data.isClub) {
      let currentCategori = window.localStorage.getItem('currentCategori')

      if (currentCategori === null || currentCategori === undefined || currentCategori === 'undefined') {
        categori = userExists.data.categoryID
        //console.log('Cat')
        setSelectedItem(categori[0])
        window.localStorage.setItem('currentCategori', JSON.stringify(categori[0]))
      } else {
        currentCategori = JSON.parse(currentCategori)
        setSelectedItem(currentCategori)
        window.localStorage.setItem('currentCategori', JSON.stringify(currentCategori))
      }
      setCategoryList(clubCategori, (state) => console.log(state))
    } else {
      window.localStorage.removeItem('currentCategori')
      window.localStorage.setItem('currentCategori', JSON.stringify(userExists.data.categoryID))
      categori = clubCategori.filter((task) => {
        if (task.categoriesID === userExists.data.categoryID) return task
      })

      // console.log('CATEGORIE FILTER MODEL ', categori)
      //setCategoryList(clubCategori, (state) => console.log(state))
      setSelectedItem(categori[0])
      setDisabled(true)
    }

  }, [clubCategori])



  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(modalSearch => !modalSearch);
  };

  const updateCurrentEvaluaciones = async (e) => {
    if (e === "Técnica") {
      // console.log('EVALUACION TECNICA ')
      let user = JSON.parse(window.localStorage.getItem('user-exists'))
      let model = {
        clubID: user.lt,
        evaluationType: e
      }

      let result = await getCurrentEvaluacion(model, true)

      if (result === undefined) {
        setMsg(`Lo sentimos aún no se a creado ninguna evaluación técnia...`)
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2200);
      }

    } else {
      let model = {
        evaluacionType: "Partido"
      }

      let result = await getCurrentEvaluacion(model, false)
    }

  }

  const ChangeCategoryData = async (item) => {
    //console.log('Item CHange ', item)
    let categori = categoryList.filter((task) => {
      if (task.categoriesID === item) return task
    })
    setSelectedItem(categori[0])
    window.localStorage.removeItem('currentCategori')
    window.localStorage.setItem('currentCategori', JSON.stringify(categori[0]))

    let userToken = JSON.parse(window.localStorage.getItem('user-exists'))
    let model = {
      "sessionID": userToken.lt,
      "categoryID": categori[0].categoriesID,
      "startDate": 0,
      "endDate": 0
    }
    let result = await getInitialData(model)

  }

  const addCurrentReports = async (e) => {
    await addCurrentReport(e)
  }

  if (isLoading) {
    return (
      <div>...cargando</div>
    )
  }

  if (isSuccess) {
    const { reportsName } = data
    return (
      <>
        <Navbar className={classNames("navbar-absolute fixed-top-stick", color)} expand="lg">
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: props.sidebarOpened,
                })}
              >
                <NavbarToggler onClick={props.toggleSidebar}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </NavbarToggler>
              </div>
            </div>
            <NavbarToggler onClick={toggleCollapse}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>
            <Collapse navbar isOpen={collapseOpen}>
              <Nav className="ml-auto" navbar>
                {
                  showAlert && <Row className="alertNavBar">
                    <span>{msg}</span>
                  </Row>
                }

                {
                  location === 2 &&
                  <UncontrolledDropdown nav style={{ opacity: need }}>
                    <DropdownToggle
                      style={{ width: "234px" }}
                      color="default"
                      nav
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="row">
                        <div className="" style={{ marginRight: "17px" }}>
                          <img
                            alt="..."
                            src={require("../../assets/img/anime3.png")}
                            style={{ width: "30px" }}
                          />
                        </div>
                        <div className="row align-items-center">
                          <p className="">{reportSelect} </p>
                          <b className="caret d-none d-lg-block d-xl-block" />
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                      {
                        reportsName.map((task) => {
                          console.log('task filter', task)
                          return (
                            <NavLink tag="li" key={uuid()} onClick={() => addCurrentReports(task)}>
                              <DropdownItem className="nav-item" value={task.id}>{task.value}</DropdownItem>
                            </NavLink>
                          )
                        })
                      }

                    </DropdownMenu>
                  </UncontrolledDropdown>
                }

                {
                  location === 3 &&
                  <UncontrolledDropdown nav style={{ opacity: need }}>
                    <DropdownToggle
                      style={{ width: "234px" }}
                      color="default"
                      nav
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="row">
                        <div className="" style={{ marginRight: "17px" }}>
                          <img
                            alt="..."
                            src={require("../../assets/img/anime3.png")}
                            style={{ width: "30px" }}
                          />
                        </div>
                        <div className="row align-items-center">
                          <p className="">{reportSelect} </p>
                          <b className="caret d-none d-lg-block d-xl-block" />
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                      {
                        reportsName.map((task) => {
                          //console.log('task filter', task)
                          return (
                            <NavLink tag="li" key={uuid()} onClick={() => addCurrentReports(task)}>
                              <DropdownItem className="nav-item" value={task.id}>{task.value}</DropdownItem>
                            </NavLink>
                          )
                        })
                      }

                    </DropdownMenu>
                  </UncontrolledDropdown>
                }
                {
                  location === 1 &&
                  <UncontrolledDropdown nav style={{ opacity: need }}>
                    <DropdownToggle
                      style={{ width: "234px" }}
                      color="default"
                      nav
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="row">
                        <div className="" style={{ marginRight: "17px" }}>
                          <img
                            alt="..."
                            src={require("../../assets/img/anime3.png")}
                            style={{ width: "30px" }}
                          />
                        </div>
                        <div className="row align-items-center">
                          <p className="">{evaSelect} </p>
                          <b className="caret d-none d-lg-block d-xl-block" />
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                      <NavLink tag="li" onClick={() => updateCurrentEvaluaciones('Técnica')}>
                        <DropdownItem className="nav-item">Técnica</DropdownItem>
                      </NavLink>
                      <NavLink tag="li" onClick={() => updateCurrentEvaluaciones('Competencia')}>
                        <DropdownItem className="nav-item">Competencia</DropdownItem>
                      </NavLink>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                }

                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>

        </Navbar>

      </>
    );
  }




}

export default AdminNavbar;