import React from 'react'
import { Button, ButtonGroup, Col, Row } from 'reactstrap'
import { FONTS } from 'variables/typography'
import Imgbck from 'components/Background_image/Index'
import background from '../../assets/img/Valores.png'
import NumericElement from './Numeric'
import InputElement from './Input'
import BooleanElement from './Boolean'
import StarRating from "../Raiting/index";
import { useStateCallback } from 'hooks/useStateCallback'

const AspectComponentAdd = ({ data = [], showDetails = false, owner = 0, onUpdate = () => { }, puntuacionRange = [] }) => {
    // console.log("DATA ", data)
    const [categoriappl, setCategoriappl] = React.useState("");
    const [list, setList] = React.useState([])
    const [ovr, setOvr] = React.useState(0);
    const [ovrFundamento, setOvrFundamento] = useStateCallback(0);
    const [ovrFundamento1, setOvrFundamento1] = useStateCallback(0);
    const [totalLenFundamento, setTotalLenFundamento] = React.useState(0);
    const [totalLenFundamento1, setTotalLenFundamento1] = React.useState(0);
    const [label, setLabel] = React.useState('Aspecto')
    const [definition, setDefinicion] = React.useState('')
    const [rating, setRatingtotal] = React.useState(5)
    const [porcent, setPorcent] = React.useState(0)
    const [modelPuntuacion, setModelPuntucion] = React.useState({
        numeric: {
            details: [],
            rating: 0
        },
    })

    React.useEffect(() => {
        if (puntuacionRange.length > 0) {
           // console.log('untuacion Range ', puntuacionRange)
            let obj = {
                numeric: {
                    details: [],
                    rating: 0
                },
            }
            obj.numeric.rating = puntuacionRange[0].puntuacionRange
            obj.numeric.details = puntuacionRange[0].Definition
            setModelPuntucion(obj)
        }
    }, [puntuacionRange])

    React.useEffect(() => {
        // console.log('Data ', data)
        if (data.hasOwnProperty("name") > 0) {
            if(data.name != ""){
                setCategoriappl(data.name)
                // ORDENAR LA DATA 
                let string = []
                let boleand = []
                let numeric = []
                // console.log('Data ', data)
                let newArray = []
                let obj = {
                    owner: data.name,
                    name: "",
                    ovr: 0,
                    item: []
                }
                let objItem = {
                    label: '',
                    definicion: '',
                    ratingDesc: "Sin detalle",
                    rating: '',
                    porcent: 0,
                    value: 0
                }
                let objpuntuacion = {
                    numeric: {
                        details: [],
                        rating: 0
                    },
                }
                objpuntuacion.numeric.rating = puntuacionRange[0].puntuacionRange
                objpuntuacion.numeric.details = puntuacionRange[0].Definition
                setModelPuntucion(objpuntuacion)
                //console.log('OBJEC PUNTATION ', objpuntuacion)
                data.content.filter((task, i) => {
                    let ovrCal = 0
                    let cal = 0
                    obj.name = task.label
                    task.item.filter((e) => {
                        objItem.label = e.label
                        objItem.definicion = e.description
                        objItem.rating = objpuntuacion.numeric.rating
                        objItem.porcent = e.percent
                        objItem.value = e.value
                        ovrCal = Math.round(ovrCal + e.percent)
                        objpuntuacion.numeric.details.filter((tasks) => {
                            // console.log('details ', tasks)
                            if (tasks.id === e.value) {
                                objItem.ratingDesc = tasks.value
                            }
                        })
                        obj.item.push(objItem)
                        objItem = {
                            label: '',
                            definicion: '',
                            ratingDesc: "Sin detalle",
                            rating: '',
                            porcent: 0
                        }
                       
                    })
                    cal = Math.round(ovrCal)
                    obj.ovr = Math.round(cal / data.content[i].item.length)
                    //console.log('OVR ', ovrCal)
                    newArray.push(obj)
                    obj = {
                        owner: data.name,
                        name: "",
                        ovr: 0,
                        item: []
                    }
                })
    
                console.log(newArray)
                setList(newArray)
    
                // SET BOLEAN 
                for (let index = 0; index < data.rangeDetails.length; index++) {
                    let elem = data.rangeDetails[index];
                    if (elem.puntuacionType === 'bolean') {
                        setModelPuntucion(prev => {
                            prev.bolean = elem.Definition
                            return prev
                        })
                    } else {
                        let obj =
                            setModelPuntucion(prev => {
                                prev.numeric.rating = elem.puntuacionRange
                                prev.numeric.details = elem.Definition
                                return prev
                            })
    
                    }
                }
    
                
                setTotalLenFundamento(data.content[0].item.length);
                setTotalLenFundamento1(data.content[1].item.length );
    
            }  
        }
    }, [data]);


    const calculation = (i) => {
        let wp = ((100 / modelPuntuacion.numeric.rating))
        let total = parseInt(i * wp)

        setOvr(total)
        return total
    }

    const handler = (index, itemIndex, fundamento) => {
        console.log('Index ', index)
        let indexArray = index
        let fun = 0
        let ratingDesc = ''
        
        //console.log('MODEL PUNTATION ', modelPuntuacion)
        modelPuntuacion.numeric.details.filter((tas) => {
            if (tas.id === indexArray) {
                ratingDesc = tas.value
            }
        })

        let newList = [...list]
        
        newList.map((e, i) => {
            if (e.name === fundamento) {
                let percent = calculation(indexArray)
                e.item.map((task, index) => {
                    if (index === itemIndex) {
                        task.ratingDesc = ratingDesc
                        task.porcent = percent
                    }
                })
            }
        })

        //CHECKED ITEMS
        let totalCheckedItemFn1 = 0
        let totalCheckedItemFn2 = 0

        for (let index = 0; index < newList.length; index++) {
            let element = newList[index];
            if (index === 0) {
                element.item.filter((task) => {
                    if (task.porcent > 0) {
                        totalCheckedItemFn1++
                    }
                })
            } else {
                element.item.filter((task) => {
                    if (task.porcent > 0) {
                        totalCheckedItemFn2++
                    }
                })
            }
        }
        newList.map((task, i) => {
            let totalPercent = 0
            task.item.filter((i, l) => {
                totalPercent = totalPercent + i.porcent
            })
            fun = i + 1
            if (i === 0) {
                task.ovr = isNaN((Math.round(totalPercent / totalCheckedItemFn1))) ? 0 : (Math.round(totalPercent / totalCheckedItemFn1))
            } else {
                task.ovr = isNaN((Math.round(totalPercent / totalCheckedItemFn2))) ? 0 : (Math.round(totalPercent / totalCheckedItemFn2))
            }
        })

        //console.log('Nw List ', newList)
        setList(newList)
        let result = calculation(indexArray)
        setPorcent(result)
        console.log('owner',owner)
        console.log('fun',fun)
        console.log('indexArray',indexArray)
        console.log('newList',newList)
        onUpdate(owner, fun, indexArray, newList)

    }

    return (
        <Row className="boxcard mt-3 mb-5">
            <Row>
                <Col className="titlestrech">
                    <h2 className="mb-1" style={{ color: '#000' }}>
                        {categoriappl}
                    </h2>
                    <hr className="titleLineDivider" />
                </Col>

            </Row>
            {
                list.map((e, i) => {
                    return (
                        <Row key={i}>
                            <Col xs='12'>
                                <Row className="p-3 ml-4 justify-content-center align-items-baseline">
                                    <div>
                                        <h4 style={{ color: '#000' }}>
                                            {e.name}
                                        </h4>
                                    </div>
                                    <div>
                                        <Imgbck props={{ background: background }}><span className='labelImbck'>{e.ovr}%</span> </Imgbck>
                                    </div>
                                </Row>
                                {
                                    e.item.map((task, index) => {
                                        return (
                                            <Row className='mb-3' key={index}>
                                                <Col className="centercol">
                                                    <span>{task.label}</span>
                                                </Col>

                                                <Col className="centercol">
                                                    <span>{task.definicion}</span>
                                                </Col>
                                                <Col xs={5} className="centercol">
                                                    <StarRating onChange={(item) => handler(item, index, e.name, i)} puntuar={modelPuntuacion.numeric.rating} active={task.value} />
                                                </Col>
                                                <Col xs='1' className="centercol">
                                                    <span>{task.porcent}%</span>
                                                </Col>
                                                <Col className="centercol">
                                                    <span className="configbutton text-white" style={{ width: '170px', padding: '3px' }}>{task.ratingDesc}</span>
                                                </Col>
                                                <hr className='divider' style={{ width: '75%' }} />
                                            </Row>

                                        )
                                    })
                                }
                            </Col>
                        </Row>)
                })
            }

        </Row >

    )
}

export default AspectComponentAdd