import React from 'react'
import { Row, Col, Input, FormGroup, Form, Label, } from "reactstrap";
import { FONTS } from 'variables/typography';
import StarRating from "../Raiting/index";

const InputElement = ({ name, definicion, ratingtotal, rangeDetails = [], type , index }) => {

  const [ratingDesc, setRatingDesc] = React.useState("Detalle");
  const [label, setLabel] = React.useState(name || 'Aspecto')
  const [definition, setDefinicion] = React.useState(definicion || 'Definición')
  const [rating, setRatingtotal] = React.useState(ratingtotal || 5)
  const [porcent, setPorcent] = React.useState(0)


  const handler = (index) => {
    //console.log('Index ', index)
    let indexArray = index
    rangeDetails.filter((tas) => {
      if (tas.id === indexArray) {
        setRatingDesc(tas.value)
      }
    })
  }


  return (
    <>
      <Row className='mb-3'>
        <Form inline className='w-100'>
          <Col xs={1}>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label
                className="me-sm-2"
                for="exampleEmail"
              >
                <span>°</span>
              </Label>

            </FormGroup>
          </Col>
          <Col xs={4}>
            <Label style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <span className='mr-2' style={{ ...FONTS.text }}>{label}</span>
              <span>{definicion}</span>
            </Label>
          </Col>
          <Col className="centercol" >
            <FormGroup className='w-100'>
              <Input
                className='w-100'
                id="compon2"
                name="compon2"
                placeholder="Nombre Componente 2"
                type="text"
                maxLength={25}

              />
            </FormGroup>
          </Col>
        </Form>
      </Row>
      <hr className='divider' style={{ width: '75%' }} />
    </>

  )

}
export default InputElement