import Manager from '../../api/main/EvaluacionServices';
const api = new Manager();

class EvaluacionesAPI {
    constructor() {
        this.response = {
            success: true,
            error: '',
            data: []
        }
    }

    async GetCurrentEvaluacion(model) {
        try {
            let result = await api.GetCurrentEvaluacion(model)
            //console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async GetBringPhysicals(model) {
        try {
            let result = await api.GetBringPhysicalsAPI(model)
            //console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }

    async GetCheckEvaluacionesInUser(model) {
       // console.log('modelCheck', model)
        try {
            
            let result = await api.GetEvaluacionesInUser(model)
         //   console.log('RESULT ', result)
            return result;
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
 
    async GetImage(model) {
        try {
            let result = await api.GetImageB64(model)
            // console.log('RESULT ', result)
            return result
        } catch (error) {
            console.log('Error ', error)
            return error
        }
    }
}

export default EvaluacionesAPI;