
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import AuthLayout from "layouts/Auth/Auth.js";
import SettingLayout from 'layouts/Setting/Setting.js';

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import GlobalProvider from './redux/state/index';
import { Toaster } from 'react-hot-toast';
import { LoaderProvider } from "components/Loading";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import { ReactQueryDevtools } from 'react-query/devtools'
// Create a client
const queryClient = new QueryClient()

ReactDOM.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
        <GlobalProvider>
        <QueryClientProvider client={queryClient}>
          <LoaderProvider >
            <Switch>
              <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
              <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
              <Route path="/setting" render={(props) => <SettingLayout {...props} />} />
              <Redirect from="/" to="/auth" />
            </Switch>
            <Toaster />
          </LoaderProvider>
           <ReactQueryDevtools position="top-left"/> 
          </QueryClientProvider>
        </GlobalProvider>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
