const GetDefinition = (e) => {
    let module = [{
        link: 'AddComponente',
        redirect: "main/addComponente"
    }, {
        link: 'AddFundamento',
        redirect: "main/addFundamento"
    }, {
        link: 'AddItems',
        redirect: "main/AddItems"
    }, {
        link: 'AddPuntuacion',
        redirect: "main/AddPuntuacion"
    }, {
        link: 'GetFilterCriterial',
        redirect: 'main/GetFilterCriterial'
    }, {
        link: 'GetPosition',
        redirect: 'main/GetPosition'
    },
    {
        link: 'GetCategoria',
        redirect: 'main/GetCategoria'
    }, {
        link: 'GetComponente',
        redirect: 'main/GetComponentes'
    }, {
        link: 'GetFundamento',
        redirect: 'main/GetFundamentos'
    }, {
        link: 'GetItems',
        redirect: 'main/GetItems'
    }, {
        link: 'GetPuntuacion',
        redirect: 'main/GetPuntuacion'
    }, {
        link: 'UpdateComponent',
        redirect: 'main/UpdateComponent'
    },
    {
        link: 'AddEvaluacion',
        redirect: 'main/AddEvaluacion'
    }, {
        link: 'GetEvaluacion',
        redirect: 'main/GetEvaluacion'
    }, {
        link: 'GetUserByClubID',
        redirect: 'main/GetUserByClubID'
    }, {
        link: 'AddEvaluacionesBase',
        redirect: 'main/AddEvaluacionTecnicaBase'
    }, {
        link: 'AddEvaluacionTecnicaInfo',
        redirect: 'main/AddEvaluacionTecnicaInfo'
    }, {
        link: 'CheckAccess',
        redirect: 'auth/validateAccess'
    }, {
        link: "GetEvaluacionByCategory",
        redirect: "main/GetEvaluacionByCategory"
    }, {
        link: 'UpdateFundamento',
        redirect: "main/UpdateFundamento"
    }, {
        link: 'GetUserByClubSession',
        redirect: "main/GetUserByClubSession"
    }, {
        link: "GetCategoriaBySession",
        redirect: "main/GetCategoriaBySession"
    }, {
        link: 'GetImgData',
        redirect: "main/image-proxy"
    },
    {
        link: 'GetEvaluacionesInUser',
        redirect: "main/GetCheckEvaluacionesInUser"

    }, {
        link: 'GetEvaPartido',
        redirect: "main/GetEvaluacionPartido"
    }, {
        link: "GetTorneoListByClub",
        redirect: "main/GetTorneoListByClubID"
    }, {
        link: "AddEvaluacionPartidoClub",
        redirect: "main/AddEvaluacionPartidoClub"
    }, {
        link: "AddEvaluacionPartidoUser",
        redirect: "main/AddEvaluacionPartidoUser"
    }, {
        link: "AddTorneo",
        redirect: "main/AddTorneo"
    }, {
        link: "CheckEvaluacionPartido",
        redirect: "main/CheckEvaluacionPartido"
    }, {
        link: "UpdateStatusPartido",
        redirect: "main/UpdateStatusPartido"
    }, {
        link: "CheckEvaluacionDiagnostica",
        redirect: "main/CheckStartEvaluacionTecnica"
    }, {
        link: "AddEvaluacionDiagnostica",
        redirect: "main/AddNewStartPoint"
    }, {
        link: "AddReports",
        redirect: "main/AddReports"
    }, {
        link: "GetAllReport",
        redirect: "main/getAllReports"
    }, {
        link: "AddReportMain",
        redirect: "main/addReportMain"
    }, {
        link: "AddReportDetails",
        redirect: "main/addReportsDetails"
    }, {
        link: "GetReportEvaluation",
        redirect: "main/getReportsEvaluation"
    }, {
        link: "MapModelForProcessFront",
        redirect: "main/mapModelDataForProcess"
    }, {
        link: "GetReportsDownload",
        redirect: "main/getReportsDownload"
    }, {
        link: "GetEvaluacionesFisicas",
        redirect: "main/getEvaluacionesFisicas"
    },{
        link:"GetAllReportName",
        redirect:"main/getReportsName"
    }
    ]

    let info = module.map(task => {
        if (task.link === e) {
            return task.redirect
        }
        return undefined
    }).filter(task => task !== undefined)

    return info;
}


export default GetDefinition;