import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import moment from "moment";
import * as XLSX from 'xlsx'
import axios from 'axios';

class HelperValidation {
    constructor() { }
    emailValid(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async dowloadpdf(refElemt, nombre) {

        let name = nombre
        let blod1 = refElemt.children[0]
        let blod2 = refElemt.children[1]

        const pdf = new jsPDF("p", "mm", "letter", false);

        const canvas = await html2canvas(blod1);
        const data = canvas.toDataURL('image/png');
        let date = moment.utc(new Date()).format('YYYY-MM-DD');
        let filename = `report_${name}_${date}.pdf`;

        const canvas2 = await html2canvas(blod2);
        const data2 = canvas2.toDataURL('image/png');

        const imgProperties = pdf.getImageProperties(data);

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (pdf.internal.pageSize.getHeight()) //(((imgProperties.height * pdfWidth) / imgProperties.width));
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.addPage();
        pdf.addImage(data2, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(filename);

    };

    dowloadexcel(model, name) {
        // console.log('model',model)
        let nombre = name
        let ws = XLSX.utils.json_to_sheet(model);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, name);
        XLSX.writeFile(wb, nombre + '.xlsx');
        //console.log('ws', ws, 'wb', wb);
    }

    getImgBase64(url) {
        return new Promise(async (resolve) => {
            let result = await axios.get(url, { responseType: 'arraybuffer' })
            let returnedB64 = Buffer.from(result.data).toString('base64');
            resolve(returnedB64)
        })
    }

}



export default HelperValidation