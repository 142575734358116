export default (state, action) => {
    const { type, payload } = action;
    //console.log('PAYLOAD ',payload)
    switch (type) {
        case "FETCHING_INITIAL_DATA":
            return {
                ...state,
                isFetching: true
            }
        case 'FETCHING_INITIAL_DATA_FAILURE':
            return {
                ...state,
                isFetching: false
            }

        case "FETCHING_GET_FILTER_CRITERIAL":
            return {
                ...state,
                filterCriterial: payload,
                isFetching: false
            }
        case "FETCHING_GET_POSITION":
            return {
                ...state,
                position: payload,
                isFetching: false
            }
        case "FETCHING_GET_CATEGORIA":

            return {
                ...state,
                categoriaList: payload,
                isFetching: false
            }
        case "FETCHING_GET_USER_BYID":
            return {
                ...state,
                userList: payload,
                isFetching: false
            }
        case "FETCHING_GET_COMPONENT":
            return {
                ...state,
                componentes: payload,
                isFetching: false
            }

        case "FETCHING_GET_FUNDAMENTO":
            return {
                ...state,
                fundamentos: payload,
                isFetching: false
            }
        case "FETCHING_GET_ITEM":
            return {
                ...state,
                items: payload,
                isFetching: false
            }
        case "FETCHING_GET_PUNTUACION":
            return {
                ...state,
                puntuacion: payload,
                isFetching: false
            }
        case "FETCHING_GET_EVALUACION":
            // console.log('PAY LOAD ', payload)
            return {
                ...state,
                evaluaciones: payload,
                currentFilterCritrial: payload[0].filterSelected,
                isFetching: false
            }
        case "FETCHING_GET_EVALUACION_CURRENT":
            return {
                ...state,
                currentEvaluacion: payload,
                currentFilterCritrial: payload[0].filterSelected,
                isFetching: false
            }
        case "FETCHING_GET_USER_INFO":
            return {
                ...state,
                userInfo: payload,
                isFetching: false
            }
        case "FETCHING_GET_FISICAL_TEST":
            return {
                ...state,
                fisicaEvaluacionList: payload,
                isFetching: false
            }
        case "FETCHING_GET_TECNICA_INFO":

            return {
                ...state,
                userEvaluaciones: payload,
                isFetching: false
            }
        case "FETCHING_GET_TECNICA_INFO_PREV_EVA":
            return {
                ...state,
                prevEva: payload,
                isFetching: false
            }
        case "FETCHING_GET_CLUB_CATEGORI_LIST":
            return {
                ...state,
                clubCategori: payload,
                isFetching: false
            }
        case "FETCHING_GET_WEEK_RANGE":
            return {
                ...state,
                weekRange: payload,
                isFetching: false
            }
        case "FETCHING_GET_DATE_ARRAY":
            return {
                ...state,
                dateArray: payload,
                isFetching: false
            }
        case "FETCHING_GET_EVALUACION_PARTIDO":
            return {
                ...state,
                evaluacionPartido: payload,
                isFetching: false
            }
        case "FETCHING_ADD_CURRENT_REPORT":
            console.log('payload', payload)
            return {
                ...state,
                currentReport: payload,
                isFetching: false
            }
            
        case "END_LOADING":
            return {
                ...state,
                isLoading: false,
            }

        case "FETCHING_LOAD_EVALUCION_FISICA":
            return{
                ...state,
                tableFisicaList: payload,
                isFetching:false
            } 
        default:
            return state
    }
}