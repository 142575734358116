import React from "react";
import {
    CardBody,
    Col,
    Row,
    Card,
    FormGroup,
    Form,
    Input as InputTextArea,
    Label,
    Button,
    CardTitle,
    Input,

} from "reactstrap";
import Select from "react-select";
import Manager from '../source/AddEvaluaciones';
import uuid from "react-uuid";
import GlobalContext from "redux/context";
import { useStateCallback } from "hooks/useStateCallback"
import { toast } from "react-hot-toast";
import InputGroup from "components/Input/inputReport/index";
import { SIZES } from "variables/typography";
import moment from "moment";

const api = new Manager()
function AddReports() {
    /////Variables de contexto
    const userContext = React.useContext(GlobalContext);
    const { state, GetUserByCategorie, endLoading } = userContext;
    const { currentReport } = state;
    const [nameReport, setNameReport] = useStateCallback({});
    const [dataFilterCategories, SetDataFilterCategories] = React.useState([]);
    const [dataFilterAtleta, setDataFilterAtleta] = React.useState([]);
    const [showData, setShowData] = useStateCallback(false)
    const [showDataAtleta, setShowDataAtleta] = React.useState(false)
    const [showInputs, setShowInputs] = React.useState(false)
    const [inputArray, setInputArray] = React.useState([]);
    const [inProgress, setInProgress] = React.useState(false)
    const [dataInputEvaluation, setDataInputEvaluation] = useStateCallback([])
    const [modelForInsert, setModelForInsert] = React.useState({
        clubID: 0,
        categoryID: 0,
        reportID: 0,
        sessionID: '',
        ovr: 0,
        date: '00:00:00'
    });

    const [labelOvr, setLabelOvr] = React.useState([])
    const [showDateFilter, setShowDateFilter] = React.useState(false)
    

    ////LOAD INITIAL

    React.useEffect(() => {
        let controller = new AbortController();

        let categoriesList = JSON.parse(window.localStorage.getItem('clubCategoriesList'))
        let newArray = []

        let obj = {
            value: '',
            label: ''
        }
        for (let index = 0; index < categoriesList.length; index++) {
            let element = categoriesList[index];
            obj.value = element.categoriesID
            obj.label = element.categoriesName
            newArray.push(obj)
            obj = {
                value: '',
                label: ''
            }
        }
        SetDataFilterCategories(newArray)
        ///Se carga modelo para insertar datos



        if (currentReport.hasOwnProperty('id')) {
            setNameReport({}, () => setNameReport({}))
            setShowData(false, () => setShowData(false))
        }
        endLoading()
        return () => { controller.abort() }
    }, []);

    React.useEffect(() => {
        let userclubID = JSON.parse(window.localStorage.getItem('user-exists'))
        if (currentReport.hasOwnProperty('id') && currentReport.hasOwnProperty('value')) {
            if (nameReport.id !== modelForInsert.reportID) {
                setNameReport(currentReport)
                setModelForInsert({ ...modelForInsert, clubID: userclubID.lt, reportID: currentReport.id })
                setShowData(true)
            } else {
                setNameReport(currentReport)
                setShowData(true)
                setShowDataAtleta(false)
                setShowDateFilter(false)
                setShowInputs(false)
            }
        }
    }, [currentReport])



    /////FUNCIONES


    const filterCategorie = async (item) => {
        setDataFilterAtleta([])
        setModelForInsert({ ...modelForInsert, categoryID: item.value });
        let user = JSON.parse(window.localStorage.getItem('user-exists'));

        let model = {
            clubID: user.lt,
            categoryID: item.value
        };
        let result = await GetUserByCategorie(model)
        let array = [];
        let obj = {
            value: '',
            label: ''
        };


        result.filter((task) => {
            obj.value = task.sessionID
            obj.label = task.nombre
            array.push(obj)
            obj = {
                value: '',
                label: ''
            }
        })
        //  console.log('array', array)
        setDataFilterAtleta(array)


        let modelReport = {
            clubID: user.lt,
            categorieID: item.value,
            reportID: currentReport.id
        }
        if (modelReport.categorieID === item.value) {
            setShowInputs(false)
            setShowDataAtleta(false)
        }

        let resultMongo = await api.GetReportsClub(modelReport)
        //console.log('result mongo', resultMongo)
        if (!resultMongo.message.success) {
            toast.error('La categoria no cuenta con un reporte creado')
        } else {

            let obj = {
                reportClubID: '',
                field: 0,
                value: 0,
                textValue: ''
            }
            let newArrayInput = []
            let newArrayLabel = []
            let data = resultMongo.message.data[0].inputItems
            for (let index = 0; index < data.length; index++) {
                let element = data[index];
                obj.field = element.id
                obj.reportClubID = element.value
                newArrayInput.push(obj)

                obj = {
                    reportClubID: '',
                    field: 0,
                    value: 0,
                    textValue: ''
                }
            }
            setInputArray(newArrayInput)

            setShowDataAtleta(true)
        }

    };

    const FilterAtleta = async (item) => {
        let user = JSON.parse(window.localStorage.getItem('ClubID'));
        setModelForInsert({
            ...modelForInsert,
            sessionID: item.value
        });
        setShowDateFilter(true)

        if (!showDateFilter) {
            toast.success('Por favor seleccione una fecha para continuar con el procedimiento.')
        }
        let modelobj = {
            clubID: user.clubID,
            categoryID: modelForInsert.categoryID,
            reportID: modelForInsert.reportID,
            sessionID: item.value
        }
        let inputObj = {
            value: 0
        };

        let arrayValue = []

        let result = await api.GetReportEvluation(modelobj)
        //  console.log('result', result)
        if (result.message.data[0].length > 0) {
            let data = result.message.data[0]

            for (let index = 0; index < data.length; index++) {
                let element = data[index];
                // console.log('element', element)
                if (modelForInsert.sessionID === element.sessionID && modelForInsert.categoryID === element.categoryID) {
                    inputObj.value = element.value
                    arrayValue.push(inputObj)
                    inputObj = {
                        value: 0
                    }
                }
                //  console.log('element', element)
            }
            //   console.log('array value ', arrayValue)
            setDataInputEvaluation([], () => setDataInputEvaluation(arrayValue))


            let today = moment(new Date()).format('YYYY-MM-DD')
            //SE VALIDA QUE EL REPORTE SEA DEL DIA ACTUAL 
            if (data[0].fecha === today) {
                setLabelOvr(data[0].ovr)
            } else {
                setLabelOvr([])
            }
        }
    };

    const fechaReport = (item) => {
        let fecha = item.target.value

        setModelForInsert({ ...modelForInsert, date: fecha })

        if (fecha !== '00:00:00') {
            setShowInputs(true)
        }

    }

    const inputsReport = (type, e) => {
        let valueInput = e.target.value
        let dataInput = inputArray

        ///SE VALIDA QUE TIPO DE INPUT SE ESTA LLENANDO
        if (type) {
            if (dataInput.length > 0) {
                if (valueInput > 0) {
                    for (let index = 0; index < dataInput.length; index++) {
                        let elem = dataInput[index];
                        //SE VALIDA ID DEL INPUT PARA AGREGAR DATO TIPO NUMBER
                        if (parseInt(elem.field) === parseInt(e.target.id)) {
                            dataInput[index].value = parseInt(valueInput)
                        }
                    }
                }
            }

            const inExist = dataInput.find(task => task.field === parseInt(e.target.id))
            if (inExist) {
                for (let index = 0; index < dataInput.length; index++) {
                    let element = dataInput[index];

                    if (element.field === parseInt(e.target.id && type)) {
                        dataInput[index].value = parseInt(valueInput)
                    }
                }
            }
        }
        else {
            if (dataInput.length > 0) {
                if (valueInput !== "") {
                    for (let index = 0; index < dataInput.length; index++) {
                        let elem = dataInput[index];
                        //SE VALIDA ID DEL INPUT PARA AGREGAR DATO TIPO TEXT
                        if (parseInt(elem.field) === parseInt(e.target.id)) {
                            dataInput[index].textValue = valueInput
                        }
                    }
                }
            }

            const inExist = dataInput.find(task => task.field === parseInt(e.target.id))
            if (inExist) {
                for (let index = 0; index < dataInput.length; index++) {
                    let element = dataInput[index];

                    if (element.field === parseInt(e.target.id && !type)) {
                        dataInput[index].textValue = valueInput
                    }
                }
            }
        }

        setInputArray(dataInput)

        let initial = 0
        let inserDetailsLength = 0
        let copieInputValue = dataInput

        let sumaInputs = copieInputValue.reduce(function (acc, currentValue) {
            console.log({ currentValue })
            if (currentValue.value > 0) {
                inserDetailsLength++
            }
            return acc + parseInt(currentValue.value)
        }, initial)

        let inputValor = isNaN(parseInt(Math.round(sumaInputs / inserDetailsLength))) ? [] : parseInt(Math.round(sumaInputs / inserDetailsLength))

        setLabelOvr(inputValor)
    };

    const AddReportByClub = async () => {
        setInProgress(true)
        let user = JSON.parse(window.localStorage.getItem('ClubID'));
        let initial = 0
        let inserDetailsLength = 0

        let sumaInputs = inputArray.reduce(function (acc, currentValue) {
            if (currentValue.value > 0) inserDetailsLength++
            return acc + parseInt(currentValue.value)
        }, initial)

        let ovrGeneral = isNaN(parseInt(Math.round(sumaInputs / inserDetailsLength))) ? 0 : parseInt(Math.round(sumaInputs / inserDetailsLength))
        let cloneInsert = { ...modelForInsert }

        cloneInsert.ovr = ovrGeneral;
        cloneInsert.clubID = user.clubID
        let today = moment(new Date()).format('YYYY-MM-DD')
        let auxDate = cloneInsert.date === '00:00:00' ? today : cloneInsert.date
        cloneInsert.date = auxDate

        let result = await api.AddReportMain(cloneInsert)

        let reportClubID = result.message.data[0][0].isInsert

        let nonError = false;

        if (result.message.success && reportClubID > 0) {
            for (let index = 0; index < inputArray.length; index++) {
                let element = inputArray[index];
                // console.log('elem', element)
                element.reportClubID = parseInt(reportClubID)
                let resultDetail = await api.AddReportDetails(element)
                if (!resultDetail.message.success) {
                    nonError = true
                }
            }

            if (!nonError) {
                toast.success('Reporte creado con exito')
                window.location.reload()
            } else {
                toast.error('Reporte creado con error de insercion de datos')
                window.location.reload()
            }

        } else {
            toast.error('Lo sentimos no puede agregar otro reporte , el sistema permite un reporte por día')
            setInProgress(false)
        }
    }

    return (
        <div className="content">
            <Card>
                <CardBody>
                    <Row className="p-3">
                        <Col xs={3}>
                            <h2>{nameReport.value === undefined ? 'Crear un reporte' : `Crear reporte ${nameReport.value}`}</h2>
                        </Col>
                        {
                            showData &&
                            <Col xs={4}>
                                <FormGroup>
                                
                                    <Select
                                        onChange={(item) => filterCategorie(item)}
                                        options={dataFilterCategories}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        defaultValue={{label:"Seleccione una categoría", value:0}}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            showDataAtleta &&
                            <Col>
                                <FormGroup>
                                    <Select
                                        onChange={(item) => FilterAtleta(item)}
                                        options={dataFilterAtleta}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Seleccione un atleta"
                                    />
                                </FormGroup>
                            </Col>
                        }

                        {
                            showDateFilter &&
                            <Col xs={2.5}>
                                <FormGroup>
                                    <InputTextArea
                                        type="date"
                                        onChange={(item) => fechaReport(item)}
                                        placeholder="Seleccione una fecha"
                                    />
                                </FormGroup>
                            </Col>
                        }

                    </Row>

                    {
                        showInputs &&
                        <Row>
                            <Col>
                                <Form>
                                    {
                                        dataInputEvaluation.length <= 0 &&
                                        <Row className="p-3">
                                            <Col className="addReportButton">
                                                <Button
                                                    size="sm"
                                                    disabled={inProgress}
                                                    style={{
                                                        borderRadius: "6px",
                                                        marginTop: '-33px'
                                                    }}
                                                    onClick={() => AddReportByClub()}
                                                >
                                                    Agregar reporte
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <Label tag='h4'>{`Ovr General: ${labelOvr}%`}</Label>
                                        </Col>
                                    </Row>


                                    <Row style={{ marginBottom: "20px", alignSelf: 'center' }}>
                                        {
                                            inputArray.length > 0 ?
                                                inputArray.map((task, i) => {
                                                    let current = dataInputEvaluation[i] ? dataInputEvaluation[i] : {};
                                                    // console.log('current', current)
                                                    if (!current) return null;

                                                    return (
                                                        <Col md={4} className="reportImputStyle" key={uuid()}>
                                                            <FormGroup>
                                                                <Label tag='h5' style={{ height: '50px' }} >{task.reportClubID}</Label>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <Input
                                                                            id={i + 1}
                                                                            defaultValue={task.value}
                                                                            type="number"
                                                                            min="0"
                                                                            onBlur={(e) => inputsReport(true, e)}

                                                                        />
                                                                    </Col>
                                                                    <Col md={8}>
                                                                        <Input
                                                                            id={i + 1}
                                                                            defaultValue={task.textValue}
                                                                            placeholder={`Ingresa texto aquí`}
                                                                            type="text"
                                                                            onBlur={(e) => inputsReport(false, e)}

                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    )
                                                }) : null
                                        }

                                    </Row>
                                </Form>
                            </Col>
                        </Row>

                    }
                </CardBody>
            </Card>
        </div>
    )

}

export default AddReports